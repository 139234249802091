import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext';
import toast from 'react-hot-toast';
import CustomNoRowsOverlay from '../common/tableLoader'
import axios from 'axios';



export default function DataGridDemo({setEdit,setDel,del,tableSearch}) {

   const {token,user} = useAuth();
  const {data,setData,makeRequest} = useData();

    const [openMenu, setOpenMenu] = React.useState(null);
    const [loadingApproval,setLoadingApproval]=React.useState(false);

    const handleOutsideClick = (event) => {
        if (!event.target.closest('.menu') &&  !event.target.closest('.menu-option')) {
          setOpenMenu(null)
          document.removeEventListener('click', handleOutsideClick);
        }
    };


    const  handleClickMenu = (id) => {
         document.addEventListener('click', handleOutsideClick);
         setOpenMenu(id)
    };

    function handleLoanApproval(id,approval_status) {

       if(loadingApproval)  return

      setTimeout(()=>{
         setOpenMenu(null)
         document.removeEventListener('click', handleOutsideClick);
      },10)


      setLoadingApproval(true)

      let approval_toast=toast.loading(`A ${approval_status==true ? 'adicionar' :'remover'}...`)

       axios.post(`${process.env.REACT_APP_BASE_URL}/api/client/blacklist/status`,{id,status:approval_status},{
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {
            toast.remove()
            toast.dismiss(approval_toast)
            toast.success(`Cliente ${approval_status==true ? ' adionado' :'removido'}`)
            let clients=JSON.parse(JSON.stringify(data.clients))
            let blacklist=JSON.parse(JSON.stringify(data.blacklist))
            let index=data.clients.findIndex(i=>i.client_id==id)
            if(index != -1) clients[index].on_blacklist=approval_status

            if(!blacklist.filter(i=>i.client_id==id).length && approval_status==true){
                 blacklist.push(clients[index])
            }

            if(approval_status==false && index != -1){
                blacklist=blacklist.filter(i=>i.client_id!=id)
            }

            data.clients=clients
            data.blacklist=blacklist
            setData(data)
            setLoadingApproval(false)

        })
        .catch(error => {
            setLoadingApproval(false)
            toast.remove()
            toast.dismiss(approval_toast)
            console.error('Error:', error);
            try{
                   toast.error('Ocorreu um erro:'+error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
            }catch(e){
                   toast.error('Error, try again!')
            }
           
        });
                 
    }






    const columns = [
    {
      field: 'name',
      headerName: 'Nome completo',
      width: 250,
      editable: true,
      renderCell: (params) => (
         <span>{params.row.name + " "+ params.row.last_name}</span>
      )
    },
    {
      field: 'microcredit',
      headerName: 'Microcredito',
      width: 190,
      editable: true,
    },
     {
      field: 'more',
      headerName: '',
      width: 130,
      renderCell: (params) => (
        <div style={{cursor:'pointer', position:'absolute'}} onClick={()=>handleClickMenu(params.row.id)}  className="menu">
                      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z"/></svg>
                      <div className="menu-dropdown-row" style={{position:'absolute',display:openMenu==params.row.id ? 'block' :'none'}}>
                                  <span className={`menu-option`} onClick={()=>handleLoanApproval(params.row.client_id,false)}><svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>Remover</span>
                      </div>
          </div>
      )
    }
 
    
  ];

    function search(array){

               function search_from_object(object,text){
                      text=tableSearch
                      let add=false
                      Object.keys(object).forEach(k=>{
                        if(typeof object[k]=="string" || typeof object[k]=="number"){
                           if(typeof object[k]=="number") object[k]=`${object[k]}`
                           if(object[k].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))){
                             add=true
                          }
                        }
                      })
                      return add
              }

            if (!array) return []
            let d=JSON.parse(JSON.stringify(array))
            let res=[]

            d.forEach((t,i)=>{
              if(search_from_object(t)) {
                  res.push(array.filter(j=>j.id==t.id)[0])
              }
           })

           return res

    }

 

  const [rows,setRows]=React.useState(data.blacklist)
  const [loading,setLoading]=React.useState(false)

  React.useEffect(()=>{
     setRows(search(data.blacklist))
  },[data,data.blacklist,tableSearch])



  



  

     React.useEffect(()=>{

      (async () => {
                   try{
                         let res = await makeRequest({ url: 'clients/blacklist', error: `` });
                         setRows(res)
                         data.blacklist=res
                         data.loaded.blacklist=true
                         setData(data)
                   }catch(e){
                         console.log(e)
                   }
      })();

   },[])



 

  
  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 6,
            },
          },
        }}
        pageSizeOptions={[6]} // Corrected
        //checkboxSelection
        disableColumnMenu
        localeText={{ noRowsLabel: <CustomNoRowsOverlay loading={!data.loaded.blacklist ? true : false}/>,  pagination: {labelRowsPerPage: 'Mostrar por página'} }}
        //onSelectionModelChange={handleSelectionModelChange}
      />
    </Box>
  );
}
