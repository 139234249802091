
import { useEffect, useState} from 'react';
import { useData } from '../../../contexts/DataContext';
import { useAuth } from '../../../contexts/AuthContext';
import toast from 'react-hot-toast';
import { useParams , useLocation, Outlet,useNavigate} from 'react-router-dom';
const Create = ({create,setCreate, mode,setContractPayments ,setEditingState,editMode,showD,utils,contract_payments}) => {

const [installmentForm,setInstallmentForm]=useState({})
const [loading,setLoading]=useState(false)
const [cancelAndClose,setCancelAndClose]=useState(false)
const {pathname}=useLocation();
const {data, setData} = useData();
const {token, user} = useAuth();
const [accounts, setAccounts] = useState([])
const [selectRefundAccount,setSelectRefundAccount]=useState(false)
const [changingPayDay,setChangingPayDay]=useState(false)

useEffect(()=>{

   if(editMode){
         let _accounts=JSON.parse(JSON.stringify(data.settings.accounts)) 
         if(installmentForm.account){

              let current_a=JSON.parse(installmentForm.account)
              if(!_accounts.some(a=>a.id==current_a.id)){
                    _accounts.unshift(current_a)

              }

              setAccounts(_accounts)

              if(!installmentForm.account_id  && _accounts.length)  setInstallmentForm({...installmentForm,account_id:current_a.id})
             
        }else{
             setAccounts(_accounts)
        }  

   }


  if(!editMode && data.settings.accounts) setAccounts(JSON.parse(JSON.stringify(data.settings.accounts)))


},[data.settings, data.settings.accounts,installmentForm])



useEffect(()=>{
    
   setInstallmentForm({paid:0,status:'pending',amount_to_pay:0,...create,
       amount_to_pay:changingPayDay && create.total_to_pay - create.paid >= parseFloat(create.amount_to_pay)  ? installmentForm.amount_to_pay : create.cancelled==true  ? '' : (!create.paid  ? create.total_to_pay  : create.total_to_pay - create.paid),
       payday:changingPayDay ? create.payday : create.cancelled==true  ? '' : create.payday || create.status=="payday" ? create.payday :  utils.get_current_date()
   })
    
},[create])



useEffect(()=>{

    if(installmentForm.amount_to_pay < 0 ){
       setInstallmentForm({...installmentForm,amount_to_pay:0})
    }else if(installmentForm.amount_to_pay > (installmentForm.total_to_pay - installmentForm.paid)){
      if(!installmentForm.cancelled){
         setInstallmentForm({...installmentForm,amount_to_pay:installmentForm.total_to_pay - installmentForm.paid})
      }else if(installmentForm.total_to_pay - installmentForm.paid < parseFloat(installmentForm.amount_to_pay)){
         setInstallmentForm({...installmentForm,amount_to_pay:installmentForm.total_to_pay - installmentForm.paid})
      }
   }

    if(installmentForm.cancelled && installmentForm.can_cancel && cancelAndClose){

      const new_contract_payments=contract_payments.map(i=>{
            if(i.id==installmentForm.id) {
               return {...installmentForm,can_cancel:false}
            }else{
               return i
            }
      }) 

      data.editingLoan=true
      setEditingState(true)
      setContractPayments(new_contract_payments)
      setCreate(false)
      setCancelAndClose(false)
        
    }

},[installmentForm])


function cancel_action() {
       setCreate(false)
}



function resetPayment(){

     if(data.transations.filter(i=>i.contract_payment_id==installmentForm.id).map(i=>i.type == "in" ?  parseFloat(i.amount) : -(parseFloat(i.amount))).reduce((acc, curr) => acc + curr, 0) != parseInt(installmentForm.paid) && pathname!="/loans/create"){
         setSelectRefundAccount(true)
     }else if(pathname=="/loans/create"){
         setCancelAndClose(true) //it will close the window after cancelling
     }

     change_payday('',{...installmentForm,
         paid:0,
         total_to_pay:installmentForm.initial_amount,
         amount_to_pay:installmentForm.initial_amount,
         payday:'',
         status:'pending',
         account_id:'',
         cancelled:true,
         can_cancel:false
    })


    // "it will close after clicking cancelar pagamento"
     /*else{
         change_payday('',{...installmentForm,
            paid:0,
            total_to_pay:installmentForm.initial_amount,
            amount_to_pay:installmentForm.initial_amount,
            payday:'',
            cancelled:true,
            status:'pending',
            can_cancel:false
         }) 
     }*/
   
}

function change_payday(v,reset) {
      let from=reset ? reset :installmentForm
      const new_contract_payments=contract_payments.map(i=>{
             if(i.id==from.id) {
                return {...from,editing_payday:v}
             }else{
                return i
             }
      })

      if(reset) {
         setChangingPayDay(false)
      }else{
         setChangingPayDay(true)
      }

      setCreate({...from,payday:v}) 
      setContractPayments(new_contract_payments)
}


function actions(){

        if(!installmentForm.cancelled || (installmentForm.amount_to_pay && installmentForm.cancelled)){

         if((!installmentForm.amount_to_pay && installmentForm.status!="paid") || !installmentForm.payday || !installmentForm.account_id){
            toast.error('Preecha todos os dados!')
            return
          }

          if(selectRefundAccount && !installmentForm.refund_account_id){
            toast.error('Adicione a conta para desembolso do pagamento anulado')
            return
          }
 
 
          installmentForm.amount_to_pay=parseFloat(installmentForm.amount_to_pay)
          installmentForm.paid=installmentForm.paid + installmentForm.amount_to_pay

        }
        
        if(selectRefundAccount && !installmentForm.refund_account_id){
         toast.error('Adicione a conta para desembolso do pagamento anulado')
         return
        }

        


        if(installmentForm.payments_history){
           installmentForm.payments_history=[...installmentForm.payments_history,{payday:new Date().toISOString().split('T')[0],amount:installmentForm.amount_to_pay}]
        
        }  


         installmentForm.status=installmentForm.paid == installmentForm.total_to_pay  ? "paid" :  installmentForm.paid  <  installmentForm.total_to_pay &&  installmentForm.paid ?  "parcial" : installmentForm.status

      
         const new_contract_payments=contract_payments.map(i=>{
             if(i.id==installmentForm.id) {
                return installmentForm
             }else{
                return i
             }
         }) 

         data.editingLoan=true
         setEditingState(true)
         setContractPayments(new_contract_payments)
         setCreate(false)

}


   
return (
 <div className={`dialog-container-bg ${showD ? 'show' :''}`}>
  <div className="bg-close" onClick={cancel_action}></div>
 <div  className={`create-microcredit ${loading && 'loading'}`}>
                  <div className="top-title">{installmentForm.status!="paid" ? 'Fazer pagamento' : 'Ver pagamento'}</div>  
                  <div className="btn-close" onClick={cancel_action}>
                         <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                  </div>

                  <div className="status" style={{color:'#111',background:installmentForm.status=='pending' || installmentForm.status=='parcial' ? '#F9CE66': installmentForm.status=="paid" ? '#4ACD35' : '#FF6B6B'}}>{installmentForm.status=='pending' ? 'Pendente': installmentForm.status=="paid" ? 'Pago' : installmentForm.status=="parcial" ? 'Parcialmente' : 'Atrasado'}</div>

                  <div className="payment-top-cards">
                         <div className="item">
                            <span className="name">Em falta</span><span className="value">{new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((installmentForm.total_to_pay - installmentForm.paid))}</span>
                         </div>
                          <div className="item">
                             <span className="name">Pago</span><span className="value">{new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((installmentForm.paid))}</span>
                         </div>

                           <div className="item">
                            <span className="name">Dias em atraso</span><span className="value">{installmentForm.delay}</span>
                          </div>

                  </div>


                  <div className="input-container">
                    {installmentForm.status!="paid" && <div className="inputs">
                                               <span>Montante a pagar <label className="mandatory">*</label></span>
                                               <input value={installmentForm.amount_to_pay} onChange={e=>setInstallmentForm({...installmentForm,amount_to_pay:e.target.value})} type="number" placeholder="Montante a pagar"/>
                    </div>}
                     <div className="inputs">
                           <span>Data de pagamento <label className="mandatory">*</label></span>
                           <input style={{opacity:installmentForm.status=="paid" ? 0.4 : 1}} disabled={installmentForm.status=="paid" ? true : false} value={installmentForm.payday} onChange={e=>change_payday(e.target.value)} type="date"/>
                     </div>

                     <div className="inputs">
                           <span>Conta de reembolso<label className="mandatory">*</label></span>
                              {selectRefundAccount && <br/>}
                              <select disabled={installmentForm.status=="paid" ? true : false} value={installmentForm.account_id} onChange={e=>setInstallmentForm({...installmentForm,account_id:e.target.value})}>
                             {!accounts.length  || !installmentForm.account_id && <option disabled selected value="">Selecione a conta</option>} 
                             {accounts.map(i=>(
                                   <option key={i.id} selected={installmentForm.account_id == i.id} value={i.id}>{i.name}</option>
                             ))}
                      </select> </div>

                      {selectRefundAccount && <div className="inputs">
                           <span>Conta para desembolso do pagamento anulado<label className="mandatory">*</label></span>
                              <select disabled={installmentForm.status=="paid" ? true : false} value={installmentForm.refund_account_id} onChange={e=>setInstallmentForm({...installmentForm,refund_account_id:e.target.value})}>
                             {!accounts.length  || !installmentForm.refund_account_id && <option disabled selected value="">Selecione a conta</option>} 
                             {accounts.map(i=>(
                                   <option key={i.id} selected={installmentForm.refund_account_id == i.id} value={i.id}>{i.name}</option>
                             ))}
                     </select> </div>}


                  </div>

                  {installmentForm.status!="paid" && <div className="payment-res">
                                         <span className="name">Em falta após o pagamento </span><span className="value">{new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(installmentForm.total_to_pay ? installmentForm.total_to_pay :0) - parseFloat(installmentForm.amount_to_pay ? installmentForm.amount_to_pay :0) - parseFloat(installmentForm.paid ? installmentForm.paid:0))}</span>
                                    </div>}

                   {(installmentForm.paid!=0 && installmentForm.paid!=undefined && installmentForm.paid!=null && (installmentForm.createdAt ||  pathname=="/loans/create")) && <span onClick={resetPayment} className="remove-payment" style={{paddingTop:0,padding:5,textDecoration:'underline',cursor:'pointer',opacity:.7,display:'table',marginBottom:6}}>Anular pagamento</span>
                  }


              {installmentForm.status!="paid" && <div className="options">
                                <button className="cancel" onClick={cancel_action}>Cancelar</button>
                                <div className="div_btn">
                                   <button className="save" onClick={actions}><span>Processar</span></button>
                                   <span className="loader"></span>
                                </div>
                   </div>}

        </div>
         </div>


)
};

export default Create;
