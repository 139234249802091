import { useState , useEffect} from 'react'

import '../css/main.css'

import {Link, useLocation,useNavigate} from 'react-router-dom'

import axios from 'axios';
import toast from 'react-hot-toast';
import { Toaster } from 'react-hot-toast';


function Component() {

   useEffect(() => {
      document.querySelectorAll('.footer .terms a').forEach(e=>{
             e.addEventListener('click', handleFooterClick);
      }) 
      return () => {
         document.querySelectorAll('.footer .terms a').forEach(e=>{
             e.removeEventListener('click', handleFooterClick);
         }) 
      };
    }, []);


   function handleFooterClick(){
         document.querySelector('._menu').scrollIntoView({ behavior: 'auto' });
   }
 
  const navigate=useNavigate()

  let [page,setPage]=useState('company')

  let [showConfirmDialog,setShowConfirmDialog]=useState(false)
  let [loading,setLoading]=useState(false)
  let [email,setEmail]=useState(null)



  async function send_email(){
       toast.remove()
    
       if(!email){
          toast.error('Insira o email')
          return
       }

      function isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email.trim());
      }

      if(!isValidEmail(email)){
          toast.error('email invalido')
          return
      }

       try {

            setLoading(true)
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/subscribe/`,{email});
            toast.remove()
            setLoading(false)
            setShowConfirmDialog(true)
            setEmail('')

        }catch (error) {
            toast.remove()
            try{
              toast.error('Request failed:'+ error.response.data.message);
              console.log(error)
            }catch(e){
              console.log(e)
              toast.error('Verifique sua conexão e tente novamente!')
            }



            setLoading(false)
       }
  }



  return (
            <>
             <div className={`delete-dialog ${showConfirmDialog && 'active'} `}> 
                            <div className="btn-close" onClick={()=>setShowConfirmDialog(false)}>
                                   <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                            </div> 
                          <div className="alert-icon">
                                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="70" fill="green"><path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                          </div>

                          <p>Subscrito com sucesso!</p>

                         <div className="options"> 
                                <button onClick={()=>setShowConfirmDialog(false)} className="save">Fechar</button> 
                             
                         </div>  

                           
                </div>


            <footer>
                      <div className="footer">
                               <div className="section">
                                       <span className="title">VLMS</span>
                                       <div className="text">Uma solução para instituições  financeiras e e emprendedores que desejam oferecer microcréditos de forma eficiente, segura e acessível.</div>
                                       <div className="links">
                                         <a href="mailto:vlsm@visum.co.mz" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z"/></svg><span>vlsm@visum.co.mz </span></a>
                                       </div>
                               </div>

                               <div className="section terms"> 
                               <span className="title">Informação</span>
                                      <div className="links">
                                          <Link to={'/privacy-politics'}> Politicas de privacidade</Link>
                                           <Link to={'/terms'}> Termos e condições</Link>
                                       </div>
                               </div>

                               <div className={`${loading && 'loading'} section full-in-mobile newsletter`}>
                                       <span className="title">Actualizações</span>
                                       <div className="text">Mantenha-se atualizado com as últimas notícias e atualizações sobre a VLMS, subscrevendo-se com seu de e-mail.</div>
                                       
                                      <div className="input-div">
                                        <input value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="Seu email"/>
                                         <div  className="div_btn">
                                            <button onClick={send_email}>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M120-160v-640l760 320-760 320Zm80-120 474-200-474-200v140l240 60-240 60v140Zm0 0v-400 400Z"/></svg>
                                            <span className="loader"></span>
                                            </button>
                                         </div>
                                      </div>
                               </div>
                      </div>

                      <div className="copy">© 2024 Visum Loan Management System</div>
            </footer>
            </>
  )
}

export default Component