
import { Navigate, useNavigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useState ,useEffect} from 'react';
import { useData } from '../../contexts/DataContext';
import Not from './notifications'
import toast from 'react-hot-toast';
import axios from 'axios';



const TopBar = ({activePage,lastPage,leftMenu,setLeftMenu}) => {
    
     const {user,token} = useAuth()
     const {data, menuOpen , setMenuOpen, reset_data} = useData()
     const navigate = useNavigate();
     const [isDialogVisible, setDialogVisible] = useState(false);
     const [not, setNot] = useState(false);




    useEffect(() => {
      document.addEventListener('click', handleOutsideClick);
      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }, [isDialogVisible,not]);

    const toggleDialog = () => {
      setDialogVisible(!isDialogVisible);
    };

     const toggleNot = () => {
       setNot(!not);
    };


    const handleOutsideClick = (e) => {
      if (isDialogVisible && !e.target.closest('._u')) {
        setDialogVisible(false);
      }

      if (not && !e.target.closest('._n')) {
          setNot(false);
      }
    };


    function Logout() {

          setDialogVisible(false)

          toast.loading('A sair...')
      
          axios.get(`${process.env.REACT_APP_BASE_URL}/auth/logout`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          })
          .then(response => {
                 toast.remove()
                 out()
            })
          .catch(error => {
                toast.remove()
                toast.error('erro ao tentar sair, tente novamente!')
                console.error('Error fetching user data:', error);
          });
      
      function out(){
        
        reset_data()
        if(user.email=="test@vfs.co.mz"){
                navigate('/test')
        }else{
               navigate('/logout')
        }

      }
        
       
        
    }

    return (
        <div className="top">
                       <div className="current_tab">
                              <span className="tab">{activePage}</span>
                              <span className="path">
                              <label onClick={()=>navigate(lastPage?.pathname)}>{lastPage?.name}</label>
                              {lastPage && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="20"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"></path></svg>}
                              {lastPage && activePage }</span>
                       </div>
                        <div className="search_container">
                            <div className="search-div">
                               {/*<input type="text" placeholder="Pesquisar..."/>
                               <div className="search-btn">
                                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"/></svg>
                               </div>
                             */}
                            </div>
                        </div>
                        <div className="right-options">
                             <div className="user _u">
                                  <div className="user_data" onClick={toggleDialog}>
                                      <div className="info">
                                         <span className="name">{user.name}</span>
                                         <span className="name">{user.email}</span>
                                      </div>

                                      <div className="avatar">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z"/></svg>
                                      </div>
                                  </div>

                                  { /*<div className="show_user_options">
                                                                         <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"></path></svg>
                                  </div>*/}

                                  {isDialogVisible && (<div className="user_options">
                                      <span onClick={Logout}>Sair</span>
                                  </div>)}
                             </div>
                              <div className="top-tabs _n">
                                <span className="notifications" onClick={toggleNot}>
                                   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" ><path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z"></path></svg>
                               </span>
                                {not && <Not/>}
                             </div>
                             <div className="burger">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"></path></svg>
                             </div>
                        </div>
           </div>
    )
  };
  
  export default TopBar;
  