import { useState,useEffect } from 'react'
import Menu from '../common/menu'
import Footer from '../common/footer'


function About() {

      useEffect(() => {
             document.querySelector('._menu').scrollIntoView({ behavior: 'auto' });
      }, []);


  

  return (
     <div className="_container _white_menu landing-page">
         <Menu/>
         <div className="main-section">
               <div className="title">Importar arquivos excel</div>
         </div>
         <div className="divide">
             <div className="left">
                
             </div>
             <div className="right">
                    <div className="section-item">
                        <span><strong>Como importar contratos</strong></span>
                        <br/>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam ullam laboriosam, ipsam error aut fuga reprehenderit, voluptates et cumque itaque ex ratione facilis similique molestias? Eius veniam quidem dolor sapiente minus quia quo suscipit assumenda voluptatem voluptates officia, magnam nostrum animi libero id excepturi odio eos placeat totam nemo officiis ipsam, magni reprehenderit eaque! Est a harum tenetur officiis, unde exercitationem magni voluptatibus, reiciendis quibusdam illum illo maiores omnis fugiat laboriosam molestiae magnam, voluptas accusantium dolorum ipsum id saepe reprehenderit aliquid. Dignissimos maiores aut repudiandae eligendi, repellendus enim. At iure repudiandae maxime asperiores deserunt quisquam dolor tenetur hic! Dolores, deleniti.
             
                    </div>

                    
                    <br></br>


                    <div className="section-item">
                        <span><strong>Como importar clients</strong></span>
                        <br/>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam ullam laboriosam, ipsam error aut fuga reprehenderit, voluptates et cumque itaque ex ratione facilis similique molestias? Eius veniam quidem dolor sapiente minus quia quo suscipit assumenda voluptatem voluptates officia, magnam nostrum animi libero id excepturi odio eos placeat totam nemo officiis ipsam, magni reprehenderit eaque! Est a harum tenetur officiis, unde exercitationem magni voluptatibus, reiciendis quibusdam illum illo maiores omnis fugiat laboriosam molestiae magnam, voluptas accusantium dolorum ipsum id saepe reprehenderit aliquid. Dignissimos maiores aut repudiandae eligendi, repellendus enim. At iure repudiandae maxime asperiores deserunt quisquam dolor tenetur hic! Dolores, deleniti.
             
                    </div>

             </div>
         </div>
         <Footer/>

    </div>
 )
}

export default About