import React, { useState } from 'react';
import Select from 'react-select';

const MultiSelect = ({options,selectedOptions,placeholder,setSelectedOptions,noOptionsMessage}) => {


  const customStyles = {
    control: (provided, state) => ({
     ...provided,
      outline: 'none',
      boxShadow: state.isFocused ? '0 0 6px #4d90fe' : '0',
      border: state.isFocused ? '1px solid #4d90fe' : '1px solid #DDDDDD',
      WebkitTransition: 'all 0.1s ease-in-out',
      MozTransition: 'all 0.1s ease-in-out',
      MsTransition: 'all 0.1s ease-in-out',
      OTransition: 'all 0.1s ease-in-out',
      height: '46px'

    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#2360E4',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'white'
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: 'white',
      ':hover': {
        backgroundColor: '#06318d',
        color: 'white',
      },
    }),
  };

  const handleSelectChange = (selected) => {
      setSelectedOptions(selected)

  };

  const customNoOptionsMessage = () => noOptionsMessage;

  return (
    <Select
      isMulti
      options={options}
      value={selectedOptions}
      onChange={handleSelectChange}
      placeholder={placeholder}
      isSearchable
      styles={customStyles}
      getOptionValue={(option) => option.id} 
      getOptionLabel={(option) => option.name + (option.last_name ? " "+option.last_name :'')}
      noOptionsMessage={customNoOptionsMessage}
    />
  );
}


export default MultiSelect;
