
import { useEffect, useState} from 'react';
import { useData } from '../../../contexts/DataContext';
import { useAuth } from '../../../contexts/AuthContext';
import axios from 'axios';
import toast from 'react-hot-toast';

const Create = ({create,setCreate, edit , setEdit, del ,showD,setRes}) => {

const [formData,setFormData]=useState({id:'',days:'',percentage:''})

const [loading,setLoading]=useState(false)

const {data, setData} = useData();
const {token, user} = useAuth();


useEffect(()=>{
    if(edit){
       setFormData(edit)
       setCreate(false)
    }
},[edit])

useEffect(()=>{
    if(create){
       setEdit(false)
       setFormData({id:'',days:'',percentage:''})
    }
},[create])

useEffect(()=>{
    if(formData.percentage < 0 ){
       setFormData({...formData,percentage:0})
    }else if(formData.percentage > 100){
       setFormData({...formData,percentage:100})
    }
},[formData])



function handle_manager(){

    if(edit){

      if(data.settings.interest_rates?.some(i=>i.days==formData.days && i.percentage==formData.percentage && i.id!=formData.id)){
           toast.error('Esta taxa já existe')
           return

      }else if(!formData.days || !formData.percentage) {
           toast.error('Preencha todos os campos')
           return
      }

      setLoading(true) 

       axios.post(`${process.env.REACT_APP_BASE_URL}/api/loans/settings/interest-rates/update`,formData, {
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {
             let id=response.data.id
             let rates=JSON.parse(JSON.stringify(data.settings.interest_rates))
             let index=data.settings.interest_rates.findIndex(i=>i.id==id)
             rates[index]=formData
             data.settings.interest_rates=rates
             toast.success('Taxa actualizada!')
             setData(data)
             setFormData({id:'',days:'',percentage:''})
             setEdit(null)
             setLoading(false)
        })
        .catch(error => {
            setLoading(false)
            console.error('Error fetching user data:', error);

            try{
                   toast.error(error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
            }catch(e){
                   toast.error('Error, try again!')
            }
            
        });

    }else{
        if(data.settings.interest_rates?.some(i=>i.days==formData.days && i.percentage==formData.percentage && i.id!=formData.id)){
            toast.error('Esta taxa já existe')
            return
       }
       if(!formData.days || !formData.percentage) {
         toast.error('Preencha todos os campos')
         return 
      }
     
      setLoading(true) 
         axios.post(`${process.env.REACT_APP_BASE_URL}/api/loans/settings/interest-rates/create`,formData, {
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {
             toast.success('Taxa adicionada!')
             formData.id=response.data.id
             data.settings.interest_rates=[...data.settings.interest_rates,formData]
             setData(data)
             setCreate(false)
             setLoading(false) 
             if(setRes) setRes(formData)
        })
        .catch(error => {
            setLoading(false)
            console.error('Error fetching user data:', error);
            try{
                   toast.error(error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
            }catch(e){
                   toast.error('Error, try again!')
            }
            
        });
    }

    
}
   
return (
    <div className={`dialog-container-bg ${showD ? 'show' :''}`}>
     <div className="bg-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}></div>
    <div  className={`create-microcredit ${loading && 'loading'}`}>
                  <div className="top-title">{edit ? 'Editar' :' Adicionar'} taxa de juros</div>  
                  <div className="btn-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}>
                         <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                  </div>

                  <div className="input-container">
                      {/*<div className="inputs">
                                               <span>Dias</span>
                                               <input value={formData.period} onChange={e=>setFormData({...formData,days:e.target.value})} type="number" placeholder="Número dias"/>
                       </div>*/}
                      <div className="inputs">
                           <span>Dias</span>
                           <input value={formData.days} max={100} onChange={e=>setFormData({...formData,days:e.target.value})} type="number" placeholder="Número de dias"/>
                      </div>
                      <div className="inputs">
                           <span>Percentagem</span>
                           <input value={formData.percentage} max={100} onChange={e=>setFormData({...formData,percentage:e.target.value})} type="number" placeholder="%"/>
                     </div>
                  </div>


              <div className="options">
                  <button className="cancel" onClick={()=>(edit ? setEdit(false) : setCreate(false) )}>Cancelar</button>
                  <div className="div_btn">
                     <button className="save" onClick={handle_manager}><span>{edit? 'Actualizar' : 'Adicionar'}</span></button>
                     <span className="loader"></span>
                  </div>
              </div>

        </div>
         </div>


)
};

export default Create;
