
import { useEffect, useState} from 'react';
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';
import toast from 'react-hot-toast';

const Create = ({create,setCreate, edit , setEdit, del,showD }) => {


const initial_form={id:'',name:'',email:'',last_name:'',contact:'',password:''}  

const [formData,setFormData]=useState(initial_form)

const [loading,setLoading]=useState(false)

const {data, setData} = useData();
const {token, user} = useAuth();

function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email.trim());
}


useEffect(()=>{
    if(edit){
       setFormData({...edit,email:edit.User.email})
       setCreate(false)
    }
},[edit])


useEffect(()=>{
    if(create){
       setEdit(false)
    }

    setFormData(initial_form)
},[create])


useEffect(()=>{
   console.log(formData)
},[formData])



function handle_manager(){

    if(edit){
      if(!formData.name || !formData.email || !formData.last_name || !formData.contact) {
         toast.error('Preencha todos os campos!')
         return 
      }
      if(!isValidEmail(formData.email)) {
           toast.error('Invalid email!')
          return
       }
      setLoading(true) 

       axios.post(`${process.env.REACT_APP_BASE_URL}/api/manager/update`,formData, {
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {
             let id=response.data.id
             let managers=JSON.parse(JSON.stringify(data.managers))
             let index=data.managers.findIndex(i=>i.id==id)
             managers[index]={...formData,User:{...formData.User,email:formData.email}}
             data.managers=managers
             toast.success('Manager updated!')
             setData(data)
             setFormData({id:'',name:'',email:'',last_name:'',contact:'',password:''})
             setEdit(null)
             setLoading(false)  
        })
        .catch(error => {
            setLoading(false)
            console.error('Error fetching user data:', error);

            try{
                   toast.error(error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
            }catch(e){
                   toast.error('Error, try again!')
            }
            
     });


    }else{
      if(!formData.name || !formData.email || !formData.last_name || !formData.contact) {
         toast.error('Preencha todos os campos!...')
         return 
      }
      if(!isValidEmail(formData.email)) {
           toast.error('Invalid email!')
          return
       }
      setLoading(true) 
         axios.post(`${process.env.REACT_APP_BASE_URL}/api/manager/create`,formData, {
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {
             toast.success('Gestor criado com sucesso!')
             formData.id=response.data.id
             let managers=JSON.parse(JSON.stringify(data.managers))
             managers.unshift({...formData,User:{status:'active',email:formData.email}})
             data.managers=managers
             setData(data)
             setCreate(false)
             setLoading(false)  
        })
        .catch(error => {
            setLoading(false)
            console.error('Error fetching user data:', error);
            try{
                   toast.error(error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
            }catch(e){
                   toast.error('Error, try again!')
            }
        });
    }
}
   
return (
 <div className={`dialog-container-bg ${showD ? 'show' :''}`}>
  <div className="bg-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}></div>
<div  className={`create-microcredit ${loading && 'loading'}`}>
                  <div className="top-title">{edit ? 'Editar' :' Adicionar'} gestor</div>  
                  <div className="btn-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}>
                         <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                  </div>

                  <div className="input-container">
                     <div className="inputs">
                           <span>Nome</span>
                           <input value={formData.name} onChange={e=>setFormData({...formData,name:e.target.value})} type="text" placeholder="Nome do gestor"/>
                     </div>
                     <div className="inputs">
                            <span>Apelido</span>
                           <input value={formData.last_name} onChange={e=>setFormData({...formData,last_name:e.target.value})} type="text" placeholder="Apelido do gestor"/>
                     </div> 

                     <div className="inputs">
                           <span>Email</span>
                           <input value={formData.email} onChange={e=>setFormData({...formData,email:e.target.value})} type="text" placeholder="Email do gestor"/>
                     </div>

                     <div className="inputs">
                            <span>Contacto</span>
                            <input value={formData.contact} onChange={e=>setFormData({...formData,contact:e.target.value})} type="number" placeholder="Contacto do gestor"/>
                     </div>

                    
                    {/*<div className="inputs">
                          <div className="label"><span>Senha</span>{edit && <label>Trocar senha <input checked={formData.change_password} onChange={(e)=>setFormData({...formData,change_password:e.target.checked})} type="checkbox"/></label>}</div>
                          <input  disabled={edit && formData.change_password ? false : !edit ? false : true} value={formData.password} onChange={e=>setFormData({...formData,password:e.target.value})} type="password" placeholder="Senha do gestor"/>
                     </div>*/}
                  
                  </div>


              <div className="options">
                  <button className="cancel" onClick={()=>(edit ? setEdit(false) : setCreate(false) )}>Cancelar</button>
                  <div className="div_btn">
                     <button className="save" onClick={handle_manager}><span>{edit? 'Actualizar' : 'Adicionar'}</span></button>
                     <span className="loader"></span>
                  </div>
              </div>

        </div>
         </div>


)
};

export default Create;
