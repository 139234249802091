
import '../../css/main.css'
import './style.css'
import Menu from '../../common/menu.js';
import TopBar from "../../common/topBar";
import { useState } from 'react';
import TableInterestRates from './table-interest-rates.js'
import TableLatePaymentInterest from './table-late-payment-interest.js'
import CreateInterestRates from './create-accounts.js'
import CreateLatePaymentInterest from './create-late-payment-interest.js'
import DeleteDialog1 from './delete-dialog-1.js'
import DeleteDialog2 from './delete-dialog-1.js'

export default function Create() {

const [createInterestRates, setCreateInterestRates] = useState(false);
const [createPaymentInterest, setCreatePaymentInterest] = useState(false);


const [edit1, setEdit1] = useState(null);
const [del1, setDel1] = useState(null);
const [edit2, setEdit2] = useState(null);
const [del2, setDel2] = useState(null);


return (
    <>
    <main className="dashboard-container">

       {<CreateInterestRates showD={(createInterestRates || edit1) ? true : false}  create={createInterestRates} setCreate={setCreateInterestRates} setEdit={setEdit1} edit={edit1} del={del1}/>}
       {<CreateLatePaymentInterest showD={(createPaymentInterest || edit2) ? true : false}  create={createPaymentInterest} setCreate={setCreatePaymentInterest} setEdit={setEdit2} edit={edit2} del={del2}/>}
       
       {<DeleteDialog1 showD={del1 ? true : false} del={del1}  setDel={setDel1}/>}
       {<DeleteDialog2 showD={del2 ? true : false} del={del2}  setDel={setDel2}/>}

        <div className="dashboard">
            <Menu/>
            <div className="main-dashboard">
                  <TopBar activePage={'Definições de caixa'} lastPage={{name:'Caixa',pathname:'/cash-management'}}/>
                  <div className="center">
                       <div className="loan-container">
                                   <div className="top-title">Definições</div>
                                   <div className="section">
                                         <div className="sub-title">Contas <button onClick={()=>setCreateInterestRates(true)}>Adicionar</button></div>
                                         <div className="_table">
                                            {<TableInterestRates setEdit={setEdit1} del={del1} setDel={setDel1}/>}
                                         </div>
                                   </div>
                                   <div className="section">
                                         <div className="sub-title">Categorias <button onClick={()=>setCreatePaymentInterest(true)}>Adicionar</button></div>
                                         <div className="_table">
                                            {<TableLatePaymentInterest setEdit={setEdit2} del={del2} setDel={setDel2}/>}
                                         </div>
                                   </div>
                       </div>
                  </div>
            </div>
        </div>
    </main>
    </>
  );
}
