import * as React from 'react';
import './style.css'
import { useData } from '../../../contexts/DataContext';

export default function Compontent({setEditTransation}) {

    const {data, setData, updateData} = useData();
    const [lastTransations,setLastTransations]=React.useState([])
 
 
   React.useEffect(()=>{
        if(data.loaded.dashboard) setLastTransations(data.dashboard.transations.last_transations)
   },[data.loaded,data.dashboard])

   function handleEdit(item) {
    return
    setEditTransation(item)
  }

  
  return (
      <div class="table-container last_transations">
    <table>
      <thead>
        <tr>
          <th>Hora</th>
          <th>Descrição</th>
          <th>Tipo de Transação</th>
          <th>Montante</th>
          <th>Referência</th>
        </tr>
      </thead>
      <tbody> 
       
          {lastTransations.map(i=>(
              <tr className="tr" onClick={()=>handleEdit(i)}>
                <td>{new Date(i.createdAt).toISOString().split('T')[1].replace('.000Z','').slice(0,5)}</td>
                <td>{i.description}</td>
                 <td>
                    <div style={{ backgroundColor:i.type=='in' ? '#CEDBF7':  '#F9CE65', color: '#111' , padding:'0.5rem',borderRadius:'2rem'}}>
                       <span style={{display:'flex',minWidth:'60px',justifyContent:'center'}}>  {i.type=='in' ? 'Entrada':  'Saida'}</span>
                   </div>
                  </td>
                  <td>{(i.type=="out" ? "-" : "") +""+new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(i.amount)}</td>
                 
                  <td>{i.reference}</td>
                  
              </tr>

           ))}
       
      </tbody>
    </table>

     {!lastTransations.length && <span style={{fontSize:'0.9rem',display:'flex',justifyContent:"center",padding:'2rem',opacity:0.4}}>Sem registros</span>}
  </div>
  );


}
