
import '../../css/main.css'
import './style.css'
import Menu from '../../common/menu.js';
import TopBar from "../../common/topBar";
import { useData } from '../../../contexts/DataContext';
import { useAuth } from '../../../contexts/AuthContext';
import { useState ,useEffect,useRef} from 'react';
import DynamicGridExample from './table.js'
import Create from './create-payment.js'
import CreateAssetTable from './assets-table.js'
import CreateInstallment from './create-installment.js'
import CreateAsset from './create-asset.js'
import toast from 'react-hot-toast';
import axios from 'axios'; 
import { useParams , useLocation, Outlet,useNavigate} from 'react-router-dom';
import MultiSelect from './select-search-input'
import CreateClient from '../../clients/create.js';
import CreateInterestRate from '../settings/create-interest-rates.js';
import CreateAccount from '../../cash-management/settings/create-accounts.js';
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import porExtenso from 'numero-por-extenso';

export default function _Create() {
const { id } = useParams()
const location = useLocation();
const currentPath = location.pathname;
const navigate=useNavigate()
const {data, setData, updateData, makeRequest} = useData();
const {user} = useAuth(); 
const [uploadedFiles, setUploadedFiles]=useState({})
const [message, setMessage]=useState(null)
const [createDialog, setCreateDialog] = useState(false);
const [_in, setIn] = useState(0);
const [_out, setOut] = useState(0);
const [_avaliable, setAvaliable] = useState(0);
const [openDialog, setOpenDialog] = useState('');
const [edit, setEdit] = useState(null);
const [client, setClient] = useState([]);
const [editingState,setEditingState]=useState(false);
const [accounts,setAccounts]=useState([]);
const [quillContent,setQuillContent]=useState('')


const [paid,setPaid]=useState(0)
const [create, setCreate] = useState(false);
const [createAsset, setCreateAsset] = useState(false);
const [editAsset, setEditAsset] = useState(false);
const [createInstallment, setCreateInstallment] = useState(false);
const [loanToEdit, setLoanToEdit] = useState(data.loanToEdit);
const [loading,setLoading]=useState(false)
const [installments, setInstallments]=useState([])
const [contract_payments,setContractPayments]=useState([])
const [editMode,setEditMode] = useState(false)
const [currentTab,setCurrentTab]=useState('details')
const [accountsA,setAccountsA]=useState([]);
const [contractAssets,setContractAssets]=useState([]);
const [newClientRes,setNewCLientRes]=useState({});
const [newInerestRatesRes,setNewInerestRatesRes]=useState({})

const modules = {
        toolbar: [
                                                                                  
        ],
        imageResize: {
            parchment: Quill.import('parchment'),
            modules: [ 'Resize', 'DisplaySize','Toolbar']
        },
}


/*

useEffect(()=>{
  
    (async () => {
                 try{
                       let res = await makeRequest({ url:'settings', error: ``});
                       data.settings=res
                       data.loaded.settings=true
                       setData({...data,settings:res})
                 }catch(e){}
    })();
  
    (async () => {
                 try{
                       let res = await makeRequest({ url:'transations', error: ``});
                       data.transations=res
                       data.loaded.transations=true
                       setData({...data,transations:res})
                 }catch(e){}

               
    })();
    (async () => {
                 try{
                       let res = await makeRequest({ url:'clients', error: ``});
                       data.clients=res
                       data.loaded.clients=true
                       setData({...data,clients:res})
                 }catch(e){}
    })();

},[])
*/



useEffect(()=>{
   async function fetchData() {
   
            try {
                let [settingsRes, transationsRes, clientsRes] = await Promise.all([
                    makeRequest({ url: 'settings', error: '' }),
                    makeRequest({ url: 'transations', error: '' }),
                    makeRequest({ url: 'clients', error: '' })
                ]);
                data.settings = settingsRes;
                data.transations = transationsRes;
                data.clients = clientsRes;
                setData(prev=>({...prev, loaded:{...data.loaded,transations:true,settings:true,clients:true}, settings: settingsRes, transations: transationsRes, clients: clientsRes }))
            } catch (e) {
                console.error('Error fetching data:', e);
            }
        }
 
  
   fetchData()

},[])


const initial_form={
  installments: [],
  id: null,
  client_id:null,
  disbursement_value: '',
  disbursement_date: '',
  refund_date: '',
  total_to_pay: 0,
  lacking: 0,
  periods: 0,
  fees_percentage: 0,
  paid: 0,
  number_of_Weeks: 0,
  days: 0,
  fees: 0,
  late_payment_interest: 0,
  declaration_of_residence_src: '',
  notes: '',
  installment_id: null
}

const [formData, setFormData] = useState(initial_form);


useEffect(()=>{

  if(currentPath=="/loans/create"){
    //setFormData(initial_form)
    setEditMode(false)
  }else{
    setEditMode(true)
  }

 if(window){
   setQuillContent(window.localStorage.getItem('contract-model-content') ? window.localStorage.getItem('contract-model-content') :'')
 }                               

},[currentPath])



function print_client_contract(){

      let client=data.clients.filter(i=>i.client_id==formData.client_id)[0]

     
      let ph={
        nome_da_empresa:user.microcredit.name,
        nuit_da_empresa:user.microcredit.nuit,
        localizacao_da_empresa:user.microcredit.name,
        nome_do_cliente:'[nome do cliente]',
        bairro_do_cliente:'[bairro do cliente]',
        bi_do_cliente:'[bilhete de identidade]',
        contacto_primario_do_cliente:'[Contacto primário do cliente]',
        contacto_secundario_do_cliente:'[Contacto secudário do cliente]',
        carta_de_conducao_do_cliente:'[Carta de condução do cliente]',
        avenida_do_cliente:'[Avenida do cliente]',
        numero_de_passaporte_do_cliente:'[Número de passaporte do cliente]',
        idade_do_cliente:'[Idade do cliente]',
        numero_da_casa_do_cliente:'[Número da casa do cliente]',
        email_do_cliente:'[Email do cliente]',
        valor_financiado:'[Valor financiado]',
        valor_financiado_por_extenso:'[Valor financiado por extenso]',
        total_a_pagar:'[Total a pagar]',
        total_a_pagar_por_extenso:'[Total a pagar por extenso]',
        percentagem_de_juros:'[Percentagem de juros]',
        percentagem_de_juros_de_mora:'[Percentagem de juros de mora]',
        periodo_em_dias:'[Periodo de pagamento]',
      }
      
      if(client){
           ph.nome_do_cliente=client.name + " " + client.last_name
           if(client.identity_number) ph.bi_do_cliente=client.identity_number
           if(client.neighborhood_name) ph.bairro_do_cliente=client.neighborhood_name
           if(client.primary_contact) ph.contacto_primario_do_cliente=client.primary_contact
           if(client.avenue) ph.avenida_do_cliente=client.avenue
           if(client.secundary_contact) ph.contacto_secundario_do_cliente=client.secundary_contact
           if(client.block_number) ph.Quarteirão=client.block_number
           if(client.passport_number) ph.numero_de_passaporte_do_cliente=client.passport_number
           if(client.driver_license_number) ph.driver_license_number=client.carta_de_conducao_do_cliente
           if(client.date_of_birth) ph.idade_do_cliente=new Date().getFullYear() - new Date(client.date_of_birth).getFullYear()
           if(client.house_number) ph.numero_da_casa_do_cliente=client.house_number
           if(client.email) ph.email_do_cliente=client.email
        
           ph.valor_financiado=new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(formData.disbursement_value)
           ph.valor_financiado_por_extenso=porExtenso.porExtenso(formData.disbursement_value) 
           ph.total_a_pagar=new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(formData.total_to_pay)
           ph.total_a_pagar_por_extenso=porExtenso.porExtenso(formData.total_to_pay) 
           if(formData.fees_percentage) ph.percentagem_de_juros=formData.fees_percentage
           if(formData.days) ph.periodo_em_dias=formData.days     
      }

      let contract_data=window.localStorage.getItem('contract-model-content') ? window.localStorage.getItem('contract-model-content') : ''
      
      Object.keys(ph).forEach((n,i)=>{
           contract_data=contract_data.replaceAll(`{${n}}`,ph[n])
           contract_data=contract_data.replaceAll(`{</em><strong><em>${n}</em></strong><em>}`,ph[n])
           contract_data=contract_data.replaceAll(`{<strong>${n}</strong>}`,`<strong>${ph[n]}</strong>`)
           contract_data=contract_data.replaceAll(`{<em>${n}</em>}`,ph[n])
           contract_data=contract_data.replaceAll(`{<strong><em>${n}</em></strong>}`,`<strong>${ph[n]}</strong>`)
           contract_data=contract_data.replaceAll(`{<span style="color: rgb(23, 87, 219);">${n}</span>}`,`${ph[n]}`)
           

           if(i==Object.keys(ph).length - 1){
              setQuillContent(contract_data)
              setTimeout(()=>window.print(), 300)
           }
      })

      //console.log(contract_data)
}

useEffect(()=>{

      if(!createDialog){
         setOpenDialog(null)
      }

},[createDialog])


/*

useEffect(()=>{


  if((formData.account_id && accounts.length != data.settings.accounts.length) || (!accounts.length && accounts.length != data.settings.accounts.length)){
      setAccounts(data.settings.accounts)
      if(!accounts.length)   setFormData({...formData,account_id:data.settings.accounts.filter(a=>!accounts.some(i=>i.id==a.id))[0].id})  
  }

  if(!data.settings.interest_rates)  return

  if(installments.length != data.settings.interest_rates.length){
     setFormData({...formData,installment_id:utils.get_installment_id()})
  }

  if(data.clients.length==1){
     utils.get_client_id()
  }


},[data.settings,data.settings.accounts,data.settings.interest_rates,data.clients])
*/

function openNewDialog(dialog){
   setCreateDialog(true)
   setOpenDialog(dialog)
}

useEffect(()=>{
  if(newClientRes?.id){
    setFormData({...formData,client_id:newClientRes.id})
    setClient(newClientRes)    
  }
},[newClientRes])

useEffect(()=>{
  if(newInerestRatesRes?.id){
    utils.get_installment_id()
    setFormData({...formData,installment_id:newInerestRatesRes.id})
    calc({...formData,installment_id:newInerestRatesRes.id})
    
  }
},[newInerestRatesRes])




let utils={
    daysBetween:function(date1, date2) {
      const milliseconds1 = date1.getTime();
      const milliseconds2 = date2.getTime();
      const diff = milliseconds2 - milliseconds1;
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      return days;
    },
    check_credit_bofore_update:function (){
           
           /* let last_account_id

            if(data.loanToEdit.account){
                 last_account_id=JSON.parse(data.loanToEdit.account).id
            }else{
                last_account_id=1
            }

            if(data.loanToEdit.client_id != formData.client_id || data.loanToEdit.client_id || formData.account_id != last_account_id){
                   accountsA.forEach((a,_a)=>{
                          //giving back
                          if(a.id==last_account_id){
                              accountsA[_a]=accountsA[_a] + data.loanToEdit.disbursement_value
                          }
                          //creating new one
                          if(a.id==formData.account_id){
                            accountsA[_a]=accountsA[_a] - formData.disbursement_value
                          }
                   })

            }*/

    },
    calculateEndDate:function (startDate, days) {

       /* if(!startDate) return 
        if(startDate.split('-')[1]=="02") days=days-1
        const startDateObject = new Date(startDate); 
        const currentMonth = startDateObject.getMonth();
        const daysInCurrentMonth = new Date(startDateObject.getFullYear(), currentMonth + 1, 0).getDate(); // Get the number of days in the current month
        if (days <= daysInCurrentMonth) {
        startDateObject.setDate(startDateObject.getDate() + days);
        } else {
        startDateObject.setDate(startDateObject.getDate() + days - daysInCurrentMonth);
        startDateObject.setMonth(currentMonth + 1); 
        }
        return startDateObject.toISOString().split('T')[0];*/

        if (!startDate) return;

        const startDateObject = new Date(startDate);
        if(startDate.split('-')[1]=="02") days=days-1
        let remainingDays = days;

        
    
        while (remainingDays > 0) {
            const currentMonth = startDateObject.getMonth();
            const daysInCurrentMonth = new Date(startDateObject.getFullYear(), currentMonth + 1, 0).getDate();
    
            if (remainingDays <= daysInCurrentMonth - startDateObject.getDate()) {
                startDateObject.setDate(startDateObject.getDate() + remainingDays);
                remainingDays = 0; // All days added, exit loop
            } else {
                remainingDays -= daysInCurrentMonth - startDateObject.getDate() + 1;
                startDateObject.setMonth(currentMonth + 1);
                startDateObject.setDate(1); // Move to the first day of next month
            }
        }
    
      return startDateObject.toISOString().split('T')[0];

    },
    get_installment_id:function() {

    

      function add(items){
            let new_installments=items.map(item => {
                return  { id: item.id, value: item.days +` dias (${item.percentage}%)` , percentage:item.percentage,days:item.days}
            })
            setInstallments(new_installments)
      }


      if(currentPath!="/loans/create"){

          if(!data.settings.interest_rates) return 

          let to_find_item=data.settings.interest_rates.filter(i=>i.days==data.loanToEdit.days && i.percentage==data.loanToEdit.fees_percentage)[0]

          if(to_find_item) {
               add(data.settings.interest_rates)
               return to_find_item.id
          }else{
              let settings=JSON.parse(JSON.stringify(data.settings))
              settings.interest_rates.unshift({id:0,days: data.loanToEdit.days,percentage:data.loanToEdit.fees_percentage}) 
              add(settings.interest_rates)
              return 0
          }
      }else if(currentPath=="/loans/create"){
              add(data.settings.interest_rates)
              return (data.settings.interest_rates.length ? data.settings.interest_rates[0].id : null)
      }

    },

    get_account_id:function(one){

    

        if(currentPath=="/loans/create" || !data.loanToEdit.account){

          
        
           
            setAccounts(data.settings.accounts.filter(i=>i.role!="loss" && i.role!="pledged"))
            if(formData.account_id){
              return formData.account_id
            }else{
              return  (data.settings.accounts.filter(i=>i.role!="loss" && i.role!="pledged").length ? data.settings.accounts.filter(i=>i.role!="loss" && i.role!="pledged")[0].id : null)
            }
        }else{



            if(!data.settings.accounts.filter(i=>i.role!="loss" && i.role!="pledged").length) return


            
            let _accounts=JSON.parse(JSON.stringify(data.settings.accounts.filter(i=>i.role!="loss" && i.role!="pledged")))
            let current_a=JSON.parse(data.loanToEdit.account)
            if(!_accounts.some(a=>a.id==current_a.id)){
               _accounts.unshift(current_a)
            }


          
            setAccounts(_accounts)

            if(!formData.account_id  && _accounts.length){
              data.loanToEdit.account_id=current_a.id
              return current_a.id
            }else{
              return formData.account_id
            }

        }
       
   },
    divideDateRange: function(startDate, endDate, numberOfParts, total_to_pay) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      const dateDiff = end - start;
      const interval = Math.floor(dateDiff / numberOfParts);

      let result = [];

      for (let i = 0; i < numberOfParts; i++) {
        const partStart = new Date(start.getTime() + i * interval);
        const partEnd = new Date(start.getTime() + (i + 1) * interval);
        if (i === numberOfParts - 1) {
          partEnd.setTime(end.getTime());
      }


      let item={id:Math.random(), start: partStart.toISOString().split('T')[0], end: partEnd.toISOString().split('T')[0] , total_to_pay: total_to_pay ? (total_to_pay /  numberOfParts): 0,payments_history:[]}
      item.initial_amount=item.total_to_pay
      result.push(item);

      }
     
      return result;

     },calculateLatePaymentIntervals:function(value, latePaymentArray) {


     
      let remainingValue = value;
      let result = [];

      for (let i = 0; i < latePaymentArray.length; i++) {
          const interval = latePaymentArray[i];
          const daysInRange = Math.min(remainingValue, interval.to - interval.from + 1);

          if (daysInRange > 0) {
              result.push({
                  days: daysInRange,
                  percentage: interval.percentage
              });
              remainingValue -= daysInRange;
          }

          if (remainingValue <= 0) {
              break;
          }
      }

      return result;

    },
    get_current_date:function () {

      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate
    },

    add_contract_payments:function (result){

                for (var i = 0; i < result.length; i++) {

                    if(!result[i].payments_history?.length){

                        let end=new Date(result[i].end)

                        result[i].can_cancel=true
                        
                        let _payday=result[i].editing_payday ? result[i].editing_payday : (result[i].paid && result[i].payday) ? result[i].payday : utils.get_current_date()
                        //let _payday=result[i].editing_payday ? result[i].editing_payday : (result[i].paid >= result[i].total_to_pay && result[i].payday) ? result[i].payday : utils.get_current_date()

                        result[i].delay=utils.daysBetween(end,new Date(_payday))

                        let left_to_pay=0

                        if(result[i].paid && result[i].paid < result[i].initial_amount){
                            left_to_pay=result[i].initial_amount - result[i].paid
                        }else{
                            left_to_pay=result[i].initial_amount
                        }


                       
                        if (result[i].delay <= 0 || result[i].status=="paid") {
                            result[i].delay=0
                            result[i].late_payment_interest=0
                          
                            if(result[i].status!="paid") {
                                result[i].total_to_pay = result[i].initial_amount
                                result[i].status="pending"
                            }  

                      }else{

                            if(!data.settings.late_payment_interest.some(j=>j.from > result[i].delay)){

                                   

                                    let start_counting=!data.settings.late_payment_interest.length ? 0 : Math.min(data.settings.late_payment_interest.map(item => item.from)) - 1
                                    const intervals = this.calculateLatePaymentIntervals(result[i].delay - start_counting, data.settings.late_payment_interest);
                                    let late_payment_interest=0

                                    intervals.forEach(j=>{
                                          late_payment_interest+=j.days * (j.percentage / 100) * left_to_pay
                                          console.log({delay:result[i].delay,days:j.days,res:j.days * (j.percentage / 100) * left_to_pay,intervals,start_counting,left_to_pay})

                                    })

                                    result[i].late_payment_interest=late_payment_interest
                                    result[i].total_to_pay = result[i].initial_amount + result[i].late_payment_interest

                            }else{
                                result[i].total_to_pay = result[i].initial_amount
                                result[i].late_payment_interest=0
                            }


                            if(result[i].status!="paid")  result[i].status="delayed"
                            
                        }

                  }else{


                    let _late_payment_interest=0


                    result[i].payments_history.forEach((f,_f)=>{
                       
                      let delay=utils.daysBetween(new Date(result[i].end),new Date(_f==0 ? f.payday : result[i].payments_history[_f - 1].payday))
                      
                      let initial_amount=result[i].initial_amount
                      let paid_before=0

                      result[i].payments_history.forEach((v,_v)=>{
                        if(_v < _f){
                            paid_before+=v.amount
                        }
                      })


                    
                          
                      if(delay > 0 && f.status!="paid"){

                        if(!data.settings.late_payment_interest.some(j=>j.from > delay)){
                          
                            let start_counting=!data.settings.late_payment_interest.length ? 0 : Math.min(data.settings.late_payment_interest.map(item => item.from)) - 1
                            
                            const intervals = this.calculateLatePaymentIntervals(delay - (_f==0 ? start_counting : 0), data.settings.late_payment_interest);
                            let late_payment_interest=0
                            let left=initial_amount - paid_before
 
                            intervals.forEach(j=>{
                                late_payment_interest+=(j.days) * (j.percentage / 100) * (left  < 0 ? 0 : left)
                            })

                            _late_payment_interest+=late_payment_interest
                            
                            if(result[i].status!="paid") result[i].status="delayed"
                        }   

                      }

                    })

                    result[i].late_payment_interest=_late_payment_interest
                    result[i].total_to_pay=result[i].initial_amount + _late_payment_interest



                    
                 }



                   


                   if(create){
                         if(result[i].id==create.id){
                              setCreate({...result[i],payday: create.payday})
                              result[i]=data.installmentToEdit
                         }
                    }


                }

                let late_payment_interest=0
                let paid=0
                
                for (var i = 0; i < result.length; i++) {
                     if(result[i].late_payment_interest) late_payment_interest+=result[i].late_payment_interest
                     if(result[i].paid) paid+=result[i].paid 

                }

                let lacking=formData.total_to_pay - paid + (paid > formData.total_to_pay ? paid - formData.total_to_pay : 0)

                setFormData(prev=>({...prev,installments:result,late_payment_interest,paid,lacking}))
                
                return {installments:result,late_payment_interest,paid,lacking} 
         
      },


      get_client_id:function(){
        

             if(currentPath=="/loans/create"){
                 if(!formData.client_id && data.clients.length){
                   setClient(data.clients[0])
                   return data.clients[0].client_id
                 }else{

                   return formData.client_id

                 }

             }else{

              if(!formData.client_id && data.loanToEdit.id){
                setClient({...data.loanToEdit.client,client_id:data.loanToEdit.client_id})
                return data.loanToEdit.client.id
              }else{
                return formData.client_id
              }   

            }
              
      },

      check_available_credit:function (_avaliable) {

        if(!formData.account_id) return
       
        if(_avaliable==0 && (!editMode || user.role!="manager" && editMode) && data.loaded.transations){
             setMessage('Sem saldo disponivel para o emprestimo!')
         }else if(_avaliable < formData.disbursement_value && (!editMode || user.role!="manager" && editMode)){
             setMessage('Apenas ' +_avaliable.toLocaleString()+ ' disponivel!')
         }else if((_avaliable + data.loanToEdit.lent) < formData.disbursement_value && editMode){
             setMessage('Apenas ' + (_avaliable + data.loanToEdit.lent).toLocaleString()+ ' disponivel!')
         }else{
             setMessage(null)
         }
     
     },
     init:function(formData){

     

        let new_data={...formData,
            installment_id:this.get_installment_id(),
            account_id:this.get_account_id(),
            client_id:this.get_client_id()
        }

        calc(new_data)
     },

      add_editing_loan:function(loan){

      
      setContractPayments(loan.contract_payments)
      setContractAssets(loan.contract_assets)

      
      let loan_detail={
        id:loan.id,
        installments:loan.contract_payments,
        fees:loan.fees,
        periods:loan.periods,
        fees_percentage:loan.fees_percentage,
        total_to_pay:loan.total_to_pay,
        days:loan.days,
        disbursement_value:loan.disbursement_value,
        disbursement_date:loan.disbursement_date,
        refund_date:loan.refund_date,
        paid:loan.paid,
        lacking:loan.lacking,
        late_payment_interest:loan.late_payment_interest,
        installment_id:this.get_installment_id(),
        account_id:this.get_account_id(),
        client_id:this.get_client_id()
     }

      setFormData(loan_detail)

      setUploadedFiles({...uploadedFiles,
        signed_contract_src:loan.signed_contract_src,
        declaration_of_residence_src:loan.declaration_of_residence_src,
        identity_ticket_src:loan.identity_ticket_src
      })

      setLoanToEdit(loan)
     }

}


console.log({formData})

function change_form(values){
    data.editingLoan=true
    calc(values)
}



useEffect(()=>{
  if(!currentPath)  return

  if(data.loaded.settings && data.loaded.transations && data.loaded.clients){

    if(currentPath=="/loans/create") {
        utils.init(initial_form) 
        
      
    }else{
        if(data.loanToEdit.id){
           setTimeout(()=>utils.add_editing_loan(data.loanToEdit),100)
        } 
       
    }
  }
},[data.loaded,data.loanToEdit,currentPath,data,loanToEdit]) 


useEffect(()=>{
   if(formData.periods) {
        utils.add_contract_payments(contract_payments)
      
   }

    if(contract_payments){
      if(contract_payments.length && contractAssets){
         if(contract_payments[contract_payments.length - 1].status!="delayed"){
          setContractAssets(contractAssets.map(i=>{return {...i,status:'retained'}}))
         }else{
          setContractAssets(contractAssets.map(i=>{return {...i,status:'confiscated'}}))
         }
      }
    } 

   
},[contract_payments]) 

useEffect(()=>{

  if(formData.periods > 50){
      toast.error('O número parcelas não deve exeder 50')
      calc({...formData,periods:1})
  }
},[formData.periods])


useEffect(()=>{

  utils.check_available_credit(_avaliable)

},[formData.disbursement_value])

function calc(formData){

     let total_to_pay=0;
     let refund_date="";
     let installments=[]
     let late_payment_interest=0
     let paid=0
     let days=0
     let fees_percentage=0
     let fees=0

     let selected_installment=data.settings.interest_rates.filter(i=>i.id==formData.installment_id)[0]

     if (selected_installment && formData.periods){
           let disbursement_value=parseFloat(formData.disbursement_value ? formData.disbursement_value : 0)
           total_to_pay=disbursement_value + ((parseFloat(selected_installment.percentage)/100) * disbursement_value)
           refund_date=utils.calculateEndDate(formData.disbursement_date, parseInt(selected_installment.days))
           days=selected_installment.days
           fees_percentage=selected_installment.percentage
           fees=(fees_percentage/100) * disbursement_value
     } 

     if(formData.disbursement_date && refund_date && total_to_pay){

           let calculated_installments=utils.divideDateRange(formData.disbursement_date, refund_date, formData.periods, total_to_pay)
           let _res= utils.add_contract_payments(calculated_installments)
           installments=_res.installments
           late_payment_interest=_res.late_payment_interest
           paid=_res.paid
          
     }

     setContractPayments(installments)

     setFormData({
        ...formData,
        total_to_pay,
        refund_date,
        installments,
        paid,
        late_payment_interest,
        days,
        fees_percentage,
        fees,
        lacking:total_to_pay - paid + (paid > total_to_pay ? paid - total_to_pay : 0),
     })

}

useEffect(()=>{
   setFormData({...formData,client_id:client.client_id})
},[client])


useEffect(()=>{
  let _a=0
  let _accounts=[]

  if(accounts.length && formData.account_id){
       accounts.forEach(i=>{
          let __in=0
          let __out=0
          let times=0
          data.transations.forEach(j=>{ if(j.account_id==i.id && j.type=="in" && (j.role!="pledged" && j.role!="loss")){
          __in+=j.amount
          times+=1

          }})
          data.transations.forEach(j=>{ if(j.account_id==i.id && (j.type=="out" || ((j.role=="pledged" || j.role=="loss") && j.type=="in"))) {
          __out+=j.amount
          times+=1

          }})
          let a=(__in - __out) <= 0 ? 0 : (__in - __out)
         // _a+=a

          console.log({id:i.id,name:i.name,in:__in,out:__out,available:a,times})

        _accounts.push({id:i.id,name:i.name,in:__in,out:__out,available:a,times})
    })

    setAccountsA(_accounts)

    accounts.forEach(f=>{
      /*new */ 
      if(f.available){
       _a+=f.available
      } 
    })

    let a=_accounts.filter(a=>a.id==formData.account_id)[0]?.available

    if(!a){
        a=0
    }

    setAvaliable(a)
    utils.check_available_credit(a)  
 }

},[formData.account_id, accounts, data.transations])


useEffect(()=>{
  if(currentPath!="/loans/create" && formData.client_id && formData.installment_id && !formData.account_id && data.settings.accounts.length){
    setFormData(prev=>({...prev,account_id:utils.get_account_id(1)}))
  }
},[formData.client_id,formData.installment_id,data.settings])






function resetForm() {
   utils.init({...initial_form,account_id:formData.account_id})
   setUploadedFiles({})
   setContractPayments([])
   setContractAssets([])
}


async function send_contract(){

  if(message) {
      toast.error(message)
      return
  }else if(!installments.length && !editMode){
      toast.error('Adicione as taxas de prestação')
      return
  }else if(!contract_payments.length || !formData.client_id){
     toast.error('Preencha todos os campos!')
     return
  }

  setLoading(true) 

  if(!editMode){

    try{

      let response = await makeRequest({method:'post',url:'loans/create',data:{...formData,...uploadedFiles,contract_assets:contractAssets}, error: ``},0);
      toast.success('Contrato criado com sucesso!')
      data.contracts=[...data.contracts,response.data]
      if(user.role=="manager") setAvaliable(_avaliable - formData.disbursement_value + formData.paid)
      setData(data)
      setLoading(false)  
      resetForm()
      

    }catch(error){
       toast.error('Ocorreu um erro: '+ error.message) 
    }



  }else{

      let reload=formData.installments.some(i=>i.cancelled)

      try{

          let response = await makeRequest({method:'post',url:'loans/update',data:{...formData,...uploadedFiles,contract_assets:contractAssets}, error: ``},0);
          toast.success('Contrato actualizado com sucesso!')
          let loans=JSON.parse(JSON.stringify(data.loans))
          let index=data.loans.findIndex(i=>i.id==formData.id)
          loans[index]=response.contract
          data.loanToEdit=response.contract
   
          setTimeout(()=>{
            window.location.reload()
          },1000)
         

          data.editingLoan=false
          setAvaliable(_avaliable + (parseFloat(paid) - loanToEdit.paid))
          data.loans=loans
          setData({...data,transations:[!data.transations.filter(i=>response.transations.some(f=>f.id==i.id)),response.transations]})

      }catch(error){
        toast.error('Ocorreu um erro: '+ error.message)  
      }
     
    }
    
    setLoading(false)

}


  const fileInputRef_1 = useRef(null);
  const fileInputRef_2 = useRef(null);
  const fileInputRef_3 = useRef(null);
  const [loadingFile,setLoadingFile]=useState(false)
  const [sendingFiles, setSendingFiles]=useState({})



  const onFileUpload = async (event) => {
    toast.remove()

    const formData = new FormData();
    let field=event.target.className
    formData.append('file', event.target.files[0]);
    formData.append('data', JSON.stringify({file_dest:field,id:formData.client_id}))

    if(field=="signed_contract_src"){
          //setSendingFiles({...sendingFiles,signed_contract_src:true})
    }

    toast.loading('A enviar ficheiro...')
    fileInputRef_1.current.value = ''


    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      //setSendingFiles({...sendingFiles,signed_contract_src:false})
      if(field=="signed_contract_src"){
           setUploadedFiles({...uploadedFiles,signed_contract_src:response.data})
      }else if(field=="identity_ticket_src"){
           setUploadedFiles({...uploadedFiles,identity_ticket_src:response.data})
      }else if(field=="declaration_of_residence_src"){
           setUploadedFiles({...uploadedFiles,declaration_of_residence_src:response.data})
      }
     
      toast.remove()
      toast.success('Arquivo enviado')
    } catch (error) {
      toast.remove()
      toast.error('Ocorreu um erro:'+error)
      console.error(error);
    }
  };

  function go_back_changes(){
          setContractPayments(data.loanToEdit.contract_payments)
          data.editingLoan=false
          utils.add_editing_loan(data.loanToEdit)

  } 

  function activeEDitLoan(){
      data.editingLoan=false
      setEditingState(true)
  }



useEffect(()=>{

    if(!currentPath || currentPath=="/loans/create" || data.loanToEdit.id || !data.loaded.settings) return
    (async () => {

        try{

        let [settingsRes, loanRes] = await Promise.all([
            makeRequest({ url: 'settings', error: '' }),
            makeRequest({url:'loans/'+id, error: ``})
        ]);

        
        setData(prev=>({...prev,loanToEdit:loanRes,settings:settingsRes,loaded:{...prev.loaded,settings:true}}))
        utils.add_editing_loan(loanRes)
        
        /*let response = await makeRequest({url:'loans/'+id, error: ``});
        setData(prev=>({...prev,loanToEdit:response}))
        utils.add_editing_loan(response)*/

        }catch(error){

             if(error.response){
                  if(error.response.status==404){
                    toast.remove()
                    toast.error('emprestimo não encontrado')
                    
                  }
             }else{
                 console.log(error)
             }

             navigate('/loans')

           
        }
     
   })();

},[currentPath,data.loaded])




return (
    <>
       <div className="quill-ed">
            <ReactQuill  
              value={quillContent}
              modules={modules}
            />
       </div>
      <main className="dashboard-container">

       { <CreateClient showD={openDialog == "clients" ? true : false} setEdit={setEdit}  create={createDialog} setCreate={setCreateDialog} setRes={setNewCLientRes}/>}
       { <CreateInterestRate showD={openDialog == "interest_rates" ? true : false} setEdit={setEdit}  create={createDialog} setCreate={setCreateDialog} editMode={editMode} setRes={setNewInerestRatesRes} />}
       { <CreateAccount showD={openDialog == "account" ? true : false} setEdit={setEdit}  create={createDialog} setCreate={setCreateDialog} editMode={editMode}  />}
       { <CreateAsset showD={createAsset || editAsset ? true : false} contract_payments={contract_payments} setEdit={setEditAsset} edit={editAsset}    create={createAsset} setCreate={setCreateAsset} editMode={editMode}  contractAssets={contractAssets} setContractAssets={setContractAssets} />}

       {<CreateInstallment showD={createInstallment ? true : false}  createInstallment={createInstallment} setCreateInstallment={setCreateInstallment} accounts={accounts} editMode={editMode} utils={utils} formData={formData} calc={calc}/>}

       {<Create showD={create ? true : false}  create={create} setCreate={setCreate} installments={formData.installments} editMode={editMode}  setEditingState={setEditingState} accounts={accounts}  utils={utils} contract_payments={contract_payments} setContractPayments={setContractPayments} utils={utils}/>}

        <div className="dashboard">
            <Menu/>
            <div className="main-dashboard">
               
                  <TopBar activePage={currentPath!="/loans/create" ? 'Editar contrato' : 'Criar contratos'} lastPage={{name:'Contratos',pathname:'/loans'}}/>

                  <div className="center">
                     
                       <div className={`loan-container ${currentPath=="/loans/create" || editingState ? '' : 'edit-mode'} ${loading ? 'loading' :''} `}>
                                   <div className="top-title">
                                                          {currentPath!="/loans/create" ? 'Editar contrato' : 'Criar contratos'}

                                                          {(loanToEdit.approval_status=="pending" && currentPath!="/loans/create") && <div>

                                                          <div className="status" style={{color:'#111',background:'#F9CE66',padding:'2px 5px',borderRadius:5}}><span style={{fontSize:12}}>Aprovação Pendente</span></div>

                                                          </div>}


                                                          {!editingState && editMode &&  <div className="btns">
                                                                 <button onClick={activeEDitLoan}>Alterar formúlario</button>
                                                          </div>}

                                                          {editingState && currentPath!="/loans/create"  && data.editingLoan &&  <div className="btns">
                                                                                                                        <button onClick={go_back_changes}>Redefinir dados iniciais</button>
                                                          </div>}
                                   </div>
                                   <div className="top-options">
                                         <span className={currentTab == "details" ? 'active' : ''} onClick={()=>setCurrentTab('details')}>Detalhes <label>do emprestimo</label></span>
                                         <span onClick={()=>setCurrentTab('models')}   className={currentTab == "models" ? 'active' : ''} >Modelo <label>de contratos</label></span>
                                         <span onClick={()=>setCurrentTab('assets')}   className={currentTab == "assets" ? 'active' : ''} >Bens <label>associados</label></span>
                                   </div>
                                   <div className="main-section">

                                           <div className="details" style={{display:currentTab == "details" ? 'block' : 'none'}} >
                                                  <div className="count-cards">
                                                      {(editingState || currentPath=="/loans/create") &&  <div className="item">
                                                                                                                <label>Saldo disponível</label>
                                                                                                                <span className="value">{_avaliable ? new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(_avaliable) : '0,00'}</span>
                                                     </div>}
                                                    
                                                      <div className="item">
                                                            <label>Total a pagar</label>
                                                            <span className="value">{formData.total_to_pay ? new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((formData.total_to_pay + formData.late_payment_interest +  (formData.paid > formData.total_to_pay ? formData.paid - formData.total_to_pay : 0))) : '0,00'}</span>
                                                     </div>
                                                     <div className="item">
                                                            <label>Financiamento</label>
                                                            <span className="value">{formData.disbursement_value ? new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(formData.disbursement_value) : '0,00'}</span>
                                                      </div>
                                                      <div className="item">
                                                            <label>Pago</label>
                                                            <span className="value">{formData.paid ? new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(formData.paid) : '0,00'}</span>
                                                      </div>
                                                      <div className="item">
                                                            <label>Em falta</label>
                                                            <span className="value">{formData.lacking ? new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(formData.lacking) : '0,00'}</span>
                                                     </div>
                                                      <div className="item">
                                                            <label>Juros</label>
                                                            <span  className="value">{formData.fees ? new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(formData.fees)  : '0,00'}</span>
                                                     </div>
                                                      <div className="item">
                                                            <label>Juros de mora</label>
                                                            <span className="value">{new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(formData.late_payment_interest)}</span>
                                                     </div>
                                                     
                                                     <div className="item">
                                                            <label>Data de reembolso</label>
                                                            <span className="value">{formData.refund_date ? formData.refund_date : '-'}</span>
                                                     </div>

                                                      {editMode &&  <div className="item">
                                                            <label>Cliente</label>
                                                            <span className="value">{client.name ? `${client.name} ${client.last_name}`:'-'}</span>
                                                      </div>}
                                                  </div>

                                                  {(!data.loaded.settings || !data.loaded.transations || !data.loaded.clients || (!loanToEdit.id && currentPath!="/loans/create"))  && <div style={{textAlign:'center'}} className="create-loan-requirements-loader">
                                                                                                       <span className="loader"></span>
                                                     </div>}

                                                  <div style={{display:(!data.loaded.settings || !data.loaded.transations || !data.loaded.clients) ||  (!editingState && currentPath!="/loans/create") ? 'none' :'flex'}} className="input-container">
                                                     <div className="inputs">
                                                           <span>Conta de desembolso</span>

                                                              <select value={formData.account_id} onChange={e=>(
                                                                data.editingLoan=true,
                                                                setFormData({...formData,account_id:e.target.value})
                                                              )}>
                                                                  {(!accounts.length || !formData.id) && <option disabled selected value="">Selecione a conta</option>} 
                                                                  {accounts.map(i=>(
                                                                        <option key={i.id} selected={formData.account_id === i.id} value={i.id}>{i.name}</option>
                                                                  ))}
                                                              </select>                                    

                                                           <span style={{display:'none'}} className="btn-add" onClick={()=>openNewDialog('account')}><svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="24"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"></path></svg></span>
          
                                                      </div>


                                                     <div className="inputs search-select">
                                                            <span>Cliente</span>
                                                            <MultiSelect options={data.clients} setSelectedOptions={setClient} selectedOptions={client} placeholder={'Adicione cliente'} noOptionsMessage={'Sem opções'}/>
                                                            <span className="btn-add" onClick={()=>openNewDialog('clients')}><svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="24"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"></path></svg></span>
                                                     </div>

                                                      <div className="inputs">
                                                         <span>Montante por desembolsar</span>
                                                         <input disabled={loading ? true : false} value={formData.disbursement_value} onChange={e=>change_form({...formData,disbursement_value:parseFloat(e.target.value)})} type="number" placeholder="valor"/>
                                                      </div>
                                                      

                                                       <div className="inputs">
                                                           <span>Prestação</span>
                                                            <select disabled={loading ? true : false} value={formData.installment_id}  onChange={e=>change_form({...formData,installment_id:e.target.value})}>
                                                                 {!installments.length && <option disabled selected value="">Selecione a prestação</option>} 
                                                                 {installments.map(i=>(
                                                                       <option key={i.id} selected={formData.installment_id === i.id} value={i.id}>{i.value}</option>
                                                                 ))}
                                                            </select>
                                                           {user.role=="manager" && <span className="btn-add" onClick={()=>openNewDialog('interest_rates')}><svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="24"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"></path></svg></span>}
                                              
                                                        </div>

                                                       <div className="inputs">
                                                         <span>Parcelas</span>
                                                         <input disabled={loading ? true : false} value={formData.periods ?  formData.periods : ''} onChange={e=>change_form({...formData,periods:parseInt(e.target.value)})}  type="number" placeholder="Parcelas"/>
                                                      </div>
                                                       <div className="inputs">
                                                         <span>Data de desembolso</span>
                                                         <input type="date" disabled={loading ? true : false} value={formData.disbursement_date} onChange={e=>change_form({...formData,disbursement_date:e.target.value})}/>
                                                      </div>
                                                      
                                                      <div className="inputs _full">
                                                          <span>Nota</span>
                                                          <textarea disabled={loading ? true : false} style={{height:'80px'}} value={formData.notes} onChange={e=>setFormData({...formData,notes:e.target.value})} placeholder="Nota..."></textarea>
                                                      </div>
                                                  </div>

                                                  <div className="_table">
                                                     {0==1 && <div className="btn-add-installment">
                                                       <div className="btn" onClick={()=>setCreateInstallment(true)}>
                                                       <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#2360E4" d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"></path></svg>
                                                         Adicionar prestação
                                                       </div>
                                                       </div>}
                                                      <DynamicGridExample  setCreate={setCreate} editMode={editMode} installments={contract_payments}/>
                                                  </div>
                                           </div>


                                           <div className="contract_model" style={{display:currentTab == "models" ? 'block' : 'none'}}>
                                                   <div className="section-div download-model">
                                                             <span>Modelo de contrato do cliente</span><button onClick={print_client_contract}>Imprimir</button>
                                                   </div>
                                                   <div className="sending-inputs">
                                                            <span>Carregar modelo de contrato assinado</span>
                                                            <div className="inputs-c">
                                                                <input type="file" ref={fileInputRef_1} onChange={onFileUpload} className="signed_contract_src"/>
                                                                <button>Selecionar arquivo</button>
                                                                <span>
                                                                      {uploadedFiles.signed_contract_src && (<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="24"><path fill="green" d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"></path></svg>)}
                                                                </span>
                                                            </div>
                                                            {uploadedFiles.signed_contract_src && <div className="see-file" onClick={()=>setUploadedFiles({...uploadedFiles,signed_contract_src:null})}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
                                                            </div>}
                                                            {uploadedFiles.signed_contract_src && <div className="see-file" >
                                                                      <a target="_blank" href={`${process.env.REACT_APP_BASE_URL}/uploaded_files/${uploadedFiles.signed_contract_src}`}><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m216-160-56-56 464-464H360v-80h400v400h-80v-264L216-160Z"/></svg></a>
                                                            </div>}
                                                </div>
                                                 <div className="sending-inputs">
                                                            <span>Carregar bilhete de identidade</span>
                                                            <div className="inputs-c">
                                                                <input type="file" ref={fileInputRef_1} onChange={onFileUpload} className="identity_ticket_src"/>
                                                                <button>Selecionar arquivo</button>
                                                                <span>
                                                                      {uploadedFiles.identity_ticket_src && (<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="24"><path fill="green" d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"></path></svg>)}
                                                                </span>
                                                            </div>
                                                            {uploadedFiles.identity_ticket_src && <div className="see-file" onClick={()=>setUploadedFiles({...uploadedFiles,identity_ticket_src:null})}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
                                                            </div>}
                                                            {uploadedFiles.identity_ticket_src && <div className="see-file" >
                                                                      <a target="_blank" href={`${process.env.REACT_APP_BASE_URL}/uploaded_files/${uploadedFiles.identity_ticket_src}`}><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m216-160-56-56 464-464H360v-80h400v400h-80v-264L216-160Z"/></svg></a>
                                                            </div>}
                                                </div> <div className="sending-inputs">
                                                            <span>Carregar declaração de residência</span>
                                                            <div className="inputs-c">
                                                                <input type="file" ref={fileInputRef_1} onChange={onFileUpload} className="declaration_of_residence_src"/>
                                                                <button>Selecionar arquivo</button>
                                                                <span>
                                                                      {uploadedFiles.declaration_of_residence_src && (<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="24"><path fill="green" d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"></path></svg>)}
                                                                </span>
                                                            </div>
                                                            {uploadedFiles.declaration_of_residence_src && <div className="see-file" onClick={()=>setUploadedFiles({...uploadedFiles,declaration_of_residence_src:null})}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
                                                            </div>}
                                                            {uploadedFiles.declaration_of_residence_src && <div className="see-file" >
                                                                      <a target="_blank" href={`${process.env.REACT_APP_BASE_URL}/uploaded_files/${uploadedFiles.declaration_of_residence_src}`}><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m216-160-56-56 464-464H360v-80h400v400h-80v-264L216-160Z"/></svg></a>
                                                            </div>}
                                                </div>
                                           </div>



                                           <div className="contract_model" style={{display:currentTab == "assets" ? 'block' : 'none'}}>
                                              <div class="sub-title">Bens  <button onClick={()=>setCreateAsset(true)}>Adicionar</button></div>
                                               <div className="_table">
                                                 <CreateAssetTable  contractAssets={contractAssets} setEdit={setEditAsset} setContractAssets={setContractAssets}  setCreate={setCreateAsset}/>
                                               </div>  
                                           </div>

                                   </div>

                                   <div className="btn-send" style={{display:currentTab == "details" ? 'flex' : 'none'}}>
                                        {<div className="div_btn"><button onClick={send_contract} className="save"><span>{currentPath=="/loans/create" ? 'Enviar' : 'Enviar'}</span></button><span className="loader"></span></div>}
                                   </div>

                                   <div className={`message ${message ? 'show' :''}`}>
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="25"><path fill="#FFA500" d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg>
                                          <span>{message}</span>
                                   </div>
                       </div>

                  </div>

                  
            </div>
        </div>
    </main>
    </>
  );
}
