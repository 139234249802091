import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate, Outlet , useLocation} from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useData } from '../contexts/DataContext';
import toast from 'react-hot-toast';
import LandingPageHome from '../components/landing-page/home/home';

const ProtectedRoute = ({ children, redirectTo = '/', path }) => {
  const { isAuthenticated, user, login, loading, token,logout } = useAuth();
  const {fire } = useData();
  const location=useLocation()


  useEffect(()=>{
    fire.init()
  },[])

 

  let page_access={
         admin:['/loans'],
         manager:['/microcredits','/managers'],
         operator:['/microcredits','/managers','/cash-management/settings'],
         client:['/microcredits','/managers','/operators']
  }

 let n;


  if(redirectTo=="/logout" && token && user){
        logout() 
        toast.remove()
        toast.success('Logout successfuly!')
        return <Navigate to={'/login'} replace />
  }


  if (loading) {
      return <Outlet/>;
  }else if(!user && !loading){

      if(location.pathname=="/"){
          return <LandingPageHome/>
      }

      return <Navigate to={'/login'} replace />
  }else if(user.role=="client"){
      toast.remove()
      toast.error('Página restrita para clientes')
      return <Navigate to={'/login'} replace />
  }else{
    if(page_access[user.role].includes(location.pathname)){
           toast.remove()
           toast.error('Página restrita')
           return <Navigate to={'/'} replace />
     }
     return isAuthenticated ? children : <Navigate to={redirectTo} replace />;
  }
};


export default ProtectedRoute;



