import React from 'react'
import './style.css'
import {Link, useLocation,useNavigate} from 'react-router-dom'


const NotFound = () =>
  <div className="c">
    <h3 className="pb-4">[Página não encontrada]</h3>
     
    <p><Link to={'/'} className="underline">Página inicial</Link></p>
  </div>

export default NotFound