


import {Link, useLocation,useNavigate} from 'react-router-dom'
import { useState,useEffect } from 'react';
import { useAuth  } from '../../contexts/AuthContext';
import { useData } from '../../contexts/DataContext';


const Not = () => {
const {user} = useAuth()
const {data,setData} = useData()
const navigate = useNavigate();
const [notifications,setNotifications]= useState([]);


useEffect(()=>{
    render_nots(data.notifications)
},[data.notifications, data])


function render_nots(nots) {

	   nots.forEach((n,i)=>{
	   	       nots[i].time=new Date(n.createdAt).toISOString().split('T')[1].split(':')[0] +":"+ new Date(n.createdAt).toISOString().split(':')[1]
	   	       nots[i].date=new Date(n.createdAt).toISOString().split('T')[0]


	   	      if(n.type=="contract_rejected"){
	   	      	  nots[i].title="Seu contrato foi aprovado pelo gestor"
	   	      	  nots[i].type="Empréstimo"
	   	      }

	   	      if(n.type=="contract_rejected"){
	   	      	  nots[i].title="Seu contrato foi rejeitado pelo gestor"
	   	      	  nots[i].type="Empréstimo"
	   	      }
	   }) 

	   setNotifications(nots)  
}


const location = useLocation();
const pathname = location.pathname

  return (
       <div className="notifications-dialog">
                                     <div className="top-title">Notificações <span className="mark-as-read">Marcar todas como lidas</span></div>
                                     <div className="messages">
                                          <div className="no-messages">Sem notificações</div>
                                          {notifications.map(i=>(
											<div className="message">
												<div className="__top">
													<span className={`type ${i.type}`}>{i.type}</span>
													<span className="date">{i.date} {i.time}</span>
												</div>
												<span className="m">{i.title}</span>
											</div>
                                         ))}
                                     </div>
        </div>
  )
};

export default Not;
