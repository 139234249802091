import * as React from 'react';

import { useEffect, useState,useRef} from 'react';
import { useData } from '../../../contexts/DataContext';
import { useAuth } from '../../../contexts/AuthContext';

import { DataGrid } from '@mui/x-data-grid';
import CustomNoRowsOverlay from '../../common/tableLoader'


export default function DataGridDemo({setEdit,setDel,del,contractAssets,setContractAssets}) {

    const [openMenu, setOpenMenu] = React.useState(null);
    const {data, setData} = useData();
    const {token, user} = useAuth();
    
    const handleOutsideClick = (event) => {
        if (!event.target.closest('.menu') &&  !event.target.closest('.menu-option')) {
          setOpenMenu(null)
          document.removeEventListener('click', handleOutsideClick);
        }
    };

    
    const  handleClickMenu = (id) => {
         document.addEventListener('click', handleOutsideClick);
         setOpenMenu(id)
    };

    function removeMediaItem(id){
      setContractAssets(contractAssets.filter(i=>i.id!=id))
    }

    const columns = [
    {
        field: 'description',
        headerName: 'Descrição',
        width: 170,
        editable: true,
    },
    {
      field: 'amount',
      headerName: 'Valor',
      width: 170,
      editable: true,
      renderCell: (params) => (
        <div>{params.row.amount ? params.row.amount :'-' }</div>
      )
    },
    {
        field: 'status',
        headerName: 'Estado',
        width: 120,
        valueGetter: (params) => params.row.status || "pending",
        renderCell: (params) => (
          <div style={{cursor:'pointer'}} onClick={() => handleEdit(params.row)}>
              <div style={{ backgroundColor:params.row.status=='retained' ? '#F9CE66': '#FF6B6B', color: '#111' , padding:'0.5rem',borderRadius:'2rem'}}>
                <span style={{display:'flex',minWidth:'60px',justifyContent:'center'}}>  {params.row.status=='retained' || !params.row.status ? 'Retido':  'Confiscado'}</span>
              </div>
          </div>
        )
      },
      {
        field: 'category',
        headerName: 'Categoria',
        width: 170,
        editable: true,
        renderCell: (params) => (
          <div>{params.row.category_id ? data.settings.inventory_categories.filter(i=>i.id==params.row.category_id)[0].name :'-'}</div>
        )
      },
      {
        field: 'date_of_acquisition',
        headerName: 'Data de aquisição',
        width: 170,
        editable: true,
        valueGetter: (params) => params.row.date_of_acquisition  || "-",
      },
      {
        field: 'registration_number',
        headerName: 'Número de registro',
        width: 170,
        editable: true,
        valueGetter: (params) => params.row.registration_number  || "-",
      },
      {
        field: 'location',
        headerName: 'Localização',
        width: 170,
        editable: true,
        valueGetter: (params) => params.row.location  || "-",
      },
      {
        field: 'media',
        headerName: 'Fotos ou documentos',
        width: 170,
        renderCell: (params) => (
            <div>
                    <div class="media-items" style={{display:'flex',maxWidth:'100px',overflow:'auto'}}>
                          {(typeof params.row.media == "string"  ? JSON.parse(params.row.media) : params.row.media).map(i=>(
                             <a key={i.name} target="_blank" href={`${process.env.REACT_APP_BASE_URL}/uploaded_files/${i.name}`}>
                                <div className="item" style={{backgroundPosition:'center',backgroundSize:'cover',backgroundImage:`url('${process.env.REACT_APP_BASE_URL}/uploaded_files/${i.name.replace(' ','%20')}')`}}>
                                 </div>
                              </a>
                          ))}
                    </div>
                 
            </div>
        )
      },
      {
      field: 'edit',
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <div>
                     <div style={{cursor:'pointer'}} onClick={() => handleEdit(params.row)}>
                           <svg style={{opacity:.6}}  xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg>
                     </div>
       </div>
      )
    },
    {
      field: 'more',
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <div style={{cursor:'pointer', position:'absolute'}} onClick={()=>handleClickMenu(params.row.id)}  className="menu">
                      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z"/></svg>
                      <div className="menu-dropdown-row" style={{position:'absolute',display:openMenu==params.row.id ? 'block' :'none'}}>
                                  <span className={`menu-option`} onClick={() =>removeMediaItem(params.row.id)}> <svg style={{opacity:.6}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>Remover</span>
                      </div>
          </div>
      )
    }
  ];


  function handleEdit(row) {
      setEdit(row)
  }
   
  
  return (
      <DataGrid
          sx={{ height: 260, width: '100%' , maxHeight:300}}
          rows={contractAssets}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 6,
              },
            },
          }}
          pageSizeOptions={[6]} 
          disableColumnMenu
          localeText={{ noRowsLabel: <CustomNoRowsOverlay loading={false}/>,  pagination: {labelRowsPerPage: 'Mostrar por página'} }}
        
     />
  );
}




      