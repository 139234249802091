
import './style.css'
import Menu from '../../common/menu.js';
import TopBar from "../../common/topBar";
import React,{ useState , useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useData } from '../../../contexts/DataContext';
import { useAuth } from '../../../contexts/AuthContext';
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import ImageResize from 'quill-image-resize-module-react'
import './quill-custom-styles.css'; // Custom styles
import toast from 'react-hot-toast';
import axios from 'axios';


Quill.register('modules/imageResize', ImageResize)


export default function Managers() {

const {token,user} = useAuth();
const {data,setData} = useData();
let [content,setContent]=React.useState('')
const [editMode,setEditMode]=React.useState(false)
const [loading,setLoading]=React.useState(false)
const [contractLoaded,setContractLoaded]=React.useState(false)
const [editingContent,setEditingContent]=React.useState('')


let ed_c=document.querySelector('.ql-editor')
useEffect(()=>{
     if(window){


         if(window.localStorage.getItem('contract-model-content')){
             setContractLoaded(true)
             setContent(window.localStorage.getItem('contract-model-content'))

         }
         
      } 
},[])


function cancel(){

    document.querySelector('.ql-editor').innerHTML=window.localStorage.getItem('contract-model-content')
    setEditMode(false)
}



  function save(){
    setLoading(true)
    toast.loading('A salvar template...')
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/microcredit/contract-template/update`,{content},{
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {
              setLoading(false)
              if(window) window.localStorage.setItem('contract-model-content',content)
              toast.remove()
              toast.success('Template actualizado!')
              setEditMode(false)
                                            
          })
        .catch(error => {
              setLoading(false)
              toast.error('Erro, tente novamente!')
              console.error('Errordata:', error);
        });  
  }


    React.useEffect(()=>{
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/microcredit/contract-template`, {

          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then(response => {
               setContent(`${response.data}`)
               //console.log(response.data)
               //document.querySelector('.ql-editor').innerHTML='response.data'
               setContractLoaded(true)
               if(window) window.localStorage.setItem('contract-model-content',response.data)
                                            
          })
        .catch(error => {
              console.error('Errordata:', error);
        });

    },[])

  
    const modules = {
        toolbar: [

            [{'header': [1, 2, 3, 4, 5, 6, false]}],

            ['bold', 'italic', 'underline', 'strike'],                                                      // toggled buttons
            ['blockquote', 'code-block'],

            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}, {'align': []}],
            [{'script': 'sub'}, {'script': 'super'}],                                                       // superscript/subscript
            [{'direction': 'rtl'}],                                                                         // text direction,

            [{'color': []}, {'background': []}],                                                            // dropdown with defaults from theme

            ['link', 'image'],

            ['clean']                                                                                       // remove formatting button
        ],
        imageResize: {
            parchment: Quill.import('parchment'),
            modules: [ 'Resize', 'DisplaySize','Toolbar']
        },
    }

return (
    <>
    <main className="dashboard-container">

        <div className="dashboard">

            <Menu/>
            <div className="main-dashboard">
               
                  <TopBar activePage={'Modelo de contractos'}/>

                  <div className="center">
                    <div className="template-container">

                              <div className="contract-template-placeholders">
                                 <span className="title">Parametros</span>
                                 <div className="params">
                                      <div><span className="name">Nome da empresa:</span><span className="p">{`{nome_da_empresa}`}</span></div>
                                      <div><span className="name">Localização da empresa:</span><span className="p">{`{localizacao_da_empresa}`}</span></div>
                                      <div><span className="name">Nuit da empresa:</span><span className="p">{`{nuit_da_empresa}`}</span></div>
                                      <div><span className="name">Nome do cliente:</span><span className="p">{`{nome_do_cliente}`}</span></div>
                                      <div><span className="name">BI do cliente:</span><span className="p">{`{bi_do_cliente}`}</span></div>
                                      <div><span className="name">Bairro do cliente:</span><span className="p">{`{bairro_do_cliente}`}</span></div>
                                      <div><span className="name">Contacto primário do cliente:</span><span className="p">{`{contacto_primario_do_cliente}`}</span></div>
                                      <div><span className="name">Carta de condução do cliente:</span><span className="p">{`{carta_de_conducao_do_cliente}`}</span></div>
                                      <div><span className="name">Avenida do cliente:</span><span className="p">{`{avenida_do_cliente}`}</span></div>
                                      <div><span className="name">Número da casa do cliente:</span><span className="p">{`{contacto_primario_do_cliente}`}</span></div>
                                      <div><span className="name">Idade do cliente:</span><span className="p">{`{idade_do_cliente}`}</span></div>
                                      <div><span className="name">Número de passaporte do cliente:</span><span className="p">{`{numero_de_passaporte_do_cliente}`}</span></div>
                                      <div><span className="name">Contacto secudário do cliente:</span><span className="p">{`{contacto_secundario_do_cliente}`}</span></div>
                                      <div><span className="name">Email do cliente:</span><span className="p">{`{email_do_cliente}`}</span></div>
                                      <div><span className="name">Valor financiado:</span><span className="p">{`{valor_finaciado}`}</span></div>
                                      <div><span className="name">Valor financiado por extenso:</span><span className="p">{`{valor_finaciado_por_extenso}`}</span></div>
                                      <div><span className="name">Período em dias:</span><span className="p">{`{periodo_em_dias}`}</span></div>
                            
                                   </div>
                             </div>
                             <div className={`my-quill-editor-container ${editMode ? 'editing' :''} ${contractLoaded ? 'loaded' :''} ${loading ? 'saving' :''}`}>
                                    <div className="float-bar">
                                      <button className="cancel" onClick={cancel}>Cancelar</button>
                                      <button className="save" onClick={save}>Salvar</button>
                                      <button className="edit" onClick={()=>setEditMode(true)}>Editar</button>
                                    </div>
                                    <div className="loader">A carregar...</div>
                                    <ReactQuill 
                                        value={content}
                                        onChange={(value) => {
                                            setContent(value)
                                        }}
                                        modules={modules}
                                    />
                             </div>
                    </div>
                  </div>

                  
            </div>
        </div>
    </main>
    </>
  );
}
