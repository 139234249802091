import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useData } from '../../../contexts/DataContext';
import { useAuth } from '../../../contexts/AuthContext';
import toast from 'react-hot-toast';
import axios from 'axios';
import CustomNoRowsOverlay from '../../common/tableLoader'





export default function DataGridDemo({setEdit,setDel,del}) {


    const [openMenu, setOpenMenu] = React.useState(null);

    const handleOutsideClick = (event) => {
        if (!event.target.closest('.menu') &&  !event.target.closest('.menu-option')) {
          setOpenMenu(null)
          document.removeEventListener('click', handleOutsideClick);
        }
    };


    const  handleClickMenu = (id) => {
         document.addEventListener('click', handleOutsideClick);
         setOpenMenu(id)
    };


    const columns = [
    {
      field: 'id',
      headerName: '#',
      width: 170,
      editable: true,
    },
    {
      field: 'name',
      headerName: 'Nome',
      width: 170,
      editable: true,
    },
    {
      field: 'edit',
      headerName: '',
      width: 30,
      renderCell: (params) => (
        <div style={{cursor:'pointer'}} onClick={() => handleEdit(params.row)}>
               <svg style={{opacity:.6}}  xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg>
       </div>
      )
    },
     {
      field: 'more',
      headerName: '',
      width: 130,
      renderCell: (params) => (
        <div style={{display:'none',cursor:'pointer', position:'absolute'}} onClick={()=>handleClickMenu(params.row.id)}  className="menu">
                      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z"/></svg>
                      <div className="menu-dropdown-row" style={{position:'absolute',display:openMenu==params.row.id ? 'block' :'none'}}>
                                   <span className={`menu-option`} onClick={() =>handleDelete(params.row)}> <svg style={{opacity:.6}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>Excluir</span>
                      </div>
          </div>
      )
    }
  ];


  function handleEdit(row) {
      setEdit(row)
  }
   function handleDelete(row) {
       console.log(row)
       setDel({id:row.id,confirm:null})
  }

  const {token,user} = useAuth();
  const {data,setData,makeRequest} = useData();
  const [loading,setLoading]=React.useState(false)

  const [rows,setRows]=React.useState(data.settings?.inventory_categories? data.settings.inventory_categories : [])

  React.useEffect(()=>{
     setRows(data.settings?.inventory_categories ? data.settings.inventory_categories : [])
  },[data,data.settings,data.settings.inventory_categories])



  React.useEffect(()=>{

    async function fetchData() {
             try {
                 let [settingsRes] = await Promise.all([
                     makeRequest({ url: 'settings', error: '' })
                 ]);
                 data.settings = settingsRes;
                 setData(prev=>({...prev, loaded:{...data.loaded,settings:true},settings:settingsRes}))
             } catch (e) {
                 console.error('Error fetching data:', e);
             }
         }
   fetchData();
 
 },[])


   React.useEffect(()=>{
    if(del?.confirm){

      axios.delete(`${process.env.REACT_APP_BASE_URL}/api/inventory/settings/categories/delete/`+del.id ,{
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {
            data.settings.inventory_categories=data.settings.inventory_categories.filter(i=>i.id!=del.id)
            toast.success('Eliminado com sucesso')
            setData(data)
            setDel(null)
        })
        .catch(error => {
            setLoading(false)
            console.error('Error fetching user data:', error);

            try{
                   toast.error(error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
            }catch(e){
                   toast.error('Error, try again!')
            }

            setDel(null)
        });
      
    }
  },[del])
  
  
  return (
      <DataGrid
          sx={{ height: 260, width: '100%' , maxHeight:300}}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 6,
              },
            },
          }}
          pageSizeOptions={[6]} 
          disableColumnMenu
          localeText={{ noRowsLabel: <CustomNoRowsOverlay loading={!data.loaded.clients ? true : false}/>,  pagination: {labelRowsPerPage: 'Mostrar por página'} }}
        
     />
  );
}


