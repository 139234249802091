
import '../css/main.css'
import './style.css'
import Menu from '../common/menu.js';
import TopBar from "../common/topBar";
import Create from './create.js'
import DeleteDialog from './delete-dialog.js'
import { useState } from 'react';
import DynamicGridExample from './table.js'
import CreateImportExcel from '../common/create-import-excel.js'
import { useData } from '../../contexts/DataContext';

export default function Clients() {
const [activePage, setActivePage] = useState('Clientes');
const [create, setCreate] = useState(false);
const [edit, setEdit] = useState(null);
const [del, setDel] = useState(null);
const [tableSearch,setTableSeach]=useState('');
const [createImport, setCreateImport] = useState(false);
const {data,setData,makeRequest} = useData();

function importExcel(){
    setCreateImport(true)
}



return (
    <>
    <main className="dashboard-container">
        { <CreateImportExcel showD={(createImport || edit) ? true : false}  create={createImport} setCreate={setCreateImport} setEdit={setEdit} edit={edit} del={del} export_details={{name:'clientes',api:'clients'}}/>}
        { <Create showD={(create || edit) ? true : false}  create={create} setCreate={setCreate} setEdit={setEdit} edit={edit} del={del}/>}
         {<DeleteDialog del={del} showD={(del) ? true : false} setDel={setDel}/>}

        <div className="dashboard">

            <Menu activePage={activePage}/>

            <div className="main-dashboard">
               
                  <TopBar activePage={activePage}/>

                  <div className="center">

                       <div className="center_search">

                           <div className="left_side">
                               <div className="search_container">
                                     <div className="search-div">
                                        <input type="text" placeholder="Pesquisar..." value={tableSearch} onChange={(e)=>setTableSeach(e.target.value)}/>
                                       <div className="search-btn">
                                           <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"/></svg>
                                        </div>
                                     </div>
                               </div>
                           </div>


                           <div className="btn-add-item" onClick={()=>setCreate(true)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#fff" d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"></path></svg>
                                    <span>Adicionar</span>
                           </div>
                       </div>


                       <div className="dasboard-item-list-container">
                       <div className="top-section" style={{display:'flex'}}>
                                <div className="left-side" >
                                        {data.loaded.clients && <>
                                            <div className="show">
                                            <span>Resultados:</span>
                                            <label>{data.clients.length}</label>
                                        </div>
                                          </>}
                                 </div>

                                            <div className="right-side">
                                                    <span onClick={()=>importExcel()} className="export export-excel page" style={{background:'rgb(74, 205, 53)'}}>
                                                    <svg style={{fill:'#fff'}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 14V8l-6-6H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-4h-7v3l-5-4 5-4v3h7zM13 4l5 5h-5V4z"/></svg>
                                                   </span>
                                            
                                            </div>
                                            </div>

                             {<DynamicGridExample tableSearch={tableSearch} setEdit={setEdit} del={del} setDel={setDel}/>}

                       </div>

                  </div>

                  
            </div>
        </div>
    </main>
    </>
  );
}
