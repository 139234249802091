import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext';
import toast from 'react-hot-toast';
import CustomNoRowsOverlay from '../common/tableLoader'
import axios from 'axios';

export default function DataGridDemo({setEdit,setDel,del,tableSearch}) {
  const {token,user} = useAuth();
  const {data,setData,makeRequest} = useData();


    const [openMenu, setOpenMenu] = React.useState(null);
    const [loadingApproval,setLoadingApproval]=React.useState(false);

    const handleOutsideClick = (event) => {
        if (!event.target.closest('.menu') &&  !event.target.closest('.menu-option')) {
          setOpenMenu(null)
          document.removeEventListener('click', handleOutsideClick);
        }
    };


    const  handleClickMenu = (id) => {
         document.addEventListener('click', handleOutsideClick);
         setOpenMenu(id)
    };

    


    

    function updatePassword(id){

      if(loadingApproval)  return

      setTimeout(()=>{
         setOpenMenu(null)
         document.removeEventListener('click', handleOutsideClick);
      },10) 

      setLoadingApproval(true)

      toast.loading('A atualizar')


      axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/change-password/`,{id},{
        headers: {
             'Content-Type': 'application/json',
             Authorization: `Bearer ${token}`,
           },
       })
       .then(response => {
           toast.remove()
           toast.success('Senha atualizada')
           setLoadingApproval(false)

       })
       .catch(error => {
           setLoadingApproval(false)
           toast.remove()
           console.error('Error:', error);
           try{
                  toast.error('Ocorreu um erro:'+error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
           }catch(e){
                  toast.error('Error, try again!')
           }
          
       });
    }


    function handleLoanApproval(id,approval_status) {

      if(loadingApproval)  return

      setTimeout(()=>{
         setOpenMenu(null)
         document.removeEventListener('click', handleOutsideClick);
      },10) 

      setLoadingApproval(true)

      let approval_toast=toast.loading(`A ${approval_status=='active' ? 'activar ' :'desactivar'}...`)

      

       axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/change-status/`,{id,status:approval_status},{
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {

            setLoadingApproval(false)
            toast.remove()
            if(response.data.status=="inactive" && approval_status=='active'){
              toast.error('Microcredito inactiva!')
              return
            }
            toast.remove()
            toast.dismiss(approval_toast)
            toast.success(`Operador ${approval_status=='active' ? 'activado ' :'desactivado'}`)
            let operators=JSON.parse(JSON.stringify(data.operators))
            let index=data.operators.findIndex(i=>i.id==id)
            operators[index].status=approval_status
            data.operators=operators
            setData(data)
            setLoadingApproval(false)

        })
        .catch(error => {
            setLoadingApproval(false)
            toast.remove()
            toast.dismiss(approval_toast)
            console.error('Error:', error);
            try{
                   toast.error('Ocorreu um erro:'+error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
            }catch(e){
                   toast.error('Error, try again!')
            }
           
        });
                 
    }






    const columns = [
    {
      field: 'name',
      headerName: 'Nome',
      width: 170,
      editable: true,
    },
    {
      field: 'last_name',
      headerName: 'Apelido',
      width: 170,
      editable: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 170,
      editable: true,
    },
    {
      field: 'contact',
      headerName: 'Contacto',
      width: 170,
      editable: true,
    },{
      field: 'microcredit',
      headerName: 'Microcredito',
      width: 170,
      editable: true,
    },
    {
    field: 'status',
    headerName: 'Estado',
    width: 90,
    editable: false,
    renderCell: (params) => (
        <div style={{cursor:'pointer'}} onClick={() => handleEdit(params.row)}>
            <div style={{ backgroundColor:!params.row.status || params.row.status=="active"  ? '#4ACD35': params.row.status=='not_verified' ?  "#F9CE66"   : params.row.status=="inactive" ? "rgb(211 211 211)" : params.row.status=='rejected' ?  '#ed143d85' : '#F9CE66' ,  color: '#111' , padding:'0.3rem',borderRadius:'0.3rem'}}>
              <span style={{display:'flex',minWidth:'60px',justifyContent:'center'}}>{params.row.status =="active" ? "activo" : 'inactivo'}</span>
            </div>
      </div>
    )
  },
    {
      field: 'edit',
      headerName: '',
      width: 30,
      renderCell: (params) => (
        <div style={{cursor:'pointer'}} onClick={() => handleEdit(params.row)}>
                <svg style={{opacity:.6}}  xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg>
       </div>
      )
    },
    {
      field: 'more',
      headerName: '',
      width: 130,
      renderCell: (params) => (
        <div style={{cursor:'pointer', position:'absolute'}} onClick={()=>handleClickMenu(params.row.id)}  className="menu">
                      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z"/></svg>
                      <div className="menu-dropdown-row" style={{position:'absolute',display:openMenu==params.row.id ? 'block' :'none'}}>
                                   {params.row.status=="inactive" && <span className={`menu-option`} onClick={()=>handleLoanApproval(params.row.id,'active')}><svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>Activar</span>}
                                   {params.row.status=="active" && <span className={`menu-option`} onClick={()=>handleLoanApproval(params.row.id,'inactive')}><svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>Desactivar</span>}
                                   <span className={`menu-option`} onClick={() =>handleDelete(params.row)}> <svg style={{opacity:.6}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>Excluir</span>
                                   {user.role!="operator" && <span className={`menu-option`} onClick={() =>updatePassword(params.row.id)}>Actualizar senha</span>}
                      </div>
          </div>
      )
    }
  ];


    function search(array){

               function search_from_object(object,text){
                      text=tableSearch
                      let add=false
                      Object.keys(object).forEach(k=>{
                        if(typeof object[k]=="string" || typeof object[k]=="number"){
                           if(typeof object[k]=="number") object[k]=`${object[k]}`
                           if(object[k].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))){
                           add=true
                          }
                        }
                      })
                      return add
              }

            if (!array) return []
            let d=JSON.parse(JSON.stringify(array))
            let res=[]

            d.forEach((t,i)=>{
              if(search_from_object(t)) {
                  res.push(array.filter(j=>j.id==t.id)[0])
              }
           })

           return res

    }

  function handleEdit(row) {
      row.password=""
      setEdit(row)
  }
   function handleDelete(row) {
       console.log(row)
       setDel({id:row.operator_id,confirm:null})
  }


 
  const [rows,setRows]=React.useState(data.operators)
  const [loading,setLoading]=React.useState(false)

  React.useEffect(()=>{
     setRows(search(data.operators))
     console.log(tableSearch)
  },[data,data.operators,tableSearch])


  React.useEffect(()=>{
    async function fetchData() {
             try {
                 let [operatorsRes, microcreditsRes] = await Promise.all([
                     makeRequest({ url: 'operators', error: '' }),
                     makeRequest({ url: 'microcredits', error: '' })
                 ]);
                 data.operators = operatorsRes;
                 data.microcredits = microcreditsRes;
                 setData(prev=>({...prev, loaded:{...data.loaded,microcredits:true,operators:true},operators:operatorsRes, microcredits: microcreditsRes}))
             } catch (e) {
                 console.error('Error fetching data:', e);
             }
         }

   fetchData();
 },[])


  React.useEffect(()=>{
    if(del?.confirm){

      axios.delete(`${process.env.REACT_APP_BASE_URL}/api/operator/delete/`+del.id ,{
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {
            data.operators=data.operators.filter(i=>i.operator_id!=del.id)
            setData(data)
            setDel(null)
            toast.success('Operador eliminado com sucesso')
        })
        .catch(error => {
            setLoading(false)
            console.error('Error fetching user data:', error);

            try{
                   toast.error(error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
            }catch(e){
                   toast.error('Error, try again!')
            }

            setDel(null)
        });

      
    }
  },[del])




  
  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 6,
            },
          },
        }}
        pageSizeOptions={[6]} // Corrected
        //checkboxSelection
        disableColumnMenu
        localeText={{ noRowsLabel: <CustomNoRowsOverlay loading={!data.loaded.operators ? true : false}/>,  pagination: {labelRowsPerPage: 'Mostrar por página'} }}
        //onSelectionModelChange={handleSelectionModelChange}
      />
    </Box>
  );
}
