import '../css/main.css'
import './style.css'
import Menu from '../common/menu.js';
import TopBar from "../common/topBar";
import Create from './create.js'
import DynamicGridExample from './table.js'
import DeleteDialog from './delete-dialog.js'
import { useState , useEffect} from 'react';
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext';

import * as XLSX from 'xlsx';

export default function Microcredits() {

const {token,user} = useAuth();
const {data,setData} = useData();

const [create, setCreate] = useState(false);
const [edit, setEdit] = useState(null);
const [del, setDel] = useState(null);

const [_in, setIn] = useState(0);
const [_out, setOut] = useState(0);
const [tableSearch,setTableSeach]=useState('');
const [_a, setAvailable] = useState(0);
const [_accounts, setAccounts] = useState([])
let initial_filters={start_date:'',end_date:'',category_id:'',account_id:'',type:''}
const [filters,setFilters]=useState(initial_filters);
const transation_types=[{id:'out',label:'Saida'},{id:'in',label:'Entrada'}]
const [filterRes,setFilterRes]=useState({in:0,out:0,available:0});
const [filteredContent,setFilteredContent]=useState([]);



function time(){
  const currentDate = new Date();
  const currentHour = currentDate.getHours();
  const currentMinute = currentDate.getMinutes();
  const formattedTime = `${currentHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`;
  return formattedTime
}

function today(get_this_day) {
        const currentDate = get_this_day ? get_this_day : new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate
}



 const exportToExcel = () => {

    let _data=filteredContent 

    const mappedData = _data.map(item => ({
       'ID':item.id,
       'Tipo':item.type=="in" ? 'Entrada' :'Saida',
       'Valor':(item.type=="out" && item.amount!=0 ?'-' :'')+""+item.amount,
       'Categoria':item.category,
       'Conta':(data.settings.accounts.some(i=>i.id==item.account_id) ? item.account + `(${data.settings.accounts.filter(i=>i.id==item.account_id)[0].name})` : item.account),
       'Descrição':item.description,
       'Referência':item.reference,
       'Criado por':item.by,
       'Data de criação':item.createdAt.split('T')[0],
    }));

    const workbook = XLSX.utils.book_new();
    const sheetData = XLSX.utils.json_to_sheet(mappedData);
    XLSX.utils.book_append_sheet(workbook, sheetData, 'Sheet1');
    XLSX.writeFile(workbook, user.microcredit.name +` - Transações de caixa ${today()} ${time()}.xlsx`);
 };




useEffect(()=>{
  let _in=filteredContent.filter(i=>i.type=="in" && (i.role!="pledged" && i.role!="loss")).map(item => item.amount).reduce((acc, curr) => acc + curr, 0);
  let _out=filteredContent.filter(i=>i.type=="out" && ((i.role!="pledged" && i.role!="loss"))).map(item => item.amount).reduce((acc, curr) => acc + curr, 0);
  setFilterRes({...filterRes,
  in:new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(_in),
  out:new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(_out),
  //available:new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((_in - _out <= 0) ? 0 : _in - _out),
  available:new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(_in - _out),
})
},[filteredContent])



useEffect(()=>{
  if(data.transations.length && !filters.end_date && !filters.start_date){
     setFilters({...filters,
     start_date:data.transations[data.transations.length - 1].createdAt.split('T')[0],
     end_date:data.transations[0].createdAt.split('T')[0],
     
   })
  }  
},[data.transations,filters]) 


useEffect(()=>{
     let _in=0
    data.transations.forEach(i=>{if(i.type=="in" && (i.role!="pledged" && i.role!="loss")) _in+=i.amount})

    let _out=0
    data.transations.forEach(i=>{if(i.type=="out" && ((i.role!="pledged" && i.role!="loss"))) _out+=i.amount})

    let _a=0

    setOut(_out)
    setIn(_in)

    let accounts=[]

    if(data.settings.accounts){
         data.settings.accounts.forEach(i=>{
            let __in=0
            let __out=0
            let times=0
               data.transations.forEach(j=>{ if(j.account_id==i.id && j.type=="in" && (j.role!="pledged" && j.role!="loss")){
               __in+=j.amount
               times+=1
     
               }})
               data.transations.forEach(j=>{ if(j.account_id==i.id && (j.type=="out" && (i.role!="pledged" && i.role!="loss"))) {
               __out+=j.amount
               times+=1
     
               }})
             let a=(__in - __out)
            //_a+=a
            accounts.push({id:i.id,name:i.name,in:__in,out:__out,available:a,times})
       })
    }

     for (var i = 0; i < 10 ; i++) {
            if(!accounts[i]) accounts.push({name:'',times:0, not_usable:true}) 
     }

     accounts.forEach(f=>{
        /*new */ 
       if(f.available){
         _a+=f.available
        } 
     })


    setAvailable(_a)
    setAccounts(accounts)

},[data.transations, data.settings, data, data.loaded])





return (
    <>
    <main className="dashboard-container">

          { <Create showD={(create || edit) ? true : false}  create={create} setCreate={setCreate} setEdit={setEdit} edit={edit} del={del}/>}
         {<DeleteDialog del={del} showD={(del) ? true : false} setDel={setDel}/>}

      

        <div className="dashboard">
            <Menu/>

            <div className="main-dashboard">
               
                  <TopBar activePage={'Caixa'}/>

                  <div className="center">


                        <div className="stat_page_2">
                             <div className="items">

                                  <div className="box">
                                    <div className="icon blue-icon">
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="27"><path d="M444-200h70v-50q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q32 0 50 15.5t26 38.5l64-26q-11-35-40.5-61T516-710v-50h-70v50q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-33 0-58.5-20.5T390-396l-66 26q14 48 43.5 77.5T444-252v52Zm36 120q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg>
                                    </div>
                                    <div className="center-content">
                                       <div className="name">Entradas</div>
                                       <div className="total">{(!data.loaded.settings || !data.loaded.transations)  ? "-" : new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(_in) +' MT'}</div>
                                    </div>
                                 </div>


                                   <div className="box">
                                    <div className="icon orange-icon">
                                       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="27"><path d="M444-200h70v-50q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q32 0 50 15.5t26 38.5l64-26q-11-35-40.5-61T516-710v-50h-70v50q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-33 0-58.5-20.5T390-396l-66 26q14 48 43.5 77.5T444-252v52Zm36 120q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg>
                                    </div>
                                    <div className="center-content">
                                       <div className="name">Saidas</div>
                                       <div className="total">{parseFloat(_out) != 0 ? '-':''}{(!data.loaded.settings || !data.loaded.transations)  ? "-" : new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(_out) +' MT'}</div>
                                    </div>
                                    
                                 </div>


                                   <div className="box">
                                    <div className="icon green-icon">
                                       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="27"><path d="M444-200h70v-50q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q32 0 50 15.5t26 38.5l64-26q-11-35-40.5-61T516-710v-50h-70v50q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-33 0-58.5-20.5T390-396l-66 26q14 48 43.5 77.5T444-252v52Zm36 120q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg>
                                    </div>
                                    <div className="center-content">
                                       <div className="name">Disponivel</div>
                                      { /*<div className="total">{(_in - _out) > 0 ? new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(_in - _out) +' MT' : 0 }</div>*/}
                                    {   /*<div className="total">{(!data.loaded.settings || !data.loaded.transations)  ? "-" : (_a) > 0 ? new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(_a) +' MT' : 0 }</div>
                                    */}
                                       <div className="total">{(!data.loaded.settings || !data.loaded.transations)  ? "-" :  new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(_a) +' MT' }</div>
                                    
                                    </div>
                                   
                                 </div>

                             </div>
                       </div>



                       <div className="center_search">

                           <div className="search_container">
                                 <div className="search-div">
                                    <input type="text" placeholder="Pesquisar..." value={tableSearch} onChange={(e)=>setTableSeach(e.target.value)}/>
                                       <div className="search-btn">
                                       <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"/></svg>
                                    </div>
                                 </div>
                           </div>


                           <div className="btn-add-item" onClick={()=>setCreate(true)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#fff" d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"></path></svg>
                                    <span>Adicionar</span>
                           </div>
                       </div>


                            <div className="extra-filters">

                               <div className="input-container">

                                         <div className="inputs">
                                            <span>Data de inicio</span>
                                            <input type="date" value={filters.start_date} onChange={(e)=>setFilters({...filters,start_date:e.target.value})} />
                                        </div>

                                         <div className="inputs">
                                            <span>Data de fim</span>
                                            <input type="date" value={filters.end_date} onChange={(e)=>setFilters({...filters,end_date:e.target.value})} />
                                        </div>


                                       <div className="inputs">
                                           <span>Tipo de transação</span>
                                           <select  value={filters.type} onChange={e=>setFilters({...filters, type:e.target.value})}>
                                                <option selected value="">Selecionar Transação</option>}
                                                {transation_types.map(i=>(
                                                      <option key={i.id} selected={filters.type === i.id} value={i.id}>{i.label}</option>
                                                ))}
                                           </select>
                                        </div>

                                        <div className="inputs">
                                           <span>Categoria</span>
                                           <select value={filters.category_id} onChange={e=>setFilters({...filters, category_id:e.target.value})}>
                                                <option  selected value="">Selecionar Categoria</option>
                                                {data.settings.categories.map(i=>(
                                                      <option key={i.id} selected={filters.category_id === i.id} value={i.id}>{i.name}</option>
                                                 ))}
                                           </select>
                                        </div>
                                        <div className="inputs">
                                           <span>Conta</span>
                                           <select value={filters.account_id} onChange={e=>setFilters({...filters, account_id:e.target.value})}>
                                                      <option selected value="">Selecionar Conta</option>
                                                      {data.settings.accounts.map(i=>(
                                                            <option key={i.id} selected={filters.account_id === i.id} value={i.id}>{i.name} {i.description ? `(${i.description})`:''}</option>
                                                      ))}
                                           </select>
                                        </div>
                                       
                                        
                                </div>

                                <div className="options">
                                   <span onClick={()=>setFilters(initial_filters)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#fff" d="M440-122q-121-15-200.5-105.5T160-440q0-66 26-126.5T260-672l57 57q-38 34-57.5 79T240-440q0 88 56 155.5T440-202v80Zm80 0v-80q87-16 143.5-83T720-440q0-100-70-170t-170-70h-3l44 44-56 56-140-140 140-140 56 56-44 44h3q134 0 227 93t93 227q0 121-79.5 211.5T520-122Z"/></svg> </span>
                                </div>




                            </div>


                       <div className="dasboard-item-list-container">


                            <div className="top-section" style={{display:'flex'}}>
                                 <div className="left-side">
                                          <div className="show" style={{display:'none'}}>
                                             <span>Mostrar</span>
                                             <select>
                                                <option>10</option>
                                                <option>20</option>
                                                <option>30</option>
                                             </select>
                                          </div>
                                          {(data.loaded.settings && data.loaded.transations) && (<> <div className="show">
                                            <span>Resultados:</span>
                                            <label>{filteredContent.length}</label>
                                          </div>

                                          <div className="show">
                                            <span>Entradas:</span>
                                            <label>{filterRes.in}</label>
                                          </div>

                                          <div className="show">
                                            <span>Saidas:</span>
                                            <label>{parseFloat(filterRes.out) != 0 ? '-':''}{filterRes.out}</label>
                                          </div>

                                          <div className="show">
                                            <span>Disponivel:</span>
                                            <label>{filterRes.available}</label>
                                          </div></>)}
                                          
                                    
                                 </div>
                                 <div className="right-side">
                                         <span onClick={()=>exportToExcel()} className="export export-excel page" style={{background:'rgb(74, 205, 53)'}}>
                                         <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24"><path fill="#fff" d="M18 22a2 2 0 0 0 2-2v-5l-5 4v-3H8v-2h7v-3l5 4V8l-6-6H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12zM13 4l5 5h-5V4z"></path></svg></span>
                                         <span className="page">4</span>
                                 </div>
                            </div>


                              {<DynamicGridExample filters={filters} setFilteredContent={setFilteredContent} tableSearch={tableSearch} setEdit={setEdit} del={del} setDel={setDel}/>}


                       </div>

                  </div>

                  
            </div>
        </div>
    </main>
    </>
  );
}
