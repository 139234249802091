import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useData } from '../../../contexts/DataContext';
import { useAuth } from '../../../contexts/AuthContext';
import toast from 'react-hot-toast';
import axios from 'axios';
import CustomNoRowsOverlay from '../../common/tableLoader'


export default function DataGridDemo({setEdit,setDel,del,tableSearch}) {

    const [openMenu, setOpenMenu] = React.useState(null);
    const [loadingApproval,setLoadingApproval]=React.useState(false);

    const handleOutsideClick = (event) => {
        if (!event.target.closest('.menu') &&  !event.target.closest('.menu-option')) {
          setOpenMenu(null)
          document.removeEventListener('click', handleOutsideClick);
        }
    };


    const  handleClickMenu = (id) => {
         document.addEventListener('click', handleOutsideClick);
         setOpenMenu(id)
    };



    function handleLoanApproval(id,approval_status) {

       if(loadingApproval)  return

      setTimeout(()=>{
         setOpenMenu(null)
         document.removeEventListener('click', handleOutsideClick);
      },10)


      setLoadingApproval(true)

      let approval_toast=toast.loading(`A ${approval_status==true ? 'adicionar' :'remover'}...`)

       axios.post(`${process.env.REACT_APP_BASE_URL}/api/client/blacklist/status`,{id,status:approval_status},{
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {
            toast.remove()
            toast.dismiss(approval_toast)
            toast.success(`Cliente ${approval_status==true ? ' adionado' :'removido'}`)
            let my_blacklist=JSON.parse(JSON.stringify(data.my_blacklist))
            let blacklist=JSON.parse(JSON.stringify(data.blacklist))
            let index=data.my_blacklist.findIndex(i=>i.client_id==id)
            my_blacklist[index].on_blacklist=approval_status

            if(!blacklist.filter(i=>i.client_id==id).length && approval_status==true){
                 blacklist.push(data.my_blacklist[index])
            }
            if(approval_status==false){
                blacklist=blacklist.filter(i=>i.client_id!=id)
            }
            data.blacklist=blacklist
            data.my_blacklist=my_blacklist
            setData(data)
            setLoadingApproval(false)

        })
        .catch(error => {
            setLoadingApproval(false)
            toast.remove()
            toast.dismiss(approval_toast)
            console.error('Error:', error);
            try{
                   toast.error('Ocorreu um erro:'+error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
            }catch(e){
                   toast.error('Error, try again!')
            }
        });   
    }






    const columns = [
    {
      field: 'name',
      headerName: 'Nome completo',
      width: 180,
      editable: true,
      renderCell: (params) => (
         <span>{params.row.name + " "+ params.row.last_name}</span>
      )
    },
    {
      field: 'delayed_contracts',
      headerName: 'Contratos atrasados',
      width: 160,
      editable: true,
      valueGetter: (params) => params.row.delayed_contracts  || "-"
    },
    {
      field: 'delayed_payments',
      headerName: 'Pagamentos atrasados',
      width: 170,
      editable: true,
      renderCell:(params)=>(
         <span>{params.row.delayed_payments}</span>
      )
    },
    {
      field: 'delayed_days',
      headerName: 'Dias de atraso',
      width: 130,
      editable: true,
      renderCell:(params)=>(
         <span>{params.row.delayed_days}</span>
      )
    },
      {
      field: 'status',
      headerName: 'Estado',
      width: 100,
      editable: false,
      renderCell: (params) => (
          <div style={{cursor:'pointer'}}>
              <div style={{ backgroundColor:params.row.on_blacklist==true  ? "#4ACD35" : '#F9CE66' ,  color: '#111' , padding:'0.3rem',borderRadius:'0.3rem'}}>
                <span style={{display:'flex',minWidth:'75px',justifyContent:'center'}}>{params.row.on_blacklist==true ? "adicionado" : 'pendente'}</span>
              </div>
        </div>
      )
    },

    {
      field: 'more',
      headerName: '',
      width: 130,
      renderCell: (params) => (
        <div style={{cursor:'pointer', position:'absolute'}} onClick={()=>handleClickMenu(params.row.id)}  className="menu">
                      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z"/></svg>
                      <div className="menu-dropdown-row" style={{position:'absolute',display:openMenu==params.row.id ? 'block' :'none'}}>
                                   {params.row.on_blacklist!=true && <span className={`menu-option`} onClick={()=>handleLoanApproval(params.row.client_id,true)}><svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>Adicionar</span>}
                                   {params.row.on_blacklist==true && <span className={`menu-option`} onClick={()=>handleLoanApproval(params.row.client_id,false)}><svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>Remover</span>}
                       </div>
          </div>
      )
    }
 
    /*{
      field: 'actions',
      headerName: 'Acções',
      width: 100,
      renderCell: (params) => (
        <div className={`table_actions ${loadingApproval==false ? 'show' :'hide'}`}>
             {params.row.on_blacklist!=true && <span onClick={()=>handleLoanApproval(params.row.client_id,true)} style={{cursor:'pointer', backgroundColor:'rgb(74, 205, 53)'}}><svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960"><path fill="#fff" d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"></path></svg></span>}
             {params.row.on_blacklist==true && <span onClick={()=>handleLoanApproval(params.row.client_id,false)} style={{cursor:'pointer',backgroundColor:'#ed143ddb'}}> <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960"><path fill="#fff" d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg></span>}
        </div>
      )
    }*/
  ];

    function search(array){

               function search_from_object(object,text){
                      text=tableSearch
                      let add=false
                      Object.keys(object).forEach(k=>{
                        if(typeof object[k]=="string" || typeof object[k]=="number"){
                           if(typeof object[k]=="number") object[k]=`${object[k]}`
                           if(object[k].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))){
                             add=true
                          }
                        }
                      })
                      return add
              }

            if (!array) return []
            let d=JSON.parse(JSON.stringify(array))
            let res=[]

            d.forEach((t,i)=>{
              if(search_from_object(t)) {
                  res.push(array.filter(j=>j.id==t.id)[0])
              }
            })


           return res

  }

  


  const {token,user} = useAuth();
  const {data,setData,makeRequest} = useData();

  const [rows,setRows]=React.useState([])
  const [loading,setLoading]=React.useState(false)

  React.useEffect(()=>{
    setRows(search(data.my_blacklist))
  },[data,tableSearch,data.my_blacklist])

  

  React.useEffect(()=>{

      (async () => {
                   try{
                         let res = await makeRequest({ url: 'clients/my-blacklist', error: `` });
                         setRows(res)
                         data.my_blacklist=res
                         data.loaded.my_blacklist=true
                         setData(data)
                   }catch(e){
                         console.log(e)
                   }
      })();

   },[])



  React.useEffect(()=>{
    if(del?.confirm){

      axios.delete(`${process.env.REACT_APP_BASE_URL}/api/client/delete/`+del.id ,{
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {
            data.clients=data.clients
            setData(data)
            setDel(null)
        })
        .catch(error => {
            setLoading(false)
            console.error('Error fetching user data:', error);

            try{
                   toast.error(error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
            }catch(e){
                   toast.error('Error, try again!')
            }

            setDel(null)
        });

      
    }
  },[del])


  
  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 6,
            },
          },
        }}
        pageSizeOptions={[6]} // Corrected
        //checkboxSelection
        disableColumnMenu
        //onSelectionModelChange={handleSelectionModelChange}
        localeText={{ noRowsLabel: <CustomNoRowsOverlay loading={!data.loaded.my_blacklist ? true : false}/>,  pagination: {labelRowsPerPage: 'Mostrar por página'} }}
      />
    </Box>
  );
}
