import { useState, Fragment } from 'react'

import '../css/main.css'

function Component() {

  let [items,setItems]=useState([
     {q:'O que é VLMS',a:'VLMS (Visum Loan Management System) é um sistema integrado de gestão de microcrédito que otimiza a gestão de clientes, contratos, transações de caixa, pagamentos atrasados, relatórios, operadores e mais de forma eficiente, segura e acessível.'},
     {q:'Qual plano é o ideal para minha empresa?',a:`O plano ideal para sua empresa depende das suas necessidades específicas e do estágio atual do seu negócio. Aqui estão algumas diretrizes gerais para ajudá-lo a escolher.\nPlano Grátis: Se você está começando e deseja explorar nossa plataforma sem compromisso financeiro, o plano grátis oferece funcionalidades básicas para iniciar.\nPlano Standard: Se sua empresa está em crescimento e precisa de mais recursos, como mais operadores e gestores, o plano Standard é uma ótima opção para expandir suas operações.\nPlano Premium: Se você precisa de funcionalidades avançadas, como notificações por email e SMS, personalizações adicionais e um número ilimitado de operadores, o plano Premium oferece uma solução completa para suas necessidades de gestão.`},
     {q:'Como posso começar a usar o sistema?',a:'Para começar a usar o sistema, terá que preencher os dados de pedido de cadastro da sua empresa, escolher um plano e esperar pela confirmação de email onde irá receber as instruções dos proximos passos.'}
  ])

  let [open,setOpen]=useState(null)

  function openCLose(i){
     if(open != i) {
        setOpen(i)
     }else{
        setOpen(null)
     }
  }

  return (
     <div className="faq" id="faq">
                 <div className="section-title">
                     <h3>FAQ</h3>
                     <span>Perguntas frequentes</span>

                 </div>


                 
                 <div className="q-container">
                       {items.map(i=>(
                           <div onClick={()=>openCLose(i)} className={`item ${open==i ?'active' :''}`} >
                            <div className="top">
                                 <span className="title">{i.q}</span>
                                  <span>
                                  <svg  xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m480-340 180-180-57-56-123 123-123-123-57 56 180 180Zm0 260q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                                 </span>
                                
                            </div>
                            <div className="content">

                            {i.a.split('\n').map((line, index) => (
                                             <Fragment key={index}>
                                             {line}
                                             <br />
                                            </Fragment>
                             ))}
                              </div>
                       </div>
                       ))}
                 </div>
            </div>
  )
}

export default Component