import { useEffect, useState} from 'react';
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';
import toast from 'react-hot-toast';

const Create = ({create,setCreate, edit , setEdit, del,showD }) => {
const {data, setData} = useData();
const {token, user} = useAuth();

let initial_form={id:'',name:'',email:'',last_name:'',contact:'',password:'',microcredit_id:data.microcredits.length ? data.microcredits[0].id : ''}
const [formData,setFormData]=useState(initial_form)
const [loading,setLoading]=useState(false)


function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email.trim());
}


useEffect(()=>{
    if(edit){
       setFormData(edit)
       setCreate(false)
    }
},[edit])

useEffect(()=>{
    if(create){
       setEdit(false)
       setFormData(initial_form)
    } 
},[create])


function handle_manager(){


    if(user.role!="admin") {
            formData.microcredit_id=user.microcredit.id
            formData.microcredit=user.microcredit.name
      }else{
            formData.microcredit=data.microcredits.filter(m=>m.id==formData.microcredit_id)[0].name
      }
    if(edit){
      if(!formData.name || !formData.email || !formData.last_name || !formData.contact || !formData.operator_id /*|| (!formData.password && formData.change_password)*/) {
         toast.error('Please, fill up the fields!')
         return 
      }

      if(!isValidEmail(formData.email)) {
           toast.error('Invalid email!')
          return
      }
       setLoading(true) 
     

       console.log(formData)

       axios.post(`${process.env.REACT_APP_BASE_URL}/api/operator/update`,formData, {
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        })

        .then(response => {
             let id=response.data.id
             let operators=JSON.parse(JSON.stringify(data.operators))
             let index=data.operators.findIndex(i=>i.id==id)
             operators[index]=formData
             data.operators=operators
             toast.remove()
             toast.success('Operator actualizado com sucesso!')
             setData(data)
             setFormData({id:'',name:'',email:'',last_name:'',contact:''})
             setEdit(null)
             setLoading(false)  
        })
        .catch(error => {
            setLoading(false)
            console.error('Error fetching user data:', error);

            try{
                   toast.error(error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
            }catch(e){
                  toast.error('Error, try again!')
            }
        });
    }else{

      formData.created_by_id=user.id

      if(!formData.name || !formData.email || !formData.last_name || !formData.contact || !formData.microcredit_id) {
         toast.error('Please, fill up the fields!')
         console.log(formData)
         return 
      }

      if(!isValidEmail(formData.email)) {
           toast.error('Invalid email!')
          return
       }
       

      setLoading(true) 
         axios.post(`${process.env.REACT_APP_BASE_URL}/api/operator/create`,formData, {
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {
             formData.id=response.data.user_id
             formData.operator_id=response.data.id
             formData.status="active"
             data.operators=[...data.operators,formData]
             setData(data)
             setCreate(false)
             setLoading(false)  
             toast.remove()
             toast.success('Operador criado com sucesso!') 
        })
        .catch(error => {
            setLoading(false)
            console.error('Error fetching user data:', error);
            try{
                   toast.error(error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
            }catch(e){
                   toast.error('Error, try again!')
            }
            
        });
    }

    
}



return (
<div className={`dialog-container-bg ${showD ? 'show' :''}`}>
<div className="bg-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}></div>
<div  className={`create-microcredit ${loading && 'loading'}`}>
                   <div className="top-title">{edit ? 'Editar' :' Adicionar'} operador</div>  
                  <div className="btn-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}>
                         <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                  </div>

                  <div className="input-container">
                     <div className="inputs">
                           <span>Nome</span>
                           <input type="text" value={formData.name} onChange={e=>setFormData({...formData,name:e.target.value})} placeholder="Nome do operador"/>
                     </div>
                     <div className="inputs">
                            <span>Apelido</span>
                           <input type="text" value={formData.last_name} onChange={e=>setFormData({...formData,last_name:e.target.value})} placeholder="Apelido do operador"/>
                     </div>

                     <div className="inputs">
                           <span>Email</span>
                           <input type="text" value={formData.email} onChange={e=>setFormData({...formData,email:e.target.value})}  placeholder="Email do operador"/>
                     </div>

                     <div className="inputs">
                            <span>Contacto</span>
                            <input type="number" value={formData.contact} onChange={e=>setFormData({...formData,contact:e.target.value})}  placeholder="Contacto do operador"/>
                     </div>

                    {/* <div className="inputs">
                          <div className="label"><span>Senha</span>{edit && <label>Trocar senha <input checked={formData.change_password} onChange={(e)=>setFormData({...formData,change_password:e.target.checked})} type="checkbox"/></label>}</div>
                          <input  disabled={edit && formData.change_password ? false : !edit ? false : true} value={formData.password} onChange={e=>setFormData({...formData,password:e.target.value})} type="password" placeholder="Senha do gestor"/>
                  </div>*/}

                    {user.role=="admin" && <div className="inputs">
                                                <span>Microcredito</span>
                                                <select value={formData.microcredit_id} onChange={e=>setFormData({...formData, microcredit_id:e.target.value})}>
                                                     {!data.microcredits.length && <option disabled selected value="">Selecione uma microcredito</option>} 
                                                     {data.microcredits.map(i=>(
                                                           <option key={i.id} selected={formData.microcredit_id == i.id} value={i.id}>{i.name}</option>
                                                     ))}
                                                </select>
                                         </div>}
                  
                  </div>


              <div className="options">
                  <button className="cancel" onClick={()=>(edit ? setEdit(false) : setCreate(false) )}>Cancelar</button>
                  <div className="div_btn">
                     <button className="save" onClick={handle_manager}><span>{edit? 'Actualizar' : 'Adicionar'}</span></button>
                     <span className="loader"></span>
                  </div>
              </div>

        </div>
 </div>


)
};

export default Create;
