import Admin from './admin.js';
import Operator from './operator.js';
import Manager from './manager.js';
import Client from './client.js';
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext';

export default function Dashboard() {
   const {data, setData, updateData} = useData();
   const {token, user} = useAuth();

   if(!token){

      

   }else if(user.role=="admin"){

      return <Admin/>

   }else if(user.role=="manager"){

      return <Manager/>

   }else if(user.role=="operator"){
    
      return <Operator/>

   }else{
      return <Client/>
   }
  
}
