
import { useEffect, useState} from 'react';
import { useData } from '../../../contexts/DataContext';
import { useAuth } from '../../../contexts/AuthContext';
import axios from 'axios';
import toast from 'react-hot-toast';

const Create = ({create,setCreate, edit , setEdit, del, showD }) => {

const account_types=[{id:'bank-account',label:'Bancária'},{id:'mobile-account',label:'Movél'},{id:'others',label:'Outro'}]

let initial_form={id:'',name:'',type:'',description:''}

const [formData,setFormData]=useState(initial_form)

const [loading,setLoading]=useState(false)

const {data, setData} = useData();
const {token, user} = useAuth();


useEffect(()=>{
    if(edit){
       setFormData(edit)
       setCreate(false)
    }
},[edit])

useEffect(()=>{
    if(create){
       setFormData(initial_form) 
       setEdit(false)
    }
},[create])

useEffect(()=>{
    if(formData.percentage < 0 ){
       setFormData({...formData,percentage:0})
    }else if(formData.percentage > 100){
       setFormData({...formData,percentage:100})
    }
},[formData])



function handle_manager(){

    if(edit){
      if(data.settings.accounts?.some(i=>i.description==formData.description && i.id!=formData.id)){
           toast.error('Conta já existe')
           return

      }else if(!formData.description || !formData.name || !formData.type) {
           toast.error('Preencha todos os campos')
           return
      }


      setLoading(true) 

       axios.post(`${process.env.REACT_APP_BASE_URL}/api/cash-management/settings/accounts/update`,formData, {
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {
             let id=response.data.id
             let accounts=JSON.parse(JSON.stringify(data.settings.accounts))
             let index=data.settings.accounts.findIndex(i=>i.id==id)
             accounts[index]=formData
             data.settings.accounts=accounts
             toast.success('Conta actualizada!')
             setData(data)
             setFormData({id:'',name:'',type:'',description:''})
             setEdit(null)
             setLoading(false)  
        })
        .catch(error => {
            setLoading(false)
            console.error('Error:', error);

            try{
                   toast.error(error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
            }catch(e){
                   toast.error('Error, try again!')
            }
            
        });

    }else{if(data.settings.accounts?.some(i=>i.description==formData.description)){
           toast.error('Conta já existe')
           return

      }else if(!formData.description || !formData.name || !formData.type) {
           toast.error('Preencha todos os campos')
           return
      }
     
      setLoading(true) 
         axios.post(`${process.env.REACT_APP_BASE_URL}/api/cash-management/settings/accounts/create`,formData, {
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {
             console.log(response)
             toast.success('Conta adicionada!')
             formData.id=response.data.id
             data.settings.accounts=[...data.settings.accounts,formData]
             setData(data)
             setCreate(false)
             setLoading(false)  
        })
        .catch(error => {
            setLoading(false)
            console.error('Error fetching user data:', error);
            try{
                   toast.error(error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
            }catch(e){
                   toast.error('Error, try again!')
            }
            
        });
    }

    
}
   
return (
  <div className={`dialog-container-bg ${showD ? 'show' :''}`}>
  <div className="bg-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}></div>
<div  className={`create-microcredit ${loading && 'loading'}`}>
                  <div className="top-title">{edit ? 'Editar' :' Adicionar'} conta</div>  
                  <div className="btn-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}>
                         <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                  </div>

                  <div className="input-container">
                     <div className="inputs">
                           <span>Nome</span>
                           <input value={formData.name} onChange={e=>setFormData({...formData,name:e.target.value})} type="text" placeholder="Nome da conta"/>
                     </div>
                      <div className="inputs">
                           <span>Descrição</span>
                           <input value={formData.description} max={100} onChange={e=>setFormData({...formData,description:e.target.value})} type="number" placeholder="Número da conta"/>
                     </div>
                     <div className="inputs">
                             <span>Tipo</span>
                             <select value={formData.type} onChange={e=>setFormData({...formData,type:e.target.value})}>
                                  <option disabled selected value="">Selecione categoria</option>
                                  {account_types.map(i=>(
                                        <option key={i.id} selected={formData.type == i.id} value={i.id}>{i.label}</option>
                                  ))}
                             </select>
                     </div>
                  </div>


              <div className="options">
                  <button className="cancel" onClick={()=>(edit ? setEdit(false) : setCreate(false) )}>Cancelar</button>
                  <div className="div_btn">
                     <button className="save" onClick={handle_manager}><span>{edit? 'Actualizar' : 'Adicionar'}</span></button>
                     <span className="loader"></span>
                  </div>
              </div>

        </div>
         </div>


)
};

export default Create;
