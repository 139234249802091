

import { useEffect, useState} from 'react';
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';
import toast from 'react-hot-toast';
import MultiSelect from './multi-select-input.js'
import { useNavigate } from 'react-router-dom';

const Create = ({create,setCreate, edit , setEdit,showD}) => {

const navigate = useNavigate()
const transation_types=[{id:'out',label:'Saida'},{id:'in',label:'Entrada'},{id:'transfer',label:'Transferência'}]

const [transationTypes,setTransationTypes]=useState(transation_types)

const {data, setData} = useData();
const {token, user} = useAuth();
let initial_form={type:'',reference:'',amount:'',category:'',description:'',account:'',account_id:'',category_id:'', in_account:'',out_account:'',in_account_id:'',out_account_id:'',account_in_role:'',account_out_role:''}
const [formData,setFormData]=useState(initial_form)
const [message,setMessage]=useState(null)
const [loading,setLoading]=useState(false)


useEffect(()=>{
    if(edit){
       setFormData(edit)
       setCreate(false)
       setTransationTypes(transation_types.filter(i=>i.id!="transfer"))
    }else{
      setTransationTypes(transation_types)
    }
},[edit])

useEffect(()=>{
    if(create) {
        setFormData(initial_form)
        setEdit(false)
        
    }
},[create])

const [_in, setIn] = useState(0);
const [_out, setOut] = useState(0);
const [_avaliable, setAvaliable] = useState(0);
const [_accounts, setAccounts] = useState([])
const [_avaliable_a, setAvaliableA] = useState(0);

useEffect(()=>{

 /*let _in=0
 data.transations.forEach(i=>{if(i.type=="in") _in+=i.amount})

 let _out=0
 data.transations.forEach(i=>{if(i.type=="out") _out+=i.amount})

 setOut(_out)
 setIn(_in)
 let av=_in - _out <=0   ? 0 : (_in - _out)
 setAvaliable(av)*/

    let _in=0
    data.transations.forEach(i=>{if(i.type=="in") _in+=i.amount})

    let _out=0
    data.transations.forEach(i=>{if(i.type=="out") _out+=i.amount})

    let _a=0

    setOut(_out)
    setIn(_in)

    let accounts=[]

    if(data.settings.accounts){
         data.settings.accounts.forEach(i=>{
            let __in=0
            let __out=0
            let times=0
            data.transations.forEach(j=>{ if(j.account_id==i.id && j.type=="in"){
             __in+=j.amount
             times+=1

            }})
            data.transations.forEach(j=>{ if(j.account_id==i.id && j.type=="out") {
              __out+=j.amount
              times+=1

            }})
            let a=(__in - __out) <= 0 ? 0 : (__in - __out)
            _a+=a
            accounts.push({id:i.id,name:i.name,in:__in,out:__out,available:a,times})
       })
    }

     for (var i = 0; i < 10 ; i++) {
            if(!accounts[i]) accounts.push({name:'',times:0, not_usable:true}) 
     }


      setAvaliable(_a)

      setAccounts(accounts)



},[data.transations, data])

useEffect(()=>{

   
    if(!formData.account_id && !formData.out_account_id){
       setMessage(null)
       return
    }

    let _av

    if(formData.type=="transfer"){

          _av=!formData.out_account_id ? null : _accounts.filter(i=>i.id==formData.out_account_id)[0].available
    }else if(formData.type=="out" || formData.type=="in"){
         _av=!formData.account_id ? null :  _accounts.filter(i=>i.id==formData.account_id)[0].available 
    }

    setAvaliableA(_av)


    if(formData.contract_id){
        setMessage('Não pode editar transação gerada de um contrato')
    }else if((formData.type=="out" || formData.type=="transfer")  && (_av==0 && !edit) && _av!=null){
        setMessage('Sem saldo disponivel para transação de saida!')
    }else if(_av < formData.amount && (formData.type=="out" || formData.type=="transfer") && !edit && _av!=null){
        setMessage('Apenas ' +_av.toLocaleString()+ ' disponivel!')
    }else{
        setMessage(null)
    }

},[formData])


useEffect(()=>{

   

setFormData({...formData,
    account_in_role:formData.type=="transfer"  ?  data.settings.accounts.filter(i=>i.id==formData.in_account_id)[0]?.role : data.settings.accounts.filter(i=>i.id==formData.account_id)[0]?.role,
    account_out_role:formData.type=="transfer"  ?  data.settings.accounts.filter(i=>i.id==formData.out_account_id)[0]?.role : data.settings.accounts.filter(i=>i.id==formData.account_id)[0]?.role
})


    

},[formData.account_id,formData.out_account_id,formData.in_account_id])

console.log({formData})

function handle_action(){

     if(message){
          toast.error(message)
          return
     }else if(!formData.type  ||
         !formData.amount ||
          (!formData.account_id && (formData.type=="in" || formData.type=="out"))  ||
           !formData.category ||
             !formData.description ||
              ((!formData.in_account_id ||
                 !formData.out_account_id) &&
                  formData.type=="transfer")) {
         toast.error('Preencha todos os campos obrigatorios!')
         console.log(formData)
         return 
     }

     if(formData.type=="transfer" && formData.in_account_id==formData.out_account_id){
         toast.error('Não é permitido transferir para mesma conta.')
         return 
     }


     setLoading(true) 

    if(edit){
      
       axios.post(`${process.env.REACT_APP_BASE_URL}/api/transation/update`,formData, {
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {

             let id=response.data.id
             let transations=JSON.parse(JSON.stringify(data.transations))
             let index=data.transations.findIndex(i=>i.id==id)
             transations[index]=response.data
             data.transations=transations
             toast.success('Transação actualizado com sucesso') 
             setData(data)
             setFormData({account_in_role:'',account_out_role:'',type:'',reference:'',amount:'',category:'',description:'',account:'',account_id:'',category_id:'', in_account:'',out_account:'',in_account_id:'',out_account_id:''})
             setEdit(null)
             setLoading(false) 
        })
        .catch(error => {
            setLoading(false)
            console.error('Error fetching user data:', error);
            try{
                   toast.error(error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
            }catch(e){
                   toast.error('Error, try again!')
            }
        });



    }else{
         axios.post(`${process.env.REACT_APP_BASE_URL}/api/transation/create`,formData, {
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {
             let transations=JSON.parse(JSON.stringify(data.transations))
             let new_data=response.data

             new_data.forEach(i=>{
                 transations.unshift({...i,by:user.name})
             })
             data.transations=transations
             setData(data)
             setCreate(false)
             setLoading(false) 
             toast.success('Transação criada com sucesso')
        })
        .catch(error => {
            setLoading(false)
            console.error('Error fetching user data:', error);
            try{
                   toast.error(error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
            }catch(e){
                   toast.error('Error, try again!')
            }
        });
    }
    
}



   
return (
<div className={`dialog-container-bg ${showD ? 'show' :''}`}>
<div className="bg-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}></div>
<div  className={`create-microcredit ${loading && 'loading'}`}>
                  <div className="top-title">{edit ? 'Editar' :' Adicionar'} transação</div>
                 {formData.contract_id && <div>
                         <div onClick={()=>navigate('/loans/'+formData.contract_id)} className="status" style={{transform:'translateY(0.3rem)',color:'#fff',background:'#2360E4',padding:'2px 5px',borderRadius:5,display:'flex',alignItems:'center'}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="21" fill="#fff"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"></path></svg><span style={{fontSize:12,color:'#fff',marginLeft:4}}>Ver contrato</span></div> 
                  </div> }
                 <div className="btn-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}>
                         <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                  </div>

                  <div className="input-container">
                      <div className="inputs">
                             <span>Tipo de transação <label class="mandatory">*</label></span>
                             <select disabled={formData.contract_id ? true : false}  value={formData.type} onChange={e=>setFormData({...formData, type:e.target.value})}>
                                  <option selected value="">Selecione transação</option>
                                  {transationTypes.map(i=>(
                                        <option key={i.id} selected={formData.type == i.id} value={i.id}>{i.label}</option>
                                  ))}
                             </select>
                     </div>

                    {(formData.type=="in" || formData.type=="out") && <div className="inputs">
                                                 <span>Conta de {formData.type=="in" ? "Entrada" : "Saida"} <label class="mandatory">*</label></span>
                                                 <select disabled={formData.contract_id ? true : false} value={formData.account_id} onChange={e=>setFormData({...formData, account_id:e.target.value,account:data.settings.accounts.filter(i=>i.id==e.target.value)[0].description})}>
                                                      <option disabled selected value="">Selecione conta</option>
                                                      {data.settings.accounts.map(i=>(
                                                            <option key={i.id} selected={formData.account == i.description} value={i.id}>{i.name} {i.description ? `(${i.description})`:''}</option>
                                                      ))}
                                                 </select>
                                                 {((formData.type=="out" || formData.type=="in") && formData.account_id) && <span style={{paddingRight:'0.2rem',justifyContent:'flex-end',display:'flex',height:0,opacity:.7,fontSize:'0.8rem'}}>Disponivel: {!_avaliable_a ? 0  : _avaliable_a.toLocaleString()}</span>}
                      </div>}


                      {formData.type=="transfer"  && (<div className="inputs">
                                                                       <span>Conta de saida<label class="mandatory">*</label></span>
                                                                       <select disabled={formData.contract_id ? true : false} value={formData.out_account_id} onChange={e=>setFormData({...formData, out_account_id:e.target.value,out_account:data.settings.accounts.filter(i=>i.id==e.target.value)[0].description})}>
                                                                            <option disabled selected value="">Selecione conta</option>
                                                                            {data.settings.accounts.map(i=>(
                                                                                  <option key={i.id} selected={formData.out_account == i.description} value={i.id}>{i.name} {i.description ? `(${i.description})`:''}</option>
                                                                            ))}
                                                                       </select>
                                 {(formData.type=="transfer" && formData.out_account_id) && <span style={{paddingRight:'0.2rem',justifyContent:'flex-end',display:'flex',height:0,opacity:.7,fontSize:'0.8rem'}}>Disponivel: {!_avaliable_a ? 0 : _avaliable_a.toLocaleString()}</span>}
                     
                        </div>
                        )}
                      <div className="inputs">
                            <span>Montante <label class="mandatory">*</label></span>
                           <input disabled={formData.contract_id ? true : false} type="number"   value={formData.amount} onChange={e=>setFormData({...formData,amount:parseFloat(e.target.value)})}  placeholder="Valor"/>
                     </div>
                      {formData.type=="transfer" && (<div className="inputs">
                                                                       <span>Conta entrada <label class="mandatory">*</label></span>
                                                                       <select disabled={formData.contract_id ? true : false} value={formData.in_account_id} onChange={e=>setFormData({...formData, in_account_id:e.target.value,in_account:data.settings.accounts.filter(i=>i.id==e.target.value)[0].description})}>
                                                                            <option disabled selected value="">Selecione conta</option>
                                                                            {data.settings.accounts.map(i=>(
                                                                                  <option key={i.id} selected={formData.in_account == i.description} value={i.id}>{i.name} {i.description ? `(${i.description})`:''}</option>
                                                                            ))}
                                                                       </select>
                        </div>
                        )}

                       <div className="inputs">
                            <span>Referência </span>
                           <input type="text"  disabled={formData.contract_id ? true : false}   value={formData.reference} onChange={e=>setFormData({...formData,reference:e.target.value})}  placeholder="Nome"/>
                     </div>
                      <div className="inputs">
                             <span>Categoria <label class="mandatory">*</label></span>
                             <select disabled={formData.contract_id ? true : false} value={formData.category_id} onChange={e=>setFormData({...formData, category_id:e.target.value, category:data.settings.categories.filter(i=>i.id==e.target.value)[0].name})}>
                                  <option disabled selected value="">Selecione categoria</option>
                                  {data.settings.categories.map(i=>(
                                        <option key={i.id} selected={formData.category == i.id} value={i.id}>{i.name}</option>
                                  ))}
                             </select>
                     </div>

                      <div className={`inputs ${formData.type=="transfer"  ||  !formData.type ? "full" : ""}`}>
                            <span>Descrição <label class="mandatory">*</label></span>
                            <textarea disabled={formData.contract_id ? true : false}  value={formData.description} onChange={e=>setFormData({...formData, description:e.target.value})} style={{height:'70px'}} placeholder="Descrição"></textarea>
                     </div>      
                  
                  </div>

                  <div className="res">
                        {message && <>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="20"><path fill="#FFA500" d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg>
                          <span className="message" style={{_color:'#FFA500',opacity:.6}}>{message}</span>
                          </>
                        }
                  </div>

              {!formData.contract_id && <div className="options">
                  <button className="cancel" onClick={()=>(edit ? setEdit(false) : setCreate(false) )}>Cancelar</button>
                  <div className="div_btn">
                     <button className="save" onClick={handle_action}><span>{edit? 'Actualizar' : 'Adicionar'}</span></button>
                     <span className="loader"></span>
                  </div>
              </div>}

        </div>

 </div>

)
};

export default Create;
