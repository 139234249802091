import '../../css/main.css'
import './style.css'
import Menu from '../../common/menu.js';
import TopBar from "../../common/topBar";
import Create from './create.js'
import DynamicGridExample from './table.js'
import DeleteDialog from './delete-dialog.js'
import { useState,useEffect } from 'react';
import { useData } from '../../../contexts/DataContext';
import { useAuth } from '../../../contexts/AuthContext';



export default function Microcredits() {
const {token,user} = useAuth();
const {data,setData} = useData();  

const [create, setCreate] = useState(false);
const [edit, setEdit] = useState(null);
const [del, setDel] = useState(null);
const [tableSearch,setTableSeach]=useState('');


 const [lent, setLent] = useState(0);
 const [paid, setPaid] = useState(0);
 const [expected, setExpected] = useState(0);

 useEffect(()=>{
   let lent=0
   let paid=0
   let expected=0


   data.loans.forEach(i=>{if(i.approval_status=="approved"){lent+=i.disbursement_value}})
   data.loans.forEach(i=>{if(i.approval_status=="approved"){paid+=i.paid}})
   data.loans.forEach(i=>{
      if(i.approval_status=="approved")  expected+=i.total_to_pay + i.late_payment_interest
   })

   setLent(lent)
   setPaid(paid)
   setExpected(expected)
},[data.loans, data])


return (
    <>
    <main className="dashboard-container">

      { <Create showD={(create || edit) ? true : false}  create={create} setCreate={setCreate} setEdit={setEdit} edit={edit} del={del}/>}
       { <DeleteDialog showD={del ? true : false} del={del}  setDel={setDel}/>}

        <div className="dashboard">
            <Menu/>

            <div className="main-dashboard">
                  <TopBar activePage={'Clientes na blacklist'}/>
                  <div className="center" style={{marginTop:'1rem'}}>
                       <div className="center_search">
                           <div className="search_container">
                                 <div className="search-div">
                                     <input type="text" placeholder="Pesquisar..." value={tableSearch} onChange={(e)=>setTableSeach(e.target.value)}/>
                                       <div className="search-btn">
                                       <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"/></svg>
                                    </div>
                                 </div>
                           </div>
                           
                       </div>

                       <div className="dasboard-item-list-container">
                            <div className="top-section">
                                 <div className="left-side">
                                          <div className="show">
                                             <span>Mostrar</span>
                                             <select>
                                                <option>10</option>
                                                <option>20</option>
                                                <option>30</option>
                                             </select>
                                          </div>
                                    
                                 </div>
                                 <div className="right-side">
                                          <div className="view">
                                                 <span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M120-520v-320h320v320H120Zm0 400v-320h320v320H120Zm400-400v-320h320v320H520Zm0 400v-320h320v320H520ZM200-600h160v-160H200v160Zm400 0h160v-160H600v160Zm0 400h160v-160H600v160Zm-400 0h160v-160H200v160Zm400-400Zm0 240Zm-240 0Zm0-240Z"/></svg>
                                                 </span>

                                                 <span className="active">
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M360-240h440v-107H360v107ZM160-613h120v-107H160v107Zm0 187h120v-107H160v107Zm0 186h120v-107H160v107Zm200-186h440v-107H360v107Zm0-187h440v-107H360v107ZM160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Z"/></svg>
                                                 </span>
                                          </div>
                                          <span className="page">4</span>
                                 </div>
                            </div>

                              {<DynamicGridExample tableSearch={tableSearch} setEdit={setEdit} del={del} setDel={setDel}/>}


                       </div>

                  </div>

                  
            </div>
        </div>
    </main>
    </>
  );
}
