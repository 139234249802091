import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';
import CustomNoRowsOverlay from '../common/tableLoader'

import toast from 'react-hot-toast';
export default function DataGridDemo({setEdit,del,setDel,tableSearch}) {
    const {token,user} = useAuth();
    const {data,setData,makeRequest} = useData();

    const [openMenu, setOpenMenu] = React.useState(null);
    const [loadingApproval,setLoadingApproval]=React.useState(false);

    const handleOutsideClick = (event) => {
        if (!event.target.closest('.menu') &&  !event.target.closest('.menu-option')) {
          setOpenMenu(null)
          document.removeEventListener('click', handleOutsideClick);
        }
    };

    const  handleClickMenu = (id) => {
         document.addEventListener('click', handleOutsideClick);
         setOpenMenu(id)
    };
    
    function handleLoanApproval(id,approval_status) {

       if(loadingApproval)  return

      setTimeout(()=>{
         setOpenMenu(null)
         document.removeEventListener('click', handleOutsideClick);
      },10)

      

      setLoadingApproval(true)

      let approval_toast=toast.loading(`A ${approval_status=='active' ? 'activar' : approval_status=='inactive' ? 'desactivar' : 'rejeitar'}...`)





       axios.post(`${process.env.REACT_APP_BASE_URL}/api/microcredit/handle-approval/`,{id,approval_status},{
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
                },
        })
        .then(response => {
            setLoadingApproval(false)
            toast.remove()
            if((response.data.status=="inactive" || response.data.status=="not_verified" ) && approval_status=='active'){
              toast.error('Altere as datas do plano primeiro!')
              return
            }

            toast.dismiss(approval_toast)
            toast.success(`Microcredito ${approval_status=='active' ? 'activada' : approval_status=='inactive' ? 'desactivada' : 'rejeitada'}`)
            let microcredits=JSON.parse(JSON.stringify(data.microcredits))
            let index=data.microcredits.findIndex(i=>i.id==id)
            microcredits[index].status=approval_status
            data.microcredits=microcredits
            setData(data)
            
        })
        .catch(error => {
            setLoadingApproval(false)
            toast.remove()
            toast.dismiss(approval_toast)
            console.error('Error fetching user data:', error);
            try{
                   toast.error('Ocorreu um erro:'+error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
            }catch(e){
                   toast.error('Error, try again!')
            }
           
        });
                 
    }





const columns = [
  {
    field: 'name',
    headerName: 'Nome',
    width: 150,
    editable: false,
    filterable: false
  },
  {
    field: 'location',
    headerName: 'Localização',
    width: 150,
    editable: false,
  },
  {
    field: 'nuit',
    headerName: 'Nuit da empresa',
    width: 150,
    editable:false,
  },
  {
    field: 'created_at',
    headerName: 'Data de cadastro',
    width: 150,
    editable: false,
  }
  ,
  {
    field: 'managers',
    headerName: 'Gestores',
    width: 150,
    editable: false,
    valueGetter: (params) => params.row.managers.map((user) => user.name+" "+user.last_name).join(', ')
  },
  ,
  {
    field: 'managers_c',
    headerName: 'Contacto',
    width: 150,
    editable: false,
    renderCell: (params) => (
      <span>{data.managers.filter(i=>params.row.managers.some(f=>f.id==i.id)).map((user) => user.contact).join(', ')}</span>
    )
  },
  {
    field: 'managers_email',
    headerName: 'Email',
    width: 210,
    editable: false,
    renderCell: (params) => (
      <span>{data.managers.filter(i=>params.row.managers.some(f=>f.id==i.id)).map((user) => user?.User?.email).join(', ')}</span>
    )
  },
  {
    field: 'activation',
    headerName: 'Data de activação',
    width: 150,
    editable: false,
    renderCell: (params) => (
      <span>{params.row.plan_info.activation_date ? params.row.plan_info.activation_date :"-"}</span>
    )
  },
  {
    field: 'expiration',
    headerName: 'Data de vencimento',
    width: 150,
    editable: false,
    renderCell: (params) => (
      <span>{params.row.plan_info.expiration_date ? params.row.plan_info.expiration_date :"-"}</span>
    )
  },
  {
    field: 'plan',
    headerName: 'Plano',
    width: 90,
    editable: false,
    renderCell: (params) => (
        <div style={{cursor:'pointer'}} onClick={() => handleEdit(params.row)}>
            <div style={{ backgroundColor:!params.row.plan || params.row.plan=='test' ? '#F9CE66': params.row.plan=='free' ?  "#d5e1f8" : params.row.plan=='gold' ?  "gold" :  params.row.plan=='premium' ?  "rgb(104 181 226)" : "rgb(132 248 192)" ,  color: '#111' , padding:'0.3rem',borderRadius:'0.3rem'}}>
              <span style={{display:'flex',minWidth:'60px',justifyContent:'center'}}>{params.row.plan ? params.row.plan : "tester"}</span>
            </div>
        </div>
    )
  },
  {
    field: 'status',
    headerName: 'Estado',
    width: 90,
    editable: false,
    renderCell: (params) => (
        <div style={{cursor:'pointer'}} onClick={() => handleEdit(params.row)}>
            <div style={{ backgroundColor:!params.row.status || params.row.status=="active"  ? '#4ACD35': params.row.status=='not_verified' ?  "#F9CE66"   : params.row.status=="inactive" ? "rgb(211 211 211)" : params.row.status=='rejected' ?  '#ed143d85' : '#F9CE66' ,  color: '#111' , padding:'0.3rem',borderRadius:'0.3rem'}}>
              <span style={{display:'flex',minWidth:'60px',justifyContent:'center'}}>{params.row.status =="not_verified" ? "pending" : !params.row.status ? "active" : params.row.status}</span>
            </div>
      </div>
    )
  },
  {
    field: 'edit',
    headerName: '',
    width: 30,
    renderCell: (params) => (
      <div style={{cursor:'pointer'}} onClick={() => handleEdit(params.row)}>
              <svg style={{opacity:.6}}  xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg>
       </div>
    )
  },
  
  {
      field: 'more',
      headerName: '',
      width: 130,
      renderCell: (params) => (
        <div style={{cursor:'pointer', position:'absolute'}} onClick={()=>handleClickMenu(params.row.id)}  className="menu">
                      {!loadingApproval && <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z"/></svg>}
                      <div className="menu-dropdown-row" style={{position:'absolute',display:openMenu==params.row.id ? 'block' :'none'}}>
                                    {params.row.status != "active" && params.row.status!="" && <span className={`menu-option ${params.row.approval_status=="approved" ? "disabled" :''}`} onClick={()=>handleLoanApproval(params.row.id,'active')}><svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="24"><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/></svg>Activar</span>}
                                    {params.row.status != "rejected" && params.row.status != "not_verified" && params.row.status != "inactive" && <span className={`menu-option ${params.row.approval_status=="rejected" ? "disabled" :''}`} onClick={()=>handleLoanApproval(params.row.id,'inactive')}><svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>Destivar</span>}
                                    {params.row.status != "rejected" && params.row.status != "active" && params.row.status != "inactive" && <span className={`menu-option ${params.row.approval_status=="rejected" ? "disabled" :''}`} onClick={()=>handleLoanApproval(params.row.id,'rejected')}><svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>Rejeitar</span>}
                                    <span className={`menu-option`} onClick={() => handleDelete(params.row)}> <svg style={{opacity:.6}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>Excluir</span>
                      </div>
          </div>
      )
    }
];



    function search(array){

               function search_from_object(object,text){
                      text=tableSearch
                      let add=false
                      Object.keys(object).forEach(k=>{
                        if(typeof object[k]=="string" || typeof object[k]=="number"){
                           if(typeof object[k]=="number") object[k]=`${object[k]}`
                           if(object[k].toLowerCase().trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(text.toLowerCase().trim().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))){
                             add=true
                          }
                        }
                      })
                      return add
              }

            if (!array) return []
            let d=JSON.parse(JSON.stringify(array))
            let res=[]

            d.forEach((t,i)=>{
              if(search_from_object(t)) {
                  res.push(array.filter(j=>j.id==t.id)[0])
              }
           })

           return res

    }


  function handleEdit(row) {
      setEdit(JSON.parse(JSON.stringify(data.microcredits.filter(i=>i.id==row.id)[0]))) 
  }
   function handleDelete(row) {
       setDel({id:row.id,confirm:null})
  }

  const [rows,setRows]=React.useState(data.microcredits)
  const [loading,setLoading]=React.useState(false)

  React.useEffect(()=>{
     setRows(search(data.microcredits))
  },[data,data.microcredits,tableSearch])


  React.useEffect(()=>{
    async function fetchData() {
             try {
                 let [managersRes, microcreditsRes,loanRes] = await Promise.all([
                     makeRequest({ url: 'managers', error: '' }),
                     makeRequest({ url: 'microcredits', error: '' }),
                     makeRequest({ url: 'loans', error: '' })
                 ]);
                 data.managers = managersRes;
                 data.microcredits = microcreditsRes;
                 data.loans = loanRes;
                 setData(prev=>({...prev, loaded:{...data.loaded,microcredits:true,managers:true,loans:true},managers:managersRes, microcredits: microcreditsRes,loans:loanRes}))
             } catch (e) {
                 console.error('Error fetching data:', e);
             }
         }

   fetchData();
 },[])


   


    React.useEffect(()=>{
    if(del?.confirm){

      axios.delete(`${process.env.REACT_APP_BASE_URL}/api/microcredit/delete/`+del.id ,{
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            data.microcredits=data.microcredits.filter(i=>i.id!=del.id)
            toast.success('Microcredito eliminado com sucesso')
            setData(data)
            setDel(null)
        })
        .catch(error => {
            setLoading(false)
            console.error('Error fetching user data:', error);
            try{
                   toast.error(error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
            }catch(e){
                   toast.error('Error, try again!')
            }

            setDel(null)
        });
      
    }
  },[del])




 
  
  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 6,
            },
          },
        }}
        pageSizeOptions={[6]} 
        disableColumnMenu
         localeText={{ noRowsLabel: <CustomNoRowsOverlay loading={!data.loaded.microcredits ? true : false}/>,  pagination: {labelRowsPerPage: 'Mostrar por página'} }}
       
      />
    </Box>
    
  );
}
