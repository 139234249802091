import { createContext, useContext, useState ,useEffect} from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';
import { jwtDecode } from "jwt-decode";
import { initializeApp } from "firebase/app";
import { getAnalytics,logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBnv0-8pW4_NY6ZdiK5SuKPk1NyVOjOs6Q",
  authDomain: "txapita-2e124.firebaseapp.com",
  databaseURL: "https://txapita-2e124.firebaseio.com",
  projectId: "txapita-2e124",
  storageBucket: "txapita-2e124.appspot.com",
  messagingSenderId: "1005064212276",
  appId: "1:1005064212276:web:2e315cf492b8dffbde1f61",
  measurementId: "G-ZEQVN1EGEN"
};


const DataContext = createContext();

export const DataProvider = ({ children }) => {

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);



  const {token,user} = useAuth();

  const [menuOpen, setMenuOpen] = useState(false)
 


 function divideDateRange(startDate, endDate, numberOfParts, total_to_pay) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const dateDiff = end - start;
  const interval = Math.floor(dateDiff / numberOfParts);

  let result = [];

  for (let i = 0; i < numberOfParts; i++) {
    const partStart = new Date(start.getTime() + i * interval);
    const partEnd = new Date(start.getTime() + (i + 1) * interval);
    if (i === numberOfParts - 1) {
      partEnd.setTime(end.getTime());
    }

    let item={id:Math.random(), start: partStart.toISOString().split('T')[0], end: partEnd.toISOString().split('T')[0] , total_to_pay: total_to_pay ? (total_to_pay /  numberOfParts): 0}
    item.initial_amount=item.total_to_pay
    result.push(item);

  }
 
  return result;
}


  ///functions
  const get_current_date=()=>{
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
  }
  
  
 let fire={
     init:function(){
        logEvent(analytics,'page_view')
     },
     click:function(item){
      logEvent(analytics,'clicked-section:'+item)
     }
 }
 

 


 

  const daysBetween=(date1,date2)=>{
     const milliseconds1 = date1.getTime();
    const milliseconds2 = date2.getTime();
    const diff = milliseconds2 - milliseconds1;
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    return days;
  }



  const calculateLatePaymentIntervals=(value, latePaymentArray)=>{
    let remainingValue = value;
    let result = [];

    for (let i = 0; i < latePaymentArray.length; i++) {
        const interval = latePaymentArray[i];
        const daysInRange = Math.min(remainingValue, interval.to - interval.from + 1);

        if (daysInRange > 0) {
            result.push({
                days: daysInRange,
                percentage: interval.percentage
            });
            remainingValue -= daysInRange;
        }

        if (remainingValue <= 0) {
            break;
        }
    }
    return result;
}


  const initial_data={
    dashboard:{},
    microcredits:[],
    managers:[],
    operators:[],
    clients:[],
    settings:{accounts:[],categories:[]},
    contracts:[],
    loans:[],
    loanToEdit:{},
    transations:[],
    loaded:{},
    notifications:[],
    loans_update:[],
    daysBetween,
    delayed_payments:[],
    blacklist:[],
    get_current_date,
    calculateLatePaymentIntervals,
    divideDateRange,
    my_blacklist:[],
    inventory:[]

  }

  const [data, setData] = useState(initial_data);


  function reset_data() {
     setData(initial_data)
  }


  function  updateData() {
      if(!token || !user)   return
      get_data()
  }

  function getCurrentLoan() {
    return data.loanToEdit
  }


 function updateUtils(argument) {
   
 }
  

const isTokenExpired = () => {
  if(!token) return 
  const decoded = jwtDecode(token);

  if (!token) return true; 
  const expiration = new Date(decoded.exp * 1000); 
  return expiration < new Date(); 
};


const refreshToken = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/refresh-token`, { expiredToken: token });
      const newToken = response.data.token;
      localStorage.setItem('token', newToken); 
      return newToken;
    } catch (error) {
      console.error('Token refresh failed:', error);
    }

};

  
 


  const value = {
    data,
    setData,
    getCurrentLoan,
    updateData,
    menuOpen,
    setMenuOpen,
    reset_data,
    updateUtils,
    refreshToken,
    makeRequest,
    fire
  };
 

const fetchingAPI=[
   /*{
    url:`${process.env.REACT_APP_BASE_URL}/api/notifications`,
    field:'notifications',
    who_can_access:['admin','manager','operator','client']
  },
  {
    url:`${process.env.REACT_APP_BASE_URL}/api/dashboard`,
    field:'dashboard',
    who_can_access:['admin','manager','operator']
  },
  {
    url:`${process.env.REACT_APP_BASE_URL}/api/managers`,
    field:'managers',
    who_can_access:['admin','manager']
  },
  {
    url:`${process.env.REACT_APP_BASE_URL}/api/microcredits`,
    field:'microcredits',
     who_can_access:['admin','manager','operator']
  },
  {
    url:`${process.env.REACT_APP_BASE_URL}/api/operators`,
    field:'operators',
    who_can_access:['admin','manager','operator']
  },
  {
    url:`${process.env.REACT_APP_BASE_URL}/api/clients`,
    field:'clients',
     who_can_access:['admin','manager','operator']
  },
  {
    url:`${process.env.REACT_APP_BASE_URL}/api/clients/blacklist`,
    field:'blacklist',
    who_can_access:['admin','manager','operator']
  },
  {
    url:`${process.env.REACT_APP_BASE_URL}/api/settings`,
    field:'settings',
    who_can_access:['manager','operator','admin']
  },
  {
    url:`${process.env.REACT_APP_BASE_URL}/api/loans`,
    field:'loans',
    who_can_access:['manager','operator','admin']
  },
  {
    url:`${process.env.REACT_APP_BASE_URL}/api/transations`,
    field:'transations',
    who_can_access:['manager','operator']
  }*/
]




/*
const fetchData = async () => {
   const storedToken = localStorage.getItem('token');
    if (isTokenExpired(storedToken)) {
      const newToken = await refreshToken();
      if (!newToken) return; // Token refresh failed, handle accordingly
      // Use new token for API request
      const response = await axios.get('/api/data', { headers: { Authorization: `Bearer ${newToken}` } });
      const data = response.data;
    } else {
      const response = await axios.get('/api/data', { headers: { Authorization: `Bearer ${storedToken}` } });
      const data = response.data;
    }
};
*/

async function makeRequest(options={data:{},method:'get'},maxRetries = 3, retryDelay = 3000) {
  
    let postData=options.data ? options.data : {}
   
    try {
     let response 
     let headers={
      'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
     }

     if(options.method=="post") {
          response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/`+options.url,postData,{headers}); 
     }else{
          response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/`+options.url,{headers});
     }
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);

      if (maxRetries > 0) {
            await new Promise(resolve => setTimeout(resolve, retryDelay)); 
            return makeRequest(options, maxRetries - 1, retryDelay); 
      } else {
            throw error; 
      }
     
    }
}



function get_data() {
  fetchingAPI.forEach((item)=>{
             if(item.who_can_access.includes(user.role)){
                  axios.get(item.url, {
                   headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${token}`,
                    },
                  })
                  .then(response => {

                       if(item.field=="dashboard"){
                           data[item.field]=response.data.data
                       }else{
                           data[item.field]=response.data
                       }
                        
                        //data.loaded[item.field]=true

                        /*setData({...data,[item.field]: item.field=="dashboard" ? response.data.data  :  response.data})*/

                        setData((prev)=>({...prev,loaded:{...data.loaded,[item.field]:true}, [item.field]:item.field=="dashboard" ? response.data.data  :  response.data}))

                    })
                  .catch(error => {
                        console.log(error)
                        console.error('Error fetching user data:', error);
                });
            }    
  })

}


useEffect(()=>{
       if(!token || !user)   return
        get_data()
},[user,token])

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export const useData = () => {
  return useContext(DataContext);
};