import './style.css'
import React from 'react';

export default function Compontent({data}) {

  return (
      <div class="table-container">
      <table>
      <thead>
        <tr>
        <th style={{width:'100px',fontWeight:600}}>Conta</th>
        <th>Domingo</th>
        <th>Segunda</th>
        <th>Terça</th>
        <th>Quarta</th>
        <th>Quinta</th>
        <th>Sexta</th>
        <th>Sabado</th>
        </tr>
      </thead>
      <tbody>
          {data.map(i=>(
              <tr> 
                  <td style={{width:'100px',fontWeight:600}}>{i.name}</td>
                  {i.data.map((value, index) => (
                     <td>{new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)}</td>
                  ))}
              </tr>
           ))}
      </tbody>
    </table>

     {!data.length && <span style={{fontSize:'0.9rem',display:'flex',justifyContent:"center",padding:'2rem',opacity:0.4}}>Sem registros</span>}
  </div>
  );


}
