import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const BarChart = ({ data }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const newChartInstance = new Chart(chartRef.current, {
        type: 'bar',
        data: {
          labels: data.map((company) => company.name),
          datasets: [
            {
              label: 'Número de transações',
              data: data.map((company) => company.times),
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              grid: {
                display: false,
              },
              beginAtZero: true,
            },
          },
        },
      });

      chartInstance.current = newChartInstance;

      return () => {
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }
      };
    }
  }, [data]);

  return <canvas ref={chartRef} />;
};

export default BarChart;
