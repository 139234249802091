import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import './style.css'
import { useData } from '../../../contexts/DataContext';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';


export default function Compontent() {

  const navigate = useNavigate();

   const {data, setData, updateData} = useData();
   const [loans,setLoans]=React.useState([])

   React.useEffect(()=>{
        if(data.loaded.dashboard)  setLoans(data.dashboard.upcoming_and_late_payments)
   },[data.loaded,data.dashboard])



  function handleEdit(id) {
      
      window.location.href = '/loans/'+id
  }
  
  return (
      <div class="table-container">
    <table>
      <thead>
        <tr>
          <th>Cliente</th>
          <th>Montante</th>
          <th>Dias em falta</th>
          <th>Vencimento</th>
          <th>Estado</th>
        </tr>
      </thead>
      <tbody>
       
          {loans.map(i=>(
              <tr className="tr" onClick={()=>handleEdit(i.contract_id)}> 
                  <td>{i.client.name}</td>
                  <td>{new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(i.total_to_pay - i.paid)}</td>
                  <td>{i.days_left == 0 ? "Hoje" : i.days_left < 0 ? -i.days_left+" dias atrás" : i.days_left}</td>
                  <td>{i.end}</td>
                  <td>
                  <div style={{padding:'0 0.3rem', backgroundColor:!i.status || i.status=='pending' || i.status=='parcial' ? '#F9CE66': i.status=="paid" ? '#4ACD35' : '#ed143d85', color: '#111' , padding:'0.2rem 0',borderRadius:'2rem',fontSize:'.9rem'}}>
                  <span style={{padding:'0 0.3rem', display:'flex',minWidth:'30px',justifyContent:'center'}}>  {i.status=='pending' || !i.status ? 'Pendente':  i.status=="paid" ? 'Pago' :  i.status=="parcial" ? 'Parcialmente' : 'Atrasado'}</span>
                  </div>
                  </td>
              </tr>

           ))}
       
      </tbody>
    </table>

     {!loans.length && <span style={{fontSize:'0.9rem',display:'flex',justifyContent:"center",padding:'2rem',opacity:0.4}}>Sem registros</span>}
  </div>
  );


}
