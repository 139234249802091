import React, { useState } from 'react';
import Select from 'react-select';

const options = [
  { id: 'apple', name: 'Apple' }
];

const MySelectComponent = ({options,selectedOptions,placeholder,setSelectedOptions,noOptionsMessage}) => {

   const customStyles = {
    control: (provided, state) => ({
     ...provided,
      outline: 'none',
      boxShadow: state.isFocused ? '0 0 6px #4d90fe' : '0',
      border: state.isFocused ? '1px solid #4d90fe' : '1px solid #DDDDDD',
      WebkitTransition: 'all 0.1s ease-in-out',
      MozTransition: 'all 0.1s ease-in-out',
      MsTransition: 'all 0.1s ease-in-out',
      OTransition: 'all 0.1s ease-in-out',
      height:'38px',
      margin: 0, 
      padding: 0, 
  }),
  input: (provided, state) => ({
    ...provided,
    margin: 0, 
    padding: 0,
  }),
  }

  const handleSelectChange = (selected) => {
      setSelectedOptions(selected)
  };

  return (
    <Select
      value={selectedOptions}
      styles={customStyles}
      getOptionValue={(option) => option.id} 
      getOptionLabel={(option) => option.name}
      onChange={handleSelectChange}
      options={options}
      placeholder={placeholder}
    />
  );
}

export default MySelectComponent;

