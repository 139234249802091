
import { useEffect, useState} from 'react';
import { useData } from '../../../contexts/DataContext';
import { useAuth } from '../../../contexts/AuthContext';
import axios from 'axios';
import toast from 'react-hot-toast';

const Create = ({createInstallment,setCreateInstallment,accounts,showD}) => {

const [formData,setFormData]=useState({paid:0,status:'pending',amount_to_pay:0})

const [loading,setLoading]=useState(false)

const {data, setData} = useData();
const {token, user} = useAuth();



useEffect(()=>{
      //setFormData({...formData,...create, amount_to_pay:!create.paid  ? create.total_to_pay : create.total_to_pay - create.paid})
},[createInstallment])

useEffect(()=>{
   /*if(formData.amount_to_pay < 0 ){
       setFormData({...formData,amount_to_pay:0})
    }else if(formData.amount_to_pay > (formData.total_to_pay - formData.paid)){
       setFormData({...formData,amount_to_pay:formData.total_to_pay - formData.paid})
    }*/
},[formData])



function actions(){

        /*if(!formData.amount_to_pay && formData.status!="paid"){
           toast.error('Insira o montante!')
           return
        }


         formData.amount_to_pay=parseFloat(formData.amount_to_pay)
         formData.paid=formData.paid + formData.amount_to_pay
         formData.status=formData.paid == formData.total_to_pay  ? "paid" :  formData.paid  <  formData.total_to_pay &&  formData.paid ?  "parcial" : formData.status
       

          const newInstallmentsRes=installmentsRes.map(i=>{
             if(i.id==formData.id) {
                return formData
             }else{
                return i
             }
          }) 

         setInstallmentsRes(newInstallmentsRes)
         setCreate(false)*/
}




   
return (
  <div className={`dialog-container-bg ${showD ? 'show' :''}`}>
  <div className="bg-close" onClick={()=>setCreateInstallment(false)}></div>
<div  className={`create-microcredit ${loading && 'loading'}`}>
                  <div className="top-title">Adicionar prestação</div>  
                  <div className="btn-close" onClick={()=>setCreateInstallment(false)}>
                         <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                  </div>

                
                  <div className="payment-top-cards">
                         <div className="item">
                            <span className="name">Em falta</span><span className="value">{0==0 ? 0 : formData.total_to_pay - formData.paid}</span>
                         </div>
                          <div className="item">
                            <span className="name">Pago</span><span className="value">{0==0 ? 0 : formData.paid}</span>
                          </div>

                  </div>


                  <div className="input-container">
                     <div className="inputs">
                           <span>Montante a pagar <label className="mandatory">*</label></span>
                           <input value={formData.amount_to_pay} onChange={e=>setFormData({...formData,amount_to_pay:e.target.value})} type="number" placeholder="Montante a pagar"/>
                     </div>
                      <div className="inputs">
                           <span>Data de desembolso<label className="mandatory">*</label></span>
                           <input value={formData.amount_to_pay} onChange={e=>setFormData({...formData,amount_to_pay:e.target.value})} type="date" placeholder="Montante a pagar"/>
                      </div>
                  </div>

                 

                  <div className="payment-res">
                       <span className="name">Em falta após o pagamento </span><span className="value">{0==0 ? 0 : formData.total_to_pay - formData.amount_to_pay - formData.paid}</span>
                  </div>



              <div className="options">
                  <button className="cancel" onClick={()=>setCreateInstallment(false)}>Cancelar</button>
                  <div className="div_btn">
                     <button className="save" onClick={()=>setCreateInstallment(false)}><span>Adicionar</span></button>
                     <span className="loader"></span>
                  </div>
              </div>

        </div>
         </div>


)
};

export default Create;
