import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useData } from '../../../contexts/DataContext';
import { useAuth } from '../../../contexts/AuthContext';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import axios from 'axios';

const CustomNoRowsOverlay = () => (
  <div style={{ textAlign: 'center', padding: '20px' }}>
      Sem registros
  </div>
);


export default function DataGridDemo({setEdit ,setDel ,del ,installments, editMode, setCreate}) {
    
   const {pathname} = useLocation()

  const columns = [
    {
      field: 'total_to_pay',
      headerName: 'Valor a pagar',
      width: 170,
      editable: true,
      valueGetter: (params) => new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(params.row.total_to_pay)  || "-",

      
    },
    {
      field: 'paid',
      headerName: 'Valor pago',
      width: 170,
      editable: true,
       valueGetter: (params) => (params.row.paid ? new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(params.row.paid)  : 0)  || "-",
      align: "center"

    },
    {
      field: 'end',
      headerName: 'Prazo de pagamento',
      width: 170,
      editable: true,
    },
    {
      field: 'delay',
      headerName: 'Dias em atraso',
      width: 170,
      editable: true,
      align: "center",
      renderCell: (params) => (
        <div>
              {params.row.delay > 0 ? params.row.delay : '-'}
        </div>
      )
    },
    {
      field: 'late_payment_interest',
      headerName: 'Juros de mora',
      width: 170,
      editable: true,
      valueGetter: (params) => (params.row.late_payment_interest ? new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(params.row.late_payment_interest)  : 0)  || "-",
     
      align: "center"
    },
    {
      field: 'status',
      headerName: 'Estado',
      width: 120,
      valueGetter: (params) => params.row.status || "pending",
      renderCell: (params) => (
        <div style={{cursor:'pointer'}} onClick={() => handleEdit(params.row)}>
            <div style={{ backgroundColor:!params.row.status || params.row.status=='pending' || params.row.status=='parcial' ? '#F9CE66': params.row.status=="paid" ? '#4ACD35' : '#FF6B6B', color: '#111' , padding:'0.5rem',borderRadius:'2rem'}}>
              <span style={{display:'flex',minWidth:'60px',justifyContent:'center'}}>  {params.row.status=='pending' || !params.row.status ? 'Pendente':  params.row.status=="paid" ? 'Pago' :  params.row.status=="parcial" ? 'Parcialmente' : 'Atrasado'}</span>
            </div>
        </div>
      )
    },
    {
      field: 'edit',
      headerName: '',
      width: 10,
      renderCell: (params) => (
        <div style={{cursor:'pointer',display:(data.loanToEdit.approval_status=="pending" && pathname!="/loans/create" || pathname=="/loans/create" && user.role!="manager") ? 'none' :'flex' }} onClick={() => handleEdit(params.row)}>
              <svg style={{opacity:.6}}  xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg>
       </div>
      )
    }
  ];

  function handleEdit(row) {
          setCreate(row)
          data.installmentToEdit=row
  }

  function handleDelete(row) {
          
  }


  const {token,user} = useAuth();
  const {data,setData} = useData();

  const [rows,setRows]=React.useState(installments)

  React.useEffect(()=>{
      setRows(installments)
  },[installments])
  
  return (
   <DataGrid
          sx={{ height: 300, width: '100%'}}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 6,
              },
            },
          }}
          pageSizeOptions={[6]} 
          disableColumnMenu
          localeText={{ noRowsLabel: <CustomNoRowsOverlay/>,  pagination: {labelRowsPerPage: 'Mostrar por página'} }}
          rowsPerPageOptions={[5, 10, 25]}
     />
  );


}
