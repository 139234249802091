import '../css/main.css'
import './style.css'
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useAuth  } from '../../contexts/AuthContext';
import Select from './select-microcredit.js'
import toast from 'react-hot-toast';



const Login=function() {
     const location = useLocation();
     const currentPath = location.pathname;

    const {login } = useAuth()
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [userCompanies, setUserCompanies]=useState(null);
    const [openSelect,setOpenSelect]=useState(null);

  const handleKeyPress = (event) => {
    if (event.key == 'Enter') {
       handleLogin();
    }
  };


  useEffect(()=>{
             
            if(currentPath.includes('/test')){
                setPassword('test123')
                setEmail('test@visum.co.mz')
            }

  },[])


    const handleLogin = async () => {

        setUserCompanies(false)

         if(!email || !password){
            toast.remove()
            toast.error('Preencha todos os campos!')
            return
         }

         setLoading(true)
            try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/login`, {
                username:email,
                password
            });
            if (typeof window !== 'undefined') {
                localStorage.setItem('token',response.data.token);
            }


            setLoading(false)

            if(response.data.length >= 2){
                  setUserCompanies(response.data)
                  setOpenSelect(true)
                  return
            } 

            toast.remove()
            toast.success('Autenticado com sucesso!')
            login(response.data[0],response.data[0].token)
            navigate('/dashboard');
        } catch (error) {
            try{
              console.error('Login failed:', error.response.data);
              toast.error(error.response.data.message)
            }catch(e){
              console.log(e)
              toast.remove()
              toast.error('Ocorreu um erro, verifique sua internet e tente novamente!')
            }
            setLoading(false)
        }
    };


  return (
    <>
    <main  className={`login-container ${loading && 'loading'}`}>
      <div className="msg-error">
                  
      </div>

      <div className="center-div">

                {openSelect && <Select userCompanies={userCompanies} setOpenSelect={setOpenSelect}/>}

                <div className="login-form">
                 <div className="logo-c">
                            {/*<svg width="25" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="20.5" cy="20.5" r="20.5" fill="#0560FA"/>
                                                        </svg> */ }
                            <h1 style={{cursor:'pointer'}} onClick={()=>navigate('/')}>VLMS</h1>
                  </div>
                  
                  <h2>Login</h2> 

                  <div className="div-input">
                          <svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960" ><path fill="#D9D9D9" d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z"/></svg>
                           <input  onKeyPress={handleKeyPress} value={email} onChange={(e) => setEmail(e.target.value)} className="username" type="text" placeholder="Email"/>
                  </div>  
                  <div className="div-input">
                          <svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 -960 960 960"><path fill="#D9D9D9" d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm0-80h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z"/></svg>
                          <input  onKeyPress={handleKeyPress} value={password} onChange={(e) => setPassword(e.target.value)} className="password" type="password" placeholder="Senha"/>
                  </div>

                  {/*<div className="forgot-password">Esqueceu senha?</div>*/}

                  

                  <div className="div_btn">
                     <button onClick={handleLogin}>Entrar</button>
                     <span className="loader"></span>
                  </div>

                  {!loading && userCompanies && !openSelect && (<span className="btn-select-account" onClick={()=>setOpenSelect(true)}>Selecione uma conta</span>)}

                  
                  
                </div>
        <div className="text-container">
                { /* <Image
                        src="/images/login/login-right-image.png"
                        alt="Login background"
                        fill
                      />*/ }

            <div className="top-content">
               <span className="welcome-text">Bem vindo!</span> 
               <div className="copy">VLMS &copy; 2024</div>
            </div>
        </div>


      </div>
       <div className="footer"><span><Link to={"/terms"}>Termos</Link></span> | <span><Link to={"/privacy-politics"}>Politicas</Link></span></div>
    </main>
    </>
  );
}



export default  Login
