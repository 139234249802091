import { useState , useEffect} from 'react'

import '../css/main.css'

import axios from 'axios';
import toast from 'react-hot-toast';
import { Toaster } from 'react-hot-toast';


function Component() {

  let [page,setPage]=useState('company')
  let [plan,setPlan]=useState(null)
  let [formData,setFormData]=useState({})
  let [loading,setLoading]=useState(false)
  let [confirm,setConfirm]=useState(false)
  let [showConfirmDialog,setShowConfirmDialog]=useState(false)

 useEffect(()=>{
     
 },[formData])



 async function send_data(resend){
       toast.remove()

      
    
       let can_pass=true
       Object.keys(formData).forEach(i=>{
         
            if(!formData[i] && i!="verification_code" &&  i!= (formData.plan=="free" ? 'period' : 'none')){
                  can_pass=false
            } 
          
       })


       if(!can_pass || Object.keys(formData).length < (formData.plan=="free" ? 8 : 9)){
          toast.error('Preencha todos os dados')
          return
       }


       if(formData.contact.length != 9){
          toast.error('Número de contacto deve ter 9 dígitos.')
          return
       }
      



        function isValidEmail(email) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return emailRegex.test(email.trim());
        }

        if(!isValidEmail(formData.email)){
            toast.error('email invalido')
            return
        }



       try {

            setLoading(true)
            
           // toast.loading("A enviar...")

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/register/microcredit`,{...formData});
            setConfirm(true)
            toast.remove()

            setLoading(false)

            if(resend=="resend"){
               toast.success('código reenviado!')
            }

        }catch (error) {
            toast.remove()
            try{
              toast.error('Correu um:'+ error.response.data.message);
              console.log(error)
            }catch(e){
              console.log(e)
              toast.error('Something went wrong, check your internet connection and try again!')
            }

            setLoading(false)
       }
  }

  
  async function go_to_personal(){
        toast.remove()
        if(!formData.company_name || !formData.company_location || !formData.company_nuit || !formData.plan || (!formData.period && formData.plan!="free") ){
            toast.error('preecha todos os dados!')
            return
        }

        if(formData.company_nuit.length != 9){
          toast.error('Número de nuit deve ter 9 dígitos.')
          return
         }
         
    

       setPage('personal')

  }

  async function selectPlan(plan){
    toast.remove()
        setPlan(plan)
        setPage('company')
  }




  function reset_form(){
    toast.remove()
            setShowConfirmDialog(false)
            setConfirm(false)
            document.querySelectorAll('input').forEach(i=>i.value="")
            setFormData({period:"",plan:""})
            setPage('company')

  }


  function change_form(feild,value,max){
    if (value.length > 9) {
      value = value.slice(0, 9);
    }
    value=value.replace(/[^0-9]/g, '');

    console.log(value)

    setFormData({...formData,[feild]:value})
  }

 

  function resend_email(){
    toast.remove()
          send_data('resend')    
  }

  function cancel_confirmation(){
        toast.remove()
        setConfirm(false)
        setFormData({...formData,verification_code:''})
        document.querySelectorAll('.confirm_container input').forEach(i=>i.value="")
  }


  async function send_confirmation(resend){
       toast.remove()
      
       if(!formData.verification_code){
          toast.error('Insira o código')
          return
       }


       try {
            setLoading(true)
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/register/microcredit/verify`,{...formData});
             console.log(response.status)
            toast.remove()
            setShowConfirmDialog(true)
            setLoading(false)

        }catch (error) {

            toast.remove()
            try{
              if(error.response.status=="400"){
                toast.error('código invaido');
              }else{
                toast.error('Ocorreu um erro:'+ error.response.data.message);
                console.log(error)
              }
              
            }catch(e){
              console.log(e)
              toast.error('Something went wrong, check your internet connection and try again!')
            }

            setLoading(false)
       }

  }

  return (
        <>
              <div className={`delete-dialog ${showConfirmDialog && 'active'} `}> 
                            <div className="btn-close" onClick={()=>reset_form()}>
                                   <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                            </div> 


                          <div className="alert-icon">
                                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="70" fill="green"><path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                          </div>

                           <p>Seu pedido foi submetido com sucesso. Por favor, aguarde a aprovação por parte da equipe VLMS. Para mais detalhes entre em contato.<br/> <a href="mailto:vlsm@visum.co.mz">vlms@visum.co.mz</a></p>

                         {/*<p>Seu pedido foi submetido com sucesso.  Entraremos em contato em breve!</p>*/}

                         <div className="options"> 
                                <button onClick={()=>reset_form()} className="save">Fechar</button> 
                             
                         </div>  

                           
                </div>
      


    
            <div className={`join ${page} ${confirm ? 'confirm' :''}  ${showConfirmDialog && 'done'}`} id="join">
                     
                     <div className="section-title">
                     <h3>Pronto para aderir?</h3>
                     <span>Insira os dados da empresa e  escolha o melhor plano para o seu negócio.</span>
                    </div>


                    <div className="join-c">

                       <div className="left">
                           <div className="steps">
                             {/* <span className={`${page=="plan" ? "active" :""}`}>Plano</span> */}
                              <span className={`${page=="company" ? "active" :""}`}>Dados da  empresa</span>
                              <span className={`${page=="personal" ? "active" :""}`}>Dados pessoais</span>
                            </div>
                       </div>

                       <div className="right">
                          <div className="slide">

                          <div  className={`create-microcredit ${loading && 'loading'} ${page=="company" ? "active" :""}`}>
                                  <div className="top-title">Dados da empresa</div>  
                                

                                  <div className="input-container">
                                    
                                     <div className="inputs">
                                            <span>Empresa <label class="mandatory">*</label></span>
                                           <input type="text" onChange={(e)=>setFormData({...formData,company_name:e.target.value})} placeholder="Nome"/>
                                     </div>
                                     <div className="inputs">
                                            <span>Localização <label class="mandatory">*</label></span>
                                           <input onChange={(e)=>setFormData({...formData,company_location:e.target.value})} type="text" placeholder="Localização da empresa"/>
                                     </div>

                                     <div className="inputs">
                                            <span>Nuit <label class="mandatory">*</label></span>
                                           <input value={formData.company_nuit} maxlength="9" onChange={(e)=>change_form('company_nuit',e.target.value,9)} type="number" placeholder="Nuit da empresa"/>
                                     </div>


                                      <div className="inputs">
                                            <span>Plano <label class="mandatory">*</label></span>
                                            <select value={formData.plan} onChange={(e)=>setFormData({...formData,plan:e.target.value})}>
                                               <option disabled selected value="">Selecione um plano</option>
                                               <option value="free">Grátis</option>
                                               <option value="standard">Standard</option>  
                                               <option value="premium">Premium</option>
                                               <option value="gold">Gold</option>                               
                                            </select>
                                       </div>

                                      {formData.plan!="free" && formData.plan && <div className="inputs">
                                          <span>Período de subscrição do plano<label class="mandatory">*</label></span>
                                          <select value={formData.period} onChange={(e)=>setFormData({...formData,period:e.target.value})}>
                                             <option disabled selected value="">Selecione o período</option>
                                             <option value="monthly">Mensal</option>
                                             <option value="yearly">Anual</option>       
                                          </select>
                                                                             </div>}
                                  
                                  </div>


                               <div className="options">
                                  <div className="empty"></div>
                                  <div className="div_btn">
                                   <button onClick={go_to_personal} className="save"><span>Seguinte</span></button>
                                   <span className="loader"></span>
                               </div>
                      </div>

            </div>


                     

                       <div  className={`create-microcredit ${loading && 'loading'} ${page=="personal" ? "active" :""}`}>
                                  <div className="top-title">Dados pessoais</div>  
                                

                                  <div className="input-container">
                                    
                                     <div className="inputs">
                                           <span>Nome <label class="mandatory">*</label></span>
                                           <input onChange={(e)=>setFormData({...formData,name:e.target.value})} type="text" placeholder="Seu Nome"/>
                                     </div>

                                      <div className="inputs">
                                            <span>Apelido <label class="mandatory">*</label></span>
                                           <input onChange={(e)=>setFormData({...formData,last_name:e.target.value})} type="text" placeholder="Seu Apelido"/>
                                     </div>

                                      <div className="inputs">
                                            <span>Email <label class="mandatory">*</label></span>
                                           <input type="text" onChange={(e)=>setFormData({...formData,email:e.target.value})} placeholder="Seu email"/>
                                     </div>
                                    

                                     <div className="inputs">
                                            <span>Contacto <label class="mandatory">*</label></span>
                                            <input value={formData.contact} maxlength="9" onChange={(e)=>change_form('contact',e.target.value,9)} type="number" placeholder="Contacto"/>
                                    
                                     </div>
                                  
                                  </div>




                                <div className="options">
                                    <button className="cancel" onClick={()=>setPage('company')}><span>Voltar</span></button>
                                
                                     <div className="div_btn">
                                     <button className="save" onClick={send_data}><span>Enviar</span></button>
                                     <span className="loader"></span>
                                     </div>
                                </div>
              </div>


              <div className={`confirm_container ${loading && 'loading'} ${loading && 'loading'}`}>
                    <span className="title">Acabamos de enviar um código de confirmação para <strong>{formData.email}</strong></span>
                    <div class="inputs"><input  onChange={(e)=>setFormData({...formData,verification_code:e.target.value})}  placeholder="Digite o código" type="text"/>
                    <div className="div_btn">
                    <button onClick={send_confirmation}><span>Confirmar</span></button>
                      <span className="loader"></span>
                    </div>
                    </div>
                    <div className="options"><span onClick={cancel_confirmation}>Cancelar</span> | <span onClick={resend_email}>Reenviar código</span> | <span onClick={cancel_confirmation}>Mudar email</span></div>
              </div>  
                   

              <div className={`plan ${page=="plan" ? "active" :""}`}>
                  <span className="title">Escolha um plano</span>
                  <div className="_plans">
                     <label><div  className={`item ${plan=="free" ? "active" :""}`} onClick={()=>selectPlan('free')}><input name="plan" type="radio"/> <span>Free</span></div></label>
                     <label><div  className={`item ${plan=="standard" ? "active" :""}`} onClick={()=>selectPlan('standard')}><input name="plan" type="radio"/> <span>Standard</span></div></label>
                     <label><div  className={`item ${plan=="premium" ? "active" :""}`} onClick={()=>selectPlan('premium')}><input name="plan" type="radio"/> <span>Premium</span></div></label>
                     <label><div  className={`item ${plan=="gold" ? "active" :""}`} onClick={()=>selectPlan('gold')}><input name="plan" type="radio"/> <span>Gold</span></div></label>
                   { /* <div className={`options ${plan ? '': 'hide'}`}>
                                                        <div className="div_btn" >
                                                           <button className="save" onClick={()=>setPage('company')}><span>Seguinte   <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z"/></svg>
                                                        </span></button>
                                                          </div>
                                        </div>*/}

                  </div>
              </div>

               </div>
         
                       </div>


                    </div>
            </div>

            </>

  )
}

export default Component