
import { useEffect, useState} from 'react';
import { useData } from '../../../contexts/DataContext';
import { useAuth } from '../../../contexts/AuthContext';
import axios from 'axios';
import toast from 'react-hot-toast';

const Create = ({create,setCreate, edit , setEdit, del ,showD}) => {

const [formData,setFormData]=useState({id:'',name:''})

const [loading,setLoading]=useState(false)

const {data, setData} = useData();
const {token, user} = useAuth();



useEffect(()=>{
    if(edit){
       setFormData(edit)
       setCreate(false)
    }
},[edit])

useEffect(()=>{
    if(create){
       setEdit(false)
       setFormData({id:'',name:''})
    }
},[create])





function handle_manager(){

    if(edit){

      if(!formData.name) {
           toast.error('Insira o nome')
           return
      }

      setLoading(true) 

       axios.post(`${process.env.REACT_APP_BASE_URL}/api/cash-management/settings/categories/update`,formData, {
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {
             let id=response.data.id
             let categories=JSON.parse(JSON.stringify(data.settings.categories))
             let index=data.settings.categories.findIndex(i=>i.id==id)
             categories[index]=formData
             data.settings.categories=categories
             toast.success('Categoria actualizada!')
             setData(data)
             setFormData({id:'',name:''})
             setEdit(null)
             setLoading(false)  
        })
        .catch(error => {
            setLoading(false)
            console.error('Error data:', error);

            try{
                   toast.error(error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
            }catch(e){
                   toast.error('Error, try again!')
            }
            
        });

    }else{
       if(!formData.name) {
         toast.error('Insira o nome')
         return 
      }
     
      setLoading(true) 
         axios.post(`${process.env.REACT_APP_BASE_URL}/api/cash-management/settings/categories/create`,formData, {
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {
             console.log(response)
             toast.success('Categoria adicionada!')
             formData.id=response.data.id
             console.log(data.settings.categories)
             data.settings.categories=[...data.settings.categories,formData]
             setData(data)
             setCreate(false)
             setLoading(false)  
        })
        .catch(error => {
            setLoading(false)
            console.error('Error data:', error);
            try{
                   toast.error(error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
            }catch(e){
                   toast.error('Error, try again!')
            }
            
        });
    }

    
}
   
return (
 <div className={`dialog-container-bg ${showD ? 'show' :''}`}>
  <div className="bg-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}></div>
<div  className={`create-microcredit ${loading && 'loading'}`}>
                  <div className="top-title">{edit ? 'Editar' :' Adicionar'} categoria</div>  
                  <div className="btn-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}>
                         <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                  </div>

                  <div className="input-container">
                     <div className="inputs">
                           <span>Nome</span>
                           <input value={formData.name} onChange={e=>setFormData({...formData,name:e.target.value})} type="text" placeholder="Nome da categoria"/>
                     </div>
                  </div>


              <div className="options">
                  <button className="cancel" onClick={()=>(edit ? setEdit(false) : setCreate(false) )}>Cancelar</button>
                  <div className="div_btn">
                     <button className="save" onClick={handle_manager}><span>{edit? 'Actualizar' : 'Adicionar'}</span></button>
                     <span className="loader"></span>
                  </div>
              </div>

        </div>
         </div>


)
};

export default Create;
