import React from 'react';

const Menu = ({ items, isOpen, position }) => {
  if (!isOpen) {
    return null;
  }

  const styles = {
    top: position.y + 'px',
    left: position.x + 'px',
    position:'absolute',
    zIndex:'99'
  };

  return (
    <div style={styles} className="menu-dropdown-row">
      {items.map((item) => (
        <div key={item.id} className="menu-option">{item.label}</div>
      ))}
    </div>
  );
};

export default Menu;
