import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';
import toast from 'react-hot-toast';
import CustomNoRowsOverlay from '../common/tableLoader'
export default function DataGridDemo({setEdit,del,setDel,tableSearch,setFilteredContent,filters}) {

    const {token,user} = useAuth();
    const {data,setData,makeRequest} = useData();


    const [openMenu, setOpenMenu] = React.useState(null);

    const handleOutsideClick = (event) => {
        if (!event.target.closest('.menu') &&  !event.target.closest('.menu-option')) {
          setOpenMenu(null)
          document.removeEventListener('click', handleOutsideClick);
        }
    };


    const  handleClickMenu = (id) => {
         document.addEventListener('click', handleOutsideClick);
         setOpenMenu(id)
    };


const columns = [
  {
    field: 'createdAt',
    headerName: 'Data',
    width: 200,
    editable: false,
    filterable: false,
    valueGetter: (params) =>new Date(params.row.createdAt).toISOString().split('T')[0] + " "+ new Date(params.row.createdAt).toISOString().split('T')[1].replace('.000Z','').slice(0,5) || "-",
  },
  {
    field: 'reference',
    headerName: 'Referência',
    width: 150,
    editable: false,
    valueGetter: (params) => params.row.reference || "-",
  },
  {
    field: 'description',
    headerName: 'Descrição',
    width: 150,
    editable:false,
    valueGetter: (params) => params.row.description || "-",
  },
  {
    field: 'type',
    headerName: 'Tipo de Transação',
    width: 150,
    editable: false,
    renderCell: (params) => (
        <div>
             <div style={{ backgroundColor:params.row.type=='in' ? '#CEDBF7':  '#F9CE65', color: '#111' , padding:'0.5rem',borderRadius:'2rem'}}>
                <span style={{display:'flex',minWidth:'60px',justifyContent:'center'}}>  {params.row.type=='in' ? 'Entrada':  'Saida'}</span>
             </div>
        </div>
    )
  },
  ,
  {
    field: 'amount',
    headerName: 'Montante',
    width: 150,
    editable: false,
    valueGetter: (params) => (params.row.type=="out" ? "-" : "") +""+new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(params.row.amount)  || "-", 
      
  },
  {
    field: 'account',
    headerName: 'Conta',
    width: 150,
    editable: false,
    valueGetter: (params) => (data.settings.accounts.some(i=>i.id==params.row.account_id) ? params.row.account + `(${data.settings.accounts.filter(i=>i.id==params.row.account_id)[0].name})` : params.row.account) || "-",
  },
  {
    field: 'category',
    headerName: 'Categoria',
    width: 150,
    editable: false,
    valueGetter: (params) => (data.settings.categories.some(i=>i.id==params.row.category_id) ? data.settings.categories.filter(i=>i.id==params.row.category_id)[0].name : params.row.category) || "-",
  },
  {
    field: 'by',
    headerName: 'Criado por',
    width: 150,
    editable: false,
    renderCell:(params)=>(
       <span>{params.row.created_by_id==user.id ? '(Você)' : params.row.by ? params.row.by :'-' }</span>
    )
  },
  {
    field: 'edit',
    headerName: '',
    width: 30,
    renderCell: (params) => (
      <div style={{cursor:'pointer'}} onClick={() => handleEdit(params.row)}>
          <svg style={{opacity:.6}}  xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"/></svg>
       </div>
    )
  },
   {
      field: 'more',
      headerName: '',
      width: 130,
      renderCell: (params) => (
        <div style={{cursor:'pointer', position:'absolute',/*display:params.row.contract_id ? 'none' :'flex'*/}} onClick={()=>handleClickMenu(params.row.id)}  className="menu">
                      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z"/></svg>
                      <div className="menu-dropdown-row" style={{position:'absolute',display:openMenu==params.row.id ? 'block' :'none'}}>
                                   <span className={`menu-option`} onClick={() =>handleDelete(params.row)}> <svg style={{opacity:.6}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>Excluir</span>
                      </div>
          </div>
      )
    }
];



    function search(array){

               function search_from_object(object,text){
                      text=tableSearch
                      let add=false
                      Object.keys(object).forEach(k=>{
                        if(typeof object[k]=="string" || typeof object[k]=="number"){
                           if(typeof object[k]=="number") object[k]=`${object[k]}`
                           if(object[k].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))){
                             add=true
                          }
                        }
                      })
                      return add
              }

            if (!array) return []
            let d=JSON.parse(JSON.stringify(array))
            let res=[]


            if(filters.account_id){
                d=d.filter(i=>i.account_id==filters.account_id)
            }

            if(filters.category_id){
                d=d.filter(i=>i.category_id==filters.category_id)
            }

            if(filters.type){
                d=d.filter(i=>i.type==filters.type)
            }

            if(filters.start_date){
                d=d.filter(i=>new Date(i.createdAt.split('T')[0]).getTime() >= new Date(filters.start_date).getTime())
            }

             if(filters.end_date){
                d=d.filter(i=>new Date(i.createdAt.split('T')[0]).getTime() <= new Date(filters.end_date).getTime())
            }

            d.forEach((t,i)=>{
              if(search_from_object(t)) {
                  res.push(array.filter(j=>j.id==t.id)[0])
              }
           })

           return res

    }

  function handleEdit(row) {
      setEdit(JSON.parse(JSON.stringify(data.transations.filter(i=>i.id==row.id)[0]))) 
  }
   function handleDelete(row) {
       setDel({id:row.id,confirm:null})
  }

  const [rows,setRows]=React.useState([])
  const [loading,setLoading]=React.useState(false)
  

  React.useEffect(()=>{
    if(data.loaded.settings && data.loaded.transations){
       let content=search(data.transations)
       setFilteredContent(content)
       setRows(content)
    } 
  },[data,data.transations,data.settings,tableSearch,filters])

    React.useEffect(()=>{

         async function fetchData() {
               try {
                   let [settingsRes, transationsRes] = await Promise.all([
                       makeRequest({ url: 'settings', error: '' }),
                       makeRequest({ url: 'transations', error: '' }),
                   ]);
                   data.settings = settingsRes;
                   data.transations = transationsRes;
                   setData(prev=>({...prev, loaded:{...data.loaded,transations:true,settings:true,clients:true}, settings: settingsRes, transations: transationsRes }))
               } catch (e) {
                   console.error('Error fetching data:', e);
               }
             }
          fetchData();
   
   },[])




    React.useEffect(()=>{
    if(del?.confirm){

      axios.delete(`${process.env.REACT_APP_BASE_URL}/api/transation/delete/`+del.id ,{
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {
            data.transations=data.transations.filter(i=>i.id!=del.id)
            toast.success('Transação eliminada com sucesso')
            setData(data)
            setDel(null)
        })
        .catch(error => {
            setLoading(false)
            console.error('Error data:', error);
            try{
                   toast.error(error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
            }catch(e){
                   toast.error('Error, try again!')
            }

            setDel(null)
        });
      
    }
  },[del])



  const handleSelectionModelChange = (newSelection) => {
    console.log('Selected Rows:', newSelection);
  };
  
  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 6,
            },
          },
        }}
        pageSizeOptions={[6]} 
        //checkboxSelection
        disableColumnMenu
        localeText={{ noRowsLabel: <CustomNoRowsOverlay loading={!data.loaded.transations || !data.loaded.settings ? true : false}/>, pagination: {labelRowsPerPage: 'Mostrar por página'} }}
        //onSelectionModelChange={handleSelectionModelChange}
      />
    </Box>
    
  );
}
