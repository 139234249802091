"use client"

import '../css/main.css'
import './style.css'
import Menu from '../common/menu.js';
import TopBar from "../common/topBar";
import { useState ,useEffect,useRef} from 'react';
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';
import toast from 'react-hot-toast';


export default function Settings() {
  const {data, setData, updateData,makeRequest} = useData();
  const {token, user} = useAuth(); 

  const [userSettings,setUserSettings]=useState({})
  const [microcreditSettings,setMicrocreditSettings]=useState({})
  const [loading,setLoading]=useState({})
  const [sending,setSending]=useState(false)
 


   useEffect(()=>{

        if(data.loaded.settings) {
             setUserSettings(data.settings.user_settings)
             setMicrocreditSettings(data.settings.microcredit)
        }

   },[data.settings])


   function user_settings(option) {
        setSending(true)
        setLoading({...loading,[option]:true})
            let value=!data.settings.user_settings[option]
            let _data={...userSettings,[option]:value}

            axios.post(`${process.env.REACT_APP_BASE_URL}/api/settings/user/update`,_data,{
             headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
                },
            })
            .then(response => {
                data.settings.user_settings=_data
                setUserSettings(response.data)
                toast.success('Actualizado com sucesso!')
                setData(data)
                setLoading({...loading,[option]:false})
                setSending(false)
            })
            .catch(error => {
                setLoading({...loading,[option]:false})
                setSending(false)
                console.error('Error fetching  data:', error);
                try{
                       toast.error(error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
                }catch(e){
                       toast.error('Error, try again!')
                }
            });

   }


   function microcredit_settings(option) {

        setSending(true)
        setLoading({...loading,[option]:true})
            let value=!data.settings.microcredit[option]
            let _data={...microcreditSettings,[option]:value}

            axios.post(`${process.env.REACT_APP_BASE_URL}/api/settings/microcredit/update`,_data,{
             headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
                },
            })
            .then(response => {
                toast.remove()
                data.settings.microcredit=_data
                setUserSettings(response.data)
                toast.success('Actualizado com sucesso!')
                setData(data)
                setLoading({...loading,[option]:false})
                setSending(false)
            })
            .catch(error => {
                toast.remove()
                setLoading({...loading,[option]:false})
                setSending(false)
                console.error('Error fetching  data:', error);
                try{
                       toast.error(error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
                }catch(e){
                       toast.error('Error, try again!')
                }
            });
   }


    useEffect(()=>{
      (async () => {
                   try{
                         let res = await makeRequest({ url: 'settings', error: `` });
                         setData({...data,settings:res,loaded:{...data.loaded,settings:true}})
                   }catch(e){
                         console.log(e)
                   }
      })();
   },[])


  return (
    <>
    <main className="dashboard-container">

        <div className="dashboard">

            <Menu/>

            <div className="main-dashboard">
               
            <TopBar activePage={'Configurações'}/>

                  <div className="center">


                 {!data.loaded.settings &&   <div style={{display:'flex', alignItems:'center'}}>

                        <div className="settings-loader">
                        
                        </div>

                        <span style={{opacity:'.7',marginLeft:10}}>A carregar configurações</span>

                    </div>}

                   
                     
                    {data.loaded.settings && <div  className={`settings ${sending ? 'loading' :''}`}>
                    
                                                 <div className="settings-options" style={{display:'none'}}>
                    
                                                   <div className="set option-c">
                                                            <div className="option lang">
                                                               <div className="title" langs="pt:Idioma,,en:Language">Idioma</div>
                                                                     <select>
                                                                        <option>Português</option>
                                                                        <option>English</option>
                                                                     </select>
                                                            </div>
                                                   </div>
                    
                                                 </div>
                    
                    
                    
                                                  <div className="settings-options">
                                                     <div className="set option-c">
                                                                       <div className="top-title">Preferências de Usuário</div>
                                                              <div className="option pop-ups">
                                                                       <div className="title">Receber Notificações por email</div>
                                                                       <div className={` ${loading.email_notifications ? 'loading' :''}`}>
                                                                             <div onClick={()=>user_settings('email_notifications')} className={`switch ${data.settings.user_settings.email_notifications ? 'active' :''}`}></div>
                                                                       </div>
                                                           </div>
                    
                                                          {user.microcredit.plan=="gold" && <div className="option pop-ups">
                                                                       <div className="title">Receber Notificações por SMS</div>
                                                                       <div className={` ${loading.sms_notifications ? 'loading' :''}`}>
                                                                             <div onClick={()=>user_settings('sms_notifications')} className={`switch ${data.settings.user_settings.sms_notifications ? 'active' :''}`}></div>
                                                                       </div>
                                                           </div>}
                    
                                                          
                                                            
                                                        </div>
                                                        { /*<div><button>cancelar</button><button>Enviar</button> </div>  */ }
                    
                                                  </div>
                    
                    
                                                   {user.role=="manager" && <div className="settings-options">
                                                   
                                                                                   <div className="set option-c">
                                                                                                        <div className="top-title">Definições de Microcrédito</div>
                                                                                           <div className="option pop-ups">
                                                                                                      <div className="title">Enviar notificações aos usuários</div>
                                                                                                      <div className={` ${loading.notify_users ? 'loading' :''}`}>
                                                                                                            <div onClick={()=>microcredit_settings('notify_users')} className={`switch ${data.settings.microcredit.notify_users ? 'active' :''}`}></div>
                                                                                                      </div>
                                                                                           </div>
                                                                                       </div>
                                                   
                                                                                </div>}
                    
                    
                                        </div>}


                  </div>

                  
            </div>
        </div>
    </main>
    </>
  );
}
