import '../css/main.css'
import './style.css'
import ChartMoreClients from './charts/moreClients.js';
import ChartMoreAccess from './charts/moreAccess.js';
import CreateClient from '../clients/create.js';
import CreateManager from '../managers/create.js';
import CreateOperator from '../operators/create.js';
import CreateTransation from '../cash-management/create.js';
import Menu from '../common/menu.js';
import TopBar from "../common/topBar";
import { useState,useEffect } from 'react';
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext';
import CompaniesTable from './tables/company_details';


export default function Admin() {
   const [leftMenu,setLeftMenu]=useState(false)
   const [openDialog, setOpenDialog] = useState('');
   const [create, setCreate] = useState(false);
   const [edit, setEdit] = useState(null);
   const {data, setData, updateData, makeRequest} = useData();
   const {token, user,auth} = useAuth();
   const [appLoader, setAppLoader] = useState(false);
   const [dataLoaded,setDataLoaded] = useState(false);

   const  [moreAccessData,setMoreAccessData]=useState([])
   const  [moreClientsData,setMoreClientsData]=useState([])

   function openNewDialog(dialog){
       setCreate(true)
       setOpenDialog(dialog)
   }


   useEffect(()=>{
           (async () => {
                   let res = await makeRequest({ url: 'dashboard' });
                   setDataLoaded(true)
                   data.dashboard=res.data
                   data.loaded.dashboard=true
                   setData(data)
          })();
   },[])





   useEffect(()=>{
           if(!data.loaded.dashboard) return

          for (var i = 0; i < 10 ; i++) {
            if(!data.dashboard.microcredits.rank_more_access[i]) data.dashboard.microcredits.rank_more_access.push({name:'',total:0,empty:true})
            if(!data.dashboard.microcredits.rank_most_clients[i]) data.dashboard.microcredits.rank_most_clients.push({name:'',total:0,empty:true}) 
          
          }

          data.dashboard.microcredits.rank_more_access=data.dashboard.microcredits.rank_more_access.filter((i,index)=>index<=10 && (i.total || i.empty)) 
          data.dashboard.microcredits.rank_most_clients=data.dashboard.microcredits.rank_most_clients.filter((i,index)=>index<=10 && (i.total || i.empty)) 


          setMoreClientsData(data.dashboard.microcredits.rank_more_access)
          setMoreAccessData(data.dashboard.microcredits.rank_most_clients)

   },[data.loaded,data.dashboard])


   useEffect(()=>{
        if(!create){
           setOpenDialog(null)
        }
   },[create])

   return (
    <>
   <main className={`dashboard-container ${dataLoaded ? '' :'loading' }`}>

        {<CreateClient showD={openDialog == "clients" ? true : false} setEdit={setEdit}  create={create} setCreate={setCreate}/>}
        {<CreateManager showD={openDialog == "managers"  ? true : false} setEdit={setEdit} create={create} setCreate={setCreate}/>}
        {<CreateOperator showD={openDialog == "operators"  ? true : false}  setEdit={setEdit}  create={create} setCreate={setCreate}/>}
       
        <div className="dashboard">
            <Menu leftMenu={leftMenu}/>
            
            <div className="main-dashboard">
            <TopBar activePage={'Dashboard'}/>

                  <div className="center">
                        <div className="stat_page_1">
                        <div className="left-side">
                               <div className="items">

                               <div className="box alerts skeleton-bg">
                                       <div className="name">
                                            <label>Recebido</label>
                                            <div className="icon orange-icon">
                                            <svg style={{opacity:.8}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-200h80v-40h40q17 0 28.5-11.5T600-280v-120q0-17-11.5-28.5T560-440H440v-40h160v-80h-80v-40h-80v40h-40q-17 0-28.5 11.5T360-520v120q0 17 11.5 28.5T400-360h120v40H360v80h80v40ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-560v-160H240v640h480v-480H520ZM240-800v160-160 640-640Z"></path></svg>
                                            </div>
                                       </div>
                                       <span className="total">{new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.dashboard.loans?.paid || 0)} MT</span>
                                    </div>


                                    <div className="box microcredit skeleton-bg">
                                       <div className="name">
                                            <label>Finananciado</label>
                                            <div className="icon green-icon">
                                            <svg style={{opacity:.8}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-200h80v-40h40q17 0 28.5-11.5T600-280v-120q0-17-11.5-28.5T560-440H440v-40h160v-80h-80v-40h-80v40h-40q-17 0-28.5 11.5T360-520v120q0 17 11.5 28.5T400-360h120v40H360v80h80v40ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-560v-160H240v640h480v-480H520ZM240-800v160-160 640-640Z"></path></svg>
                                            
                                            </div>
                                       </div>
                                    <span className="total">{new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.dashboard.loans?.lent || 0)} MT</span>
                                    </div>
                                    <div className="box revenues skeleton-bg">
                                       <div className="name">
                                            <label>Esperado</label>
                                            <div className="icon red-icon">
                                            <svg style={{opacity:.8}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-200h80v-40h40q17 0 28.5-11.5T600-280v-120q0-17-11.5-28.5T560-440H440v-40h160v-80h-80v-40h-80v40h-40q-17 0-28.5 11.5T360-520v120q0 17 11.5 28.5T400-360h120v40H360v80h80v40ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-560v-160H240v640h480v-480H520ZM240-800v160-160 640-640Z"></path></svg>
                                            </div>
                                       </div>
                                      <span className="total">{new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.dashboard.loans?.expected || 0)} MT</span>
                                    </div>
                                    <div className="box loans skeleton-bg">
                                       <div className="name">
                                            <label>Em falta</label>
                                            <div className="icon blue-icon">
                                            <svg style={{opacity:.8}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-200h80v-40h40q17 0 28.5-11.5T600-280v-120q0-17-11.5-28.5T560-440H440v-40h160v-80h-80v-40h-80v40h-40q-17 0-28.5 11.5T360-520v120q0 17 11.5 28.5T400-360h120v40H360v80h80v40ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-560v-160H240v640h480v-480H520ZM240-800v160-160 640-640Z"></path></svg>
                                            </div>
                                       </div>
                                       <span className="total">{new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.dashboard.loans?.lacking || 0)} MT</span>
                                    </div>
                               </div> 
                            </div>

                            <div className="right-side">
                               <div className="chart-c skeleton-bg">
                                     <div className="box-top-title">Microcreditos com maior de acesso</div>
                                     <div className="chart">
                                        <ChartMoreAccess data={moreAccessData} />
                                     </div>
                               </div> 
                            </div>
                        </div>


                      <div className="stat_page_2">
                                                    <div className="items">
                       
                                                         <div className="box skeleton-bg">
                                                           <div className="icon blue-icon">
                                                                      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120-240h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0 320Zm0-400Z"/></svg>
                                                           </div>
                                                           <div className="center-content">
                                                              <div className="name">Clientes</div>
                                                              <div className="total">{data.dashboard.clients?.total}</div>
                                                           </div>
                                                           <div className="add-btn" onClick={()=>openNewDialog('clients')}>
                                                                   <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path  fill="#789DEA" d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"/></svg> 
                                                           </div>
                                                        </div>
                                                          <div className="box skeleton-bg">
                                                           <div className="icon red-icon">
                                                                      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120-240h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0 320Zm0-400Z"/></svg>
                                                           </div>
                                                           <div className="center-content">
                                                              <div className="name">Gestores</div>
                                                              <div className="total">{data.dashboard.managers?.total}</div>
                                                           </div>
                                                         <div className="add-btn" onClick={()=>openNewDialog('managers')}>
                                                               <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path  fill="#789DEA" d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"/></svg>
                                                         </div>
                                                        </div>
                       
                       
                                                          <div className="box skeleton-bg">
                                                           <div className="icon green-icon">
                                                                      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120-240h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0 320Zm0-400Z"/></svg>
                                                           </div>
                                                           <div className="center-content">
                                                              <div className="name">Operadores</div>
                                                              <div className="total">{data.dashboard.operators?.total}</div>
                                                           </div>
                                                           <div className="add-btn" onClick={()=>openNewDialog('operators')}>
                                                                                             <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path  fill="#789DEA" d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"/></svg>
                                                           </div>
                                                        </div>
                       
                                                    </div>
                                              </div>


                                        <div className="stat_page_2">
                                                    <div className="items">
                                                         <div className="box skeleton-bg">
                                                           <div className="icon blue-icon">
                                                           <svg style={{opacity:.8}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-200h80v-40h40q17 0 28.5-11.5T600-280v-120q0-17-11.5-28.5T560-440H440v-40h160v-80h-80v-40h-80v40h-40q-17 0-28.5 11.5T360-520v120q0 17 11.5 28.5T400-360h120v40H360v80h80v40ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-560v-160H240v640h480v-480H520ZM240-800v160-160 640-640Z"></path></svg>
                                                           </div>
                                                           <div className="center-content">
                                                              <div className="name">Contratos activos</div>
                                                              <div className="total">{data.dashboard.loans?.active}</div>
                                                           </div>
                                                          
                                                           
                                                        </div>
                                                          <div className="box skeleton-bg">
                                                           <div className="icon blue-icon">
                                                           <svg style={{opacity:.8}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-200h80v-40h40q17 0 28.5-11.5T600-280v-120q0-17-11.5-28.5T560-440H440v-40h160v-80h-80v-40h-80v40h-40q-17 0-28.5 11.5T360-520v120q0 17 11.5 28.5T400-360h120v40H360v80h80v40ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-560v-160H240v640h480v-480H520ZM240-800v160-160 640-640Z"></path></svg>
                                                           </div>
                                                           <div className="center-content">

                                                              <div className="name">Contratos pendentes</div>
                                                              <div className="total">{data.dashboard.loans?.pending}</div>

                                                           </div>
                                                          
                                                          
                                                        </div>
                       
                       
                                                          <div className="box skeleton-bg">
                                                           <div className="icon green-icon">
                                                           <svg style={{opacity:.8}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-200h80v-40h40q17 0 28.5-11.5T600-280v-120q0-17-11.5-28.5T560-440H440v-40h160v-80h-80v-40h-80v40h-40q-17 0-28.5 11.5T360-520v120q0 17 11.5 28.5T400-360h120v40H360v80h80v40ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-560v-160H240v640h480v-480H520ZM240-800v160-160 640-640Z"></path></svg>
                                                          </div>
                                                           <div className="center-content">
                                                              <div className="name">Contratos em atraso</div>
                                                              <div className="total">{data.dashboard.loans?.delayed}</div>
                                                           </div>
                                                          
                                                        </div>
                       
                                                    </div>
                                </div>




                       <div style={{marginTop:'1rem'}} className="admin-company-table">


                           <CompaniesTable/>


                       </div>
                      


                       <div className="stat_page_3">
                           <div className="chart-container skeleton-bg">
                                 <div className="box-top-title">Microcreditos com maior número de clientes</div>
                                 <div className="chart">
                                    {<ChartMoreClients data={moreAccessData} />}
                                 </div>
                           </div>
                           <div className="loans-status skeleton-bg">
                              <div className="box-top-title">Contratos</div>
                              <div className="list">
                                  
                                    <div className="item">
                                      <span className="name">Total</span>
                                      <span className="total">{data.loaded.dashboard && data.dashboard.loans.total}</span>
                                   </div>
                                    <div className="item">
                                      <span className="name">Activos</span>
                                      <span className="total">{data.loaded.dashboard && data.dashboard.loans.active}</span>
                                   </div>
                                   <div className="item">
                                      <span className="name">Rejeitados</span>
                                      <span className="total">{data.loaded.dashboard && data.dashboard.loans.rejected}</span>
                                   </div>
                                    <div className="item">
                                      <span className="name">Pendentes</span>
                                      <span className="total">{data.loaded.dashboard && data.dashboard.loans.pending}</span>
                                   </div>
                                    <div className="item">
                                      <span className="name">Cancelados</span>
                                      <span className="total">{0}</span>
                                   </div>
                                   
                              </div>
                           </div>
                       </div>

                  </div>
            </div>
        </div>
    </main>
    </>
  );
}
