
const Delete = ({del,setDel,showD}) =>{

    return (
      <div className={`dialog-container-bg ${showD ? 'show' :''}`}>
       <div className="bg-close" onClick={()=>setDel(null)}></div>
      <div className="delete-dialog"> 
                  <div className="btn-close" onClick={()=> setDel(false)}>
                         <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
               </div> 


                <div className="alert-icon">
                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="70"><path fill="#FFA500" d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                </div>

               <p>Tem certeza de que deseja excluir?</p>

               <div className="options"> 
                     {!del?.confirm ?  (<>
                       <button onClick={()=>setDel(null)} className="cancel">Não</button> <button className="save" onClick={()=>setDel({...del,confirm:true})}>Sim</button> 
                     </>) : (<span>A eliminar...</span>)}  
               </div>  

           </div>
       
      </div>
    )
};

export default Delete;
