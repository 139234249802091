
import { useEffect, useState} from 'react';
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import toast from 'react-hot-toast';

const Select = ({setOpenSelect , userCompanies}) => {
 const {login } = useAuth()
 const navigate = useNavigate();

function selectAccount(c) {
            toast.success('You are logged in')
            login(c,c.token)
            navigate('/dashboard');
}  

return (
  <div className="dialog-container-bg">
  <div onClick={()=>setOpenSelect(false)} className="bg-close"></div>
    <div  className="create-microcredit select-c">
                     <div className="btn-close" onClick={()=>setOpenSelect(false)}>
                         <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                    </div>
                    <div className="top-title">Selecione uma conta</div> 
                       <div className="c-options">
                           {userCompanies.map((c,i)=>(
                              <div className="o" key={i} onClick={()=>selectAccount(c)}>
                                  <span className="name">{c.microcredit ? c.name : ''}</span>
                                  <span className="micro">{c?.microcredit ? c.microcredit.name : c.name + " " +c.last_name }</span>
                                  <span className="role">({c.role})</span>
                              </div>
                            ))}
                       </div>
                   </div>
    </div>
  )
};

export default Select;
