

import '../../css/main.css'
import './style.css'
import ChartMoreClients from './charts/moreClients.js';
import ChartMoreAccess from './charts/moreAccess.js';
import CreateClient from '../../clients/create.js';
import CreateManager from '../../managers/create.js';
import CreateOperator from '../../operators/create.js';
import Menu from '../../common/menu.js';
import TopBar from "../../common/topBar";
import { useState,useEffect } from 'react';
import { useData } from '../../../contexts/DataContext';
import { useAuth } from '../../../contexts/AuthContext';
import DynamicGridExample from './table.js'
import MothlyAccountsTable from './tables/montly-accounts.js'
import WeeklyAccountsTable from './tables/weekly-accounts.js'
import MothlyCategoriesTable from './tables/montly-categories.js'
import WeeklyCategoriesTable from './tables/weekly-categories.js'


export default function Dashboard() {

    const {token,user} = useAuth();
    const {data,setData,makeRequest} = useData();

    const [_in, setIn] = useState(0);
    const [_out, setOut] = useState(0);
    const [_a, setAvailable] = useState(0);
    const [_accounts, setAccounts] = useState([])
    const [_cats, setCats] = useState([])
    const [montlyAccountsPerformace, setMontlyAccountsPerformace] = useState([])
    const [weeklyAccountsPerformace, setweeklyAccountsPerformace] = useState([])
    const [montlyCategoriesPerformace, setMontlyCategoriesPerformace] = useState([])
    const [weeklyCategoriesPerformace, setweeklyCategoriesPerformace] = useState([])
    let initial_filters={start_date:'',end_date:'',category_id:'',account_id:''}
    const [filters,setFilters]=useState(initial_filters);
    const transation_types=[{id:'out',label:'Saida'},{id:'in',label:'Entrada'}]
    const [filteredContent,setFilteredContent]=useState({accounts:[],categories:[],transations:[]});


   useEffect(()=>{
          (async () => {
                       try{
                             let res = await makeRequest({ url:'settings', error: ``});
                             data.settings=res
                             data.loaded.settings=true
                             setData({...data,settings:res})
                       }catch(e){}
          })();

          (async () => {
                        try{
                             let res = await makeRequest({ url:'transations', error: ``});
                             data.transations=res
                             data.loaded.transations=true
                             setData({...data,transations:res})
                        }catch(e){}
          })();
           
  },[])

  function startAndEndDateOfTheWeek(){
	const currentDate = new Date();
	const currentDayOfWeek = currentDate.getDay();
	const startDate = new Date(currentDate);
	startDate.setDate(startDate.getDate() - currentDayOfWeek);
	const endDate = new Date(startDate);
	endDate.setDate(endDate.getDate() + 6);
	return {endDate, startDate}
}
   

   useEffect(()=>{

               let d=JSON.parse(JSON.stringify(filteredContent))

                d.transations=data.transations
                if(data.settings.accounts) d.accounts=data.settings.accounts
                if(data.settings.categories) d.categories=data.settings.categories



                if(filters.account_id){
                  d.transations=d.transations.filter(i=>i.account_id==filters.account_id)
                  d.accounts=d.accounts.filter(i=>i.id==filters.account_id)
                }

                if(filters.category_id){
                    d.transations=d.transations.filter(i=>i.category_id==filters.category_id)
                    d.categories=d.categories.filter(i=>i.id==filters.category_id)
                }

                if(filters.start_date){
                    d.transations=d.transations.filter(i=>new Date(i.createdAt.split('T')[0]).getTime() >= new Date(filters.start_date).getTime())
                }

                if(filters.end_date){
                    d.transations=d.transations.filter(i=>new Date(i.createdAt.split('T')[0]).getTime() <= new Date(filters.end_date).getTime())
                }

                setFilteredContent(d)


       
    },[data.transations, data, data.settings,filters])
   

   useEffect(()=>{
        if(data.transations.length && !filters.end_date && !filters.start_date){
           setFilters({...filters,
           start_date:data.transations[data.transations.length - 1].createdAt.split('T')[0],
           end_date:data.transations[0].createdAt.split('T')[0],
          })
        }  

    },[data.transations,filters])
    
    


    useEffect(()=>{

    let _in=0
    filteredContent.transations.forEach(i=>{if(i.type=="in" && (i.role!="pledged" && i.role!="loss")) _in+=i.amount})

    let _out=0
    filteredContent.transations.forEach(i=>{if(i.type=="out" && (i.role!="pledged" && i.role!="loss")) _out+=i.amount})

    let _a=0

    setOut(_out)
    setIn(_in)

    

    let accounts=[]

    if(data.settings.accounts){

        let monthlyAccounts=[]
        let weeklyAccounts=[]

         filteredContent.accounts.forEach((i,_i)=>{

            monthlyAccounts.push({name:i.name,id:i.id,data:Array.from({ length: 12 }, () => 0)})
            weeklyAccounts.push({name:i.name,id:i.id,data:Array.from({ length: 7 }, () => 0)})
            
           
            let __in=0
            let __out=0
            let times=0

            filteredContent.transations.forEach(j=>{ if(j.account_id==i.id && j.type=="in"){
             __in+=j.amount
             times+=1

             let index=new Date(j.createdAt.split('T')[0]).getMonth()
             monthlyAccounts[_i].data[index]=monthlyAccounts[_i].data[index] + j.amount


             let end=new Date(startAndEndDateOfTheWeek().endDate.toISOString().split('T')[0])
             let start=new Date(startAndEndDateOfTheWeek().startDate.toISOString().split('T')[0])
             let transactionDate=new Date(j.createdAt.split('T')[0])
             

             if(transactionDate >= start && transactionDate <= end){
                 let _index=new Date(j.createdAt.split('T')[0]).getDay()
                 weeklyAccounts[_i].data[_index]=weeklyAccounts[_i].data[_index] + j.amount
             }
            }})
            filteredContent.transations.forEach(j=>{ if(j.account_id==i.id && (j.type=="out")) {
              __out+=j.amount
              times+=1

              let index=new Date(j.createdAt.split('T')[0]).getMonth()
              monthlyAccounts[_i].data[index]=monthlyAccounts[_i].data[index] - j.amount

             let end=new Date(startAndEndDateOfTheWeek().endDate.toISOString().split('T')[0])
             let start=new Date(startAndEndDateOfTheWeek().startDate.toISOString().split('T')[0])
             let transactionDate=new Date(j.createdAt.split('T')[0])
             

             if(transactionDate >= start && transactionDate <= end){
                 let _index=new Date(j.createdAt.split('T')[0]).getDay()
                 weeklyAccounts[_i].data[_index]=weeklyAccounts[_i].data[_index] - j.amount
             }

            }})
            let a=(__in - __out)// <= 0 ? 0 : (__in - __out)
            //_a+=a
            accounts.push({id:i.id,name:i.name,in:__in,out:__out,available:a,times,role:i.role})
        })

          accounts.forEach(f=>{
            /*new */ 
            if(f.available){
             _a+=f.available
            } 
        })
      

        let monthlyAccountsSubTotal={name:'Sub-Total',id:'Sub-Total',data:Array.from({ length: 12 }, () => 0)}
        let monthlyAccountsTotal={name:'Total',id:'Sub-Total',data:Array.from({ length: 1 }, () => null)}
        monthlyAccounts.forEach((i,_i)=>{
             let d=i.data
             d.forEach((f,_f)=>{
                monthlyAccountsSubTotal.data[_f]+=f 
             })
        })

        monthlyAccountsSubTotal.data.forEach((i)=>{
            monthlyAccountsTotal.data[0]+=i
        })

        monthlyAccounts.push(monthlyAccountsSubTotal)
        monthlyAccounts.push(monthlyAccountsTotal)


        let weeklyAccountsSubTotal={name:'Sub-Total',id:'Sub-Total',data:Array.from({ length: 7 }, () => 0)}
        let weeklyAccountsTotal={name:'Total',id:'Sub-Total',data:Array.from({ length: 1 }, () => null)}
        
        weeklyAccounts.forEach((i,_i)=>{
             let d=i.data
             d.forEach((f,_f)=>{
                weeklyAccountsSubTotal.data[_f]+=f 
             })
        })
        weeklyAccountsSubTotal.data.forEach((i)=>{
            weeklyAccountsTotal.data[0]+=i
        })

        weeklyAccounts.push(weeklyAccountsSubTotal)
        weeklyAccounts.push(weeklyAccountsTotal)


        setMontlyAccountsPerformace(monthlyAccounts)
        setweeklyAccountsPerformace(weeklyAccounts)
    }

     for (var i = 0; i < 10 ; i++) {
            if(!accounts[i]) accounts.push({name:'',times:0, not_usable:true}) 
     }

      let loss_and_pledged_available=accounts.filter(i=>i.role=="loss" || i.role=="pledged").map(item => item.available).reduce((acc, curr) => acc + curr, 0)

      console.log()
      setAvailable(_a - loss_and_pledged_available)
      setAccounts(accounts)

    let categories=[]
    if(data.settings.categories){

         let monthlyCategories=[]
         let weeklyCategories=[]

         filteredContent.categories.forEach((i,_i)=>{
            let __in=0
            let __out=0
            let times=0
            
            monthlyCategories.push({name:i.name,id:i.id,data:Array.from({ length: 12 }, () => 0)})
            weeklyCategories.push({name:i.name,id:i.id,data:Array.from({ length: 7 }, () => 0)})

            filteredContent.transations.forEach(j=>{ if(j.category_id==i.id && j.type=="in" && (j.role!="pledged" && j.role!="loss")){
             __in+=j.amount
             times+=1
                let index=new Date(j.createdAt.split('T')[0]).getMonth()
                monthlyCategories[_i].data[index]=monthlyCategories[_i].data[index] + j.amount


                let end=new Date(startAndEndDateOfTheWeek().endDate.toISOString().split('T')[0])
                let start=new Date(startAndEndDateOfTheWeek().startDate.toISOString().split('T')[0])
                let transactionDate=new Date(j.createdAt.split('T')[0])
                

                if(transactionDate >= start && transactionDate <= end){
                    let _index=new Date(j.createdAt.split('T')[0]).getDay()
                    weeklyCategories[_i].data[_index]=weeklyCategories[_i].data[_index] + j.amount
                }
            }})

            filteredContent.transations.forEach(j=>{ if(j.category_id==i.id && (j.type=="out" && (j.role!="pledged" && j.role!="loss"))) {
              __out+=j.amount
               times+=1

               let index=new Date(j.createdAt.split('T')[0]).getMonth()
               monthlyCategories[_i].data[index]=monthlyCategories[_i].data[index] - j.amount
  
  
               let end=new Date(startAndEndDateOfTheWeek().endDate.toISOString().split('T')[0])
               let start=new Date(startAndEndDateOfTheWeek().startDate.toISOString().split('T')[0])
               let transactionDate=new Date(j.createdAt.split('T')[0])
               
  
               if(transactionDate >= start && transactionDate <= end){
                   let _index=new Date(j.createdAt.split('T')[0]).getDay()
                   weeklyCategories[_i].data[_index]=weeklyCategories[_i].data[_index] - j.amount
               }
            }})

           categories.push({id:i.id,name:i.name,in:__in,out:__out,available:(__in - __out) <= 0 ? 0 : (__in - __out),times})
        })


       let monthlyCategoriesSubTotal={name:'Sub-Total',id:'Sub-Total',data:Array.from({ length: 12 }, () => 0)}
       let monthlyCategoriesTotal={name:'Total',id:'Sub-Total',data:Array.from({ length: 1 }, () => null)}
       monthlyCategories.forEach((i,_i)=>{
            let d=i.data
            d.forEach((f,_f)=>{
               monthlyCategoriesSubTotal.data[_f]+=f 
            })
       })
       monthlyCategoriesSubTotal.data.forEach((i)=>{
        monthlyCategoriesTotal.data[0]+=i
       })

       monthlyCategories.push(monthlyCategoriesSubTotal)
       monthlyCategories.push(monthlyCategoriesTotal)
       

       let weeklyCategoriesSubTotal={name:'Sub-Total',id:'Sub-Total',data:Array.from({ length: 7 }, () => 0)}
       let weeklyCategoriesTotal={name:'Total',id:'Sub-Total',data:Array.from({ length: 1 }, () => null)}
        
       weeklyCategories.forEach((i,_i)=>{
            let d=i.data
            d.forEach((f,_f)=>{
               weeklyCategoriesSubTotal.data[_f]+=f 
            })
       })
       weeklyCategoriesSubTotal.data.forEach((i)=>{
        weeklyCategoriesTotal.data[0]+=i
       })

       weeklyCategories.push(weeklyCategoriesSubTotal)
       weeklyCategories.push(weeklyCategoriesTotal)
       setMontlyCategoriesPerformace(monthlyCategories)
       setweeklyCategoriesPerformace(weeklyCategories)
    }


    for (var i = 0; i < 10 ; i++) {
            if(!categories[i]) categories.push({name:'',times:0, not_usable:true}) 
    }

    setCats(categories)



    },[filteredContent])


   const [openDialog, setOpenDialog] = useState('');
   const [create, setCreate] = useState(false);
   const [edit, setEdit] = useState(null);

  

   function openNewDialog(dialog){
       setCreate(true)
       setOpenDialog(dialog)
   }

   useEffect(()=>{
        if(!create){
            setOpenDialog('')
        }
   },[create])


   

   return (
    <>
    <main className="dashboard-container">


        <div className="dashboard">

            <Menu/>

            <div className="main-dashboard">
               
            <TopBar activePage={'Relatórios de caixa'}/>

                  <div className="center">


                         <div className="stat_page_2">

                             <div className="items">

                                  <div className="box">
                                    <div className="icon blue-icon">
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="27"><path d="M444-200h70v-50q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q32 0 50 15.5t26 38.5l64-26q-11-35-40.5-61T516-710v-50h-70v50q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-33 0-58.5-20.5T390-396l-66 26q14 48 43.5 77.5T444-252v52Zm36 120q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg>
                                    </div>
                                    <div className="center-content">
                                       <div className="name">Entradas</div>
                                       <div className="total">{!data.loaded.settings || !data.loaded.transations ? "-" :  new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(_in) +' MT'}</div>
                                    </div>
                                 </div>


                                   <div className="box">
                                    <div className="icon orange-icon">
                                       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="27"><path d="M444-200h70v-50q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q32 0 50 15.5t26 38.5l64-26q-11-35-40.5-61T516-710v-50h-70v50q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-33 0-58.5-20.5T390-396l-66 26q14 48 43.5 77.5T444-252v52Zm36 120q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg>
                                    </div>
                                    <div className="center-content">
                                       <div className="name">Saidas</div>
                                       <div className="total">{_out != 0 ? '-':''}{!data.loaded.settings || !data.loaded.transations ? "-" : new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(_out) +' MT'}</div>
                                    </div>
                                    
                                 </div>


                                   <div className="box">
                                    <div className="icon green-icon">
                                       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="27"><path d="M444-200h70v-50q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q32 0 50 15.5t26 38.5l64-26q-11-35-40.5-61T516-710v-50h-70v50q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-33 0-58.5-20.5T390-396l-66 26q14 48 43.5 77.5T444-252v52Zm36 120q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"></path></svg>
                                    </div>
                                    <div className="center-content">
                                       <div className="name">disponível</div>
                                       <div className="total">{!data.loaded.settings || !data.loaded.transations ? "-" : new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(_a) +' MT'}</div>
                                    </div>
                                 </div>
                             </div>

                       </div>


                       <div className="filter-res">

                                  <div className="extra-filters" style={{marginTop:'1rem'}}>

                               <div className="input-container">

                                         <div className="inputs">
                                            <span>Data de inicio</span>
                                            <input type="date" value={filters.start_date} onChange={(e)=>setFilters({...filters,start_date:e.target.value})} />
                                        </div>

                                         <div className="inputs">
                                            <span>Data de fim</span>
                                            <input type="date" value={filters.end_date} onChange={(e)=>setFilters({...filters,end_date:e.target.value})} />
                                        </div>

                                        <div className="inputs">
                                           <span>Categoria</span>
                                           <select value={filters.category_id} onChange={e=>setFilters({...filters, category_id:e.target.value})}>
                                                <option  selected value="">Selecionar Categoria</option>
                                                {data.settings.categories.map(i=>(
                                                      <option key={i.id} selected={filters.category_id == i.id} value={i.id}>{i.name}</option>
                                                 ))}
                                           </select>
                                        </div>
                                        <div className="inputs">
                                           <span>Conta</span>
                                           <select value={filters.account_id} onChange={e=>setFilters({...filters, account_id:e.target.value})}>
                                                      <option selected value="">Selecionar Conta</option>
                                                      {data.settings.accounts.map(i=>(
                                                            <option key={i.id} selected={filters.account_id == i.id} value={i.id}>{i.name} ({i.description})</option>
                                                      ))}
                                           </select>
                                        </div>


                                        
                                </div>

                                <div className="options">
                                   <span onClick={()=>setFilters(initial_filters)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="#fff" d="M440-122q-121-15-200.5-105.5T160-440q0-66 26-126.5T260-672l57 57q-38 34-57.5 79T240-440q0 88 56 155.5T440-202v80Zm80 0v-80q87-16 143.5-83T720-440q0-100-70-170t-170-70h-3l44 44-56 56-140-140 140-140 56 56-44 44h3q134 0 227 93t93 227q0 121-79.5 211.5T520-122Z"/></svg> </span>
                                </div>
                            </div>
                       </div>

                       <div className="stat_page_3">
                           <div className="chart-container">
                                 <div className="box-top-title">
                                 Movimentação de contas
                                 </div>
                                 <div className="chart">
                                    <ChartMoreClients data={_accounts} />
                                 </div>
                           </div>
                           <div className="loans-status">
                              <div className="box-top-title">Contas <div className="r"><label>Entrada</label><label>Saida</label><label>disponível</label></div></div>
                              <div className="list">
                                   {_accounts && _accounts.filter(i=>!i.not_usable).map(i=>(
                                       <div className="item">
                                        <span className="name">{i.name}</span>
                                        <div className="r">
                                             <span className="total"><label>E. </label>{new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(i.in)}</span>
                                             <span className="total"><label>S. </label>{i.out != 0 ? '-':''}{new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(i.out)}</span>
                                           
                                            <span className="total"><label>D. </label>{new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(i.available)}</span>
                                        </div>
                                       </div>
                                   ))}
                              </div>
                           </div>
                       </div>




                       <div className="stat_page_3">
                           <div className="chart-container">
                                 <div className="box-top-title">
                                 Movimentação de caixa
                                   <select style={{display:'none'}}>
                                      <option selected value="in">Saida</option>
                                      <option value="out">Saida</option>
                                   </select>
                                 </div>
                                 <div className="chart">
                                     <ChartMoreClients data={_cats} />
                                 </div>
                           </div>
                           <div className="loans-status">
                             <div className="box-top-title">Categorias <div className="r"><label>Entrada</label><label>Saida</label>{/*<label>disponível</label>*/}</div></div>
                              <div className="list">
                                   {_cats && _cats.filter(i=>!i.not_usable).map(i=>(
                                       <div className="item">
                                        <span className="name">{i.name}</span>
                                        <div className="r">
                                            <span className="total"><label>E. </label>{new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(i.in)}</span>
                                            <span className="total"><label>S. </label>{i.out != 0 ? '-':''}{new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(i.out)}</span>
                                            {/*<span className="total"><label>D. </label>{i.available.toLocaleString()}</span>*/}
                                        </div>
                                       </div>
                                   ))}
                              </div>
                           </div>
                       </div>
 




                   <div className="tables">

                       <div className="c-table">
                                     <div className="table-top">
                                          <span className="name">Movimentação semanal por conta</span>
                                         
                                     </div>
                                     <WeeklyAccountsTable data={weeklyAccountsPerformace}/>
                       </div>

                       <div className="c-table">
                                     <div className="table-top">
                                          <span className="name">Movimentação semanal por categoria</span>
                                         
                                     </div>
                                     <WeeklyAccountsTable data={weeklyCategoriesPerformace}/>
                       </div>


                       <div className="c-table">
                                     <div className="table-top">
                                          <span className="name">Movimentação mensal por conta</span>
                                          <div className="left-options">
                                                <select>
                                                    <option>2024</option>
                                                </select>
                                          </div>
                                     </div>
                                     <MothlyAccountsTable data={montlyAccountsPerformace}/>
                       </div>

                       <div className="c-table">
                                     <div className="table-top">
                                          <span className="name">Movimentação mensal por categoria</span>
                                          <div className="left-options">
                                                <select>
                                                    <option>2024</option>
                                                </select>
                                          </div>
                                     </div>
                                     <MothlyAccountsTable data={montlyCategoriesPerformace}/>
                       </div>

                   </div>

                   










                  </div>
            </div>
        </div>
    </main>
    </>
  );
}
