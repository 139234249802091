import { useEffect, useState} from 'react';
import { useData } from '../../../contexts/DataContext';
import { useAuth } from '../../../contexts/AuthContext';
import axios from 'axios';
import toast from 'react-hot-toast';
const Create = ({create,setCreate, edit , setEdit, del ,showD}) => {
const {data, setData} = useData();
const {token, user} = useAuth();

const [formData,setFormData]=useState({
  id:Math.random(),name:'',email:'',last_name:'',
  microcredit_id:data.microcredits.length ? data.microcredits[0].id : '',
  primary_contact:'',
  secondary_contact:'',
  neighborhood_name:'',
  block_number:'',identity_number:'',
  date_of_birth:'',
  house_number:'',
  avenue:'',
  is_client:true,
  passport_number:'',
  driver_license_number:'',
})
const [loading,setLoading]=useState(false)



function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email.trim());
}


function validate_form(data,excluded=[]){
      let can_pass=true
      Object.keys(data).forEach(i=>{
          if((!data[i] && !excluded.includes(i)) || (!formData.password && formData.change_password)) can_pass=false
      })
      return can_pass
}

useEffect(()=>{
    if(edit){
       setFormData({...edit,is_client:true})
       setCreate(false)
    }
},[edit])


useEffect(()=>{
    if(create){
       setEdit(false)
    } 
     console.log(user)
},[create])


const [allowNull,setAllowNull]=useState([])
function handle_allow_null(field) {
      if(allowNull.includes(field)){
             
      }else{

      }
}

function handle_manager(){

   if(user.role!="admin") {
            formData.microcredit_id=user.microcredit.id
            formData.microcredit=user.microcredit.name
    }else{
           formData.microcredit=data.microcredits.filter(m=>m.id==formData.microcredit_id)[0].name
    }


    if(!isValidEmail(formData.email) && formData.email) {
          toast.error('Email invalido!')
          return
     }

      if(!formData.name || !formData.last_name || !formData.primary_contact || !formData.microcredit_id){
        toast.error('Preencha os campos obrigatorios!')
        return
      }

  

    if(edit){

     
      setLoading(true) 

       axios.post(`${process.env.REACT_APP_BASE_URL}/api/client/update`,formData, {
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        })

        .then(response => {
             let id=response.data.id
             let clients=JSON.parse(JSON.stringify(data.clients))
             let index=data.clients.findIndex(i=>i.id==id)
             clients[index]=formData
             data.clients=clients
             toast.success('Cliente Actualizado com sucesso!')
             setData(data)
             setFormData({
                  id:Math.random(),name:'',email:'',last_name:'',
                  microcredit_id:data.microcredits.length ? data.microcredits[0].id : '',
                  primary_contact:'',
                  secondary_contact:'',
                  neighborhood_name:'',
                  block_number:'',identity_number:'',
                  date_of_birth:'',
                  house_number:'',
                  avenue:'',
                  passport_number:'',
                  driver_license_number:'',
                  is_client:true
             })
             setEdit(null)
             setLoading(false)  
        })
        .catch(error => {
            setLoading(false)
            console.error('Error fetching user data:', error);

            try{
                   toast.error(error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
            }catch(e){
                   toast.error('Error, try again!')
            }
        });


    }else{

      formData.created_by_id=user.id

      setLoading(true) 
         axios.post(`${process.env.REACT_APP_BASE_URL}/api/client/create`,formData, {
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {
             formData.id=response.data.user_id
             formData.client_id=response.data.id
             data.clients=[...data.clients,formData]
             setData(data)
             setCreate(false)
             setLoading(false) 
             toast.success('Cliente adicionado com sucesso!') 
        })
        .catch(error => {
            setLoading(false)
            console.error('Error fetching user data:', error);
            try{
                   toast.error(error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
            }catch(e){
                   toast.error('Error, try again!')
            }
            
        });
    }
}

return (

<div className={`dialog-container-bg ${showD ? 'show' :''}`}>
<div className="bg-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}></div>
<div  className={`create-microcredit client ${loading && 'loading'}`}>
                  <div className="top-title">{edit ? 'Editar' :' Adicionar'} cliente</div>  
                  <div className="btn-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}>
                         <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                  </div>

                  <div className="input-container">
                     <div className="inputs">
                           <span>Nome <label class="mandatory">*</label></span>
                           <span class="set-empty" style={{display:'none'}}>
                           <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="15"  viewBox="0 0 350.000000 346.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,346.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none"><path d="M1615 3274 c-394 -38 -691 -175 -963 -443 -325 -322 -474 -691 -459 -1136 11 -320 80 -524 282 -832 l73 -111 -160 -158 -161 -158 99 -92 99 -93 84 82 c112 110 128 124 186 179 l51 47 119 -80 c303 -203 524 -273 860 -273 233 0 368 23 560 96 430 162 802 572 926 1020 49 178 64 449 34 653 -33 234 -125 448 -286 666 -27 37 -49 70 -49 75 0 5 70 79 155 164 l156 156 -98 97 -98 97 -153 -155 c-84 -85 -155 -155 -158 -155 -3 0 -59 36 -124 79 -225 149 -383 216 -585 251 -110 18 -315 31 -390 24z m356 -295 c114 -24 258 -84 401 -166 64 -37 124 -73 134 -80 16 -11 -79 -109 -871 -903 -489 -490 -894 -889 -899 -887 -16 5 -155 244 -192 329 -47 110 -74 221 -84 352 -32 393 91 729 370 1006 273 272 574 390 950 373 58 -3 144 -13 191 -24z m837 -589 c72 -121 114 -219 149 -345 27 -96 28 -110 28 -315 -1 -201 -3 -221 -27 -313 -57 -209 -155 -381 -311 -543 -191 -201 -393 -318 -645 -375 -126 -29 -395 -32 -511 -5 -170 38 -338 108 -486 203 -38 25 -72 47 -74 49 -2 1 399 403 890 893 l894 890 19 -22 c10 -12 43 -65 74 -117z"/></g></svg>
                           </span>
                           <input type="text" value={formData.name} onChange={e=>setFormData({...formData,name:e.target.value})}  placeholder="Nome do cliente"/>
                     </div>
                     <div className="inputs">
                            <span>Apelido <label class="mandatory">*</label></span>
                           <input type="text" value={formData.last_name} onChange={e=>setFormData({...formData,last_name:e.target.value})}  placeholder="Apelido do cliente"/>
                     </div>

                     {user.role=="admin" &&  <div className="inputs">
                             <span>Microcredito <label class="mandatory">*</label></span>
                             <select value={formData.microcredit_id} onChange={e=>setFormData({...formData, microcredit_id:e.target.value})}>
                                  {!data.microcredits.length && <option disabled selected value="">Selecione uma microcredito</option>} 
                                  {data.microcredits.map(i=>(
                                        <option key={i.id} selected={formData.microcredit_id === i.id} value={i.id}>{i.name}</option>
                                  ))}
                             </select>
                      </div>}

                      <div className="inputs">
                           <span>Contacto primário <label class="mandatory">*</label></span>
                           <input value={formData.primary_contact} onChange={e=>setFormData({...formData,primary_contact:e.target.value})}  type="number" placeholder="Contacto primário"/>
                     </div>

                      <div className="inputs">
                            <span>Contacto secudário</span>
                            <input type="number" value={formData.secondary_contact} onChange={e=>setFormData({...formData,secondary_contact:e.target.value})}  placeholder="Contacto secunário"/>
                     </div>


                     <div className="inputs">
                           <span>Email</span>
                           <input type="text" value={formData.email} onChange={e=>setFormData({...formData,email:e.target.value})}  placeholder="Email do operador"/>
                     </div>

            
                     <div className="inputs">
                          <div className="label"><span>Senha</span>{edit && <label>Trocar senha <input checked={formData.change_password} onChange={(e)=>setFormData({...formData,change_password:e.target.checked})} type="checkbox"/></label>}</div>
                          <input  disabled={edit && formData.change_password ? false : !edit ? false : true} value={formData.password} onChange={e=>setFormData({...formData,password:e.target.value})} type="password" placeholder="Senha do gestor"/>
                    </div>
                     
   
                    
                     <div className="inputs">
                            <span>Bairro</span>
                            <input value={formData.neighborhood_name} onChange={e=>setFormData({...formData,neighborhood_name:e.target.value})}  type="text" placeholder="Nome do bairro de residência"/>
                     </div>

                     <div className="inputs">
                            <span>Quarteirão</span>
                            <input type="text" value={formData.block_number} onChange={e=>setFormData({...formData,block_number:e.target.value})}  placeholder="Número do quarteirão do cliente"/>
                     </div>

                     <div className="inputs">
                            <span>Número da casa</span>
                            <input value={formData.house_number} onChange={e=>setFormData({...formData,house_number:e.target.value})}  type="number" placeholder="Número da casa"/>
                     </div>

                     <div className="inputs">
                            <span>Avenida</span>
                            <input value={formData.avenue} onChange={e=>setFormData({...formData,avenue:e.target.value})}  type="text" placeholder="Avenida do cliente"/>
                     </div>
                     

                     <div className="inputs">
                            <span>Data de nascimento</span>
                            <input value={formData.date_of_birth} onChange={e=>setFormData({...formData,date_of_birth:e.target.value})}  type="date"/>
                     </div>

                       <div className="inputs">
                            <span >Número de B.I</span>
                            <input value={formData.identity_number} onChange={e=>setFormData({...formData,identity_number:e.target.value})}  type="text" placeholder="Número de B.I"/>
                      </div>

                      <div className="inputs">
                            <span>Carta de condução</span>
                            <input value={formData.driver_license_number} onChange={e=>setFormData({...formData,driver_license_number:e.target.value})}  type="text" placeholder="Número da carta de condução"/>
                      </div>

                       <div className="inputs">
                            <span>Número de passaporte</span>
                            <input value={formData.passport_number} onChange={e=>setFormData({...formData,passport_number:e.target.value})}  type="text" placeholder="Número de passaporte"/>
                      </div>

                      {user.role!="admin" && <div className="inputs"></div>}
                  
                  </div>


               <div className="options">
                  <button className="cancel" onClick={()=>(edit ? setEdit(false) : setCreate(false) )}>Cancelar</button>
                  <div className="div_btn">
                     <button className="save" onClick={handle_manager}><span>{edit? 'Actualizar' : 'Adicionar'}</span></button>
                     <span className="loader"></span>
                  </div>
              </div>

        </div>
         </div>


)
};

export default Create;
