import axios from 'axios';
import { createContext, useContext, useState, useEffect} from 'react';
import toast from 'react-hot-toast';

const AuthContext = createContext();



export const AuthProvider = ({ children }) => {

  const [user, setUser] = useState(null);
  const [token, setToken] = useState(() => localStorage.getItem('token'));
  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useState(false); 

  const login = (userData, authToken) => {
    setUser(userData);
    setToken(authToken);
    if(localStorage.getItem('token')) localStorage.setItem('token', authToken);
  };

  const logout = () => {
    setUser(null);
    setToken(null);
    localStorage.removeItem('token');
  };

  const isAuthenticated = () => {
    return !!token;
  };


    useEffect(() => {
      const fetchUserData = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/me`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.ok) {
            const userData = await response.json();
            login(userData, localStorage.getItem('token'));
            setAuth(true)
          } else {

            if(response.status=="401"){
                  toast.remove()
                  toast.error('Sessão expirou!')
            }
            logout()
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          logout()
        } finally {
          setLoading(false);
        }
      };
      
      if (isAuthenticated && !user && loading && token) {
        fetchUserData();
      } else {
        setLoading(false);
      }

     }, [isAuthenticated, user, token]);



  return (
    <AuthContext.Provider value={{ user, login, logout, isAuthenticated , loading, setUser, setLoading, token,auth}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
