import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import './style.css'
import { useData } from '../../../contexts/DataContext';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';


export default function Compontent() {

  const navigate = useNavigate();

   const {data, setData, updateData} = useData();
   const [table,setTable]=React.useState([])

   React.useEffect(()=>{
        if(data.loaded.dashboard)  setTable(data.dashboard.companies || [])
   },[data.loaded,data.dashboard])


  return (
      <div class="table-container">
    <table>
      <thead>
        <tr>
          <th>Nome</th>
          <th>Usuários</th>
          <th>Saldo disponível</th>
          <th>Contratos</th>
          <th>Clientes</th>
          <th>Gestores</th>
          <th>Operadores</th>
          <th>Plano</th>
        </tr>
      </thead>
      <tbody>
       
          {table.map(i=>(
              <tr className="tr"> 
                     <td>{i.name}</td>
                     <td>{i.users.map(f=>f.name + " "+ f.last_name).join(', ')}</td>
                     <td>{i.balance}</td>
                     <td>{i.contracts}</td>
                     <td>{i.clients}</td>
                     <td>{i.operators}</td>
                     <td>{i.managers}</td>
                     <td>{i.plan?.toUpperCase()}</td>
              </tr>

           ))}
       
      </tbody>
    </table>

  </div>
  );


}
