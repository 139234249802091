

import { useEffect, useState} from 'react';
import { useData } from '../../contexts/DataContext';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';
import toast from 'react-hot-toast';
import MultiSelect from '../common/multi-select-input.js'

const Create = ({create,setCreate, edit , setEdit,showD}) => {

const {data, setData} = useData();
const {token, user} = useAuth();
const [managers,setManagers]=useState([])
let initial_form={name:'',location:'',nuit:'',managers:[],expiration_date:'',plan:'free',period:'monthly',activation_date:''}
const [formData,setFormData]=useState(initial_form)
const [showDetails,setShowDetails]=useState(false)
const [loading,setLoading]=useState(false)


const plans=['free','standard','premium','gold']
const periods=['monthly','yearly']

 function today(get_this_day) {
        const currentDate = get_this_day ? get_this_day : new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate
}


function calculateExpirationDate(planType, duration, starting_date) {
    const currentDate = starting_date ? new Date(starting_date) : new Date();
    let expirationDate;
    if (planType === 'monthly') {
        expirationDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + duration, currentDate.getDate());
    } else if (planType === 'yearly') {
        expirationDate = new Date(currentDate.getFullYear() + duration, currentDate.getMonth(), currentDate.getDate());
    } else {
        throw new Error('Invalid plan type specified.');
    }
    return today(expirationDate);


}



useEffect(()=>{
    if(edit){
       if(!edit.plan) edit.plan="free"
       if(!edit.period) edit.period="monthly"

       setFormData(edit.plan_info ? {...edit,...edit.plan_info} : edit)
       setManagers(edit.managers)
       setCreate(false)
    }
    setShowDetails(false)
},[edit])

useEffect(()=>{
    if(create) {
      setEdit(false)
      setFormData({...initial_form,plan:'free',period:'monthly'})
    }
    setShowDetails(false)
    setManagers([])

},[create])

useEffect(()=>{
      if(formData.activation_date){
          setFormData({...formData,expiration_date:calculateExpirationDate(formData.period, 1,formData.activation_date)})
      }

},[formData.activation_date,formData.period])

useEffect(()=>{
   try{
    formData.managers=managers.map(i=>i.id)
  }catch(e){
    console.log(e)
  }
},[managers])


useEffect(()=>{
   console.log(formData)
},[formData,managers])


function verify_connected_managers(){

           let managers=JSON.parse(JSON.stringify(data.managers))

           managers.forEach((m,index)=>{ 
                 let micro=data.microcredits.filter(micro=>micro.managers.some(i=>i.id==m.id))[0]
                 managers[index].microcredit_id=micro?.id
                 managers[index].Microcredit=micro
           })

           data.managers=managers 
           setData(data) 
}



function handle_action(){
  
     if(!formData.name || !formData.location || !formData.nuit || !managers.length) {
         toast.error('Preencha todos os campos!')
         console.log(formData)
         return 
     }

     setLoading(true) 

     let new_data={status:today()!=formData.expiration_date ? 'not_verified' : 'active' ,...formData,plan_info:{plan:formData.plan,period:formData.period,expiration_date:formData.expiration_date,activation_date:formData.activation_date}}

    if(edit){
      /// new_data={...new_data,managers:new_data.managers.map(i=>i.id)}
       axios.post(`${process.env.REACT_APP_BASE_URL}/api/microcredit/update`,new_data, {
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {
             let id=response.data.id
             let microcredits=JSON.parse(JSON.stringify(data.microcredits))
             let index=data.microcredits.findIndex(i=>i.id==id)
             new_data.managers=managers
             microcredits[index]={...new_data,status:response.data.status}
             data.microcredits=microcredits
             toast.success('Microcredito actualizado com sucesso') 
             setData(data)
             setFormData({id:'',name:'',location:'',nuit:'',manager_id:data.managers.length ? data.managers[0].id : ''})
             setEdit(null)
             setLoading(false) 
             setTimeout(()=>verify_connected_managers(),2000) 
        })
        .catch(error => {
            setLoading(false)
            console.error('Error fetching user data:', error);
            try{
                   toast.error(error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
            }catch(e){
                   toast.error('Error, try again!')
            }
        });

    }else{
         axios.post(`${process.env.REACT_APP_BASE_URL}/api/microcredit/create`,new_data, {
         headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {
             new_data.id=response.data.id
             new_data.created_at=response.data.created_at 
             new_data.managers=managers
             let microcredits=JSON.parse(JSON.stringify(data.microcredits))
             microcredits.push({...new_data,status:response.data.status})
             data.microcredits=microcredits
             setData(data)
             setCreate(false)
             setLoading(false) 
             toast.success('Microcredito criado com sucesso')
             verify_connected_managers()
        })
        .catch(error => {
            setLoading(false)
            console.error('Error fetching user data:', error);
            try{
                   toast.error(error.response.data.message, `${error.response.data.error ? error.response.data.error :''}`)
            }catch(e){
                   toast.error('Error, try again!')
            }
        });
    }
    
}


return (
<div className={`dialog-container-bg ${showD ? 'show' :''}`}>
<div className="bg-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}></div>
<div  className={`create-microcredit ${loading && 'loading'}`}>
                  <div className={`details ${showDetails ? 'show' :'hide'}`}>

                      <div className="top-title">Detalhes do plano</div>  

                       <div className="btn-close" onClick={()=>setShowDetails(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z"/></svg>
                       </div>

                        <div className="input-container">
                               <div className="inputs">
                                    <span>Plano</span>
                                    <select  value={formData.plan} onChange={e=>setFormData({...formData, plan:e.target.value})}>
                                        {plans.map(i=>(
                                              <option key={i.id} selected={formData.plan === i} value={i}>{i}</option>
                                        ))}
                                   </select>
                               </div>
                               <div className="inputs">
                                      <span>Periodo de subscrição</span>
                                      <select  value={formData.period} onChange={e=>setFormData({...formData, period:e.target.value})}>
                                              {periods.map(i=>(
                                                    <option key={i.id} selected={formData.period === i} value={i}>{i}</option>
                                              ))}
                                      </select>
                               </div>

                               <div className="inputs">
                                      <span>Data de activação</span>
                                      <input type="date" value={formData.activation_date ? formData.activation_date : ''} onChange={e=>setFormData({...formData,activation_date:e.target.value})}  placeholder=""/>
                               </div>



                               <div className="inputs">
                                      <span>Data de vencimento</span>
                                     <input disabled="true" type="text" value={formData.activation_date ? formData.expiration_date : ''} onChange={e=>setFormData({...formData,expiration_date:e.target.value})}  placeholder=""/>
                               </div>
                        </div>

                  </div>


                  <div className="top-title">{edit ? 'Editar' :' Adicionar'} microcredito</div>  
                  <div className="btn-close" onClick={()=>(edit ? setEdit(null): setCreate(false))}>
                         <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                  </div>

                  <div  onClick={()=>setShowDetails(true)} className="status">Detalhes do plano</div>


                  <div className="input-container">
                     <div className="inputs">
                           <span>Nome</span>
                           <input type="text" value={formData.name} onChange={e=>setFormData({...formData,name:e.target.value})} placeholder="Nome da empresa"/>
                     </div>
                     <div className="inputs">
                            <span>Localização</span>
                           <input type="text"  value={formData.location} onChange={e=>setFormData({...formData,location:e.target.value})} placeholder="Localização da empresa"/>
                     </div>

                     <div className="inputs">
                            <span>Nuit</span>
                           <input type="number"   value={formData.nuit} onChange={e=>setFormData({...formData,nuit:e.target.value})}  placeholder="Nuit da empresa"/>
                     </div>
                    

                   {<div className="inputs full search-select">
                                          <span>Gestor</span>
                                          <MultiSelect options={data.managers.filter(i=>(!i.microcredit_id || i.microcredit_id == formData.id) && !managers.some(m=>m.email==i.User.email)).map(i=>{
                                             return {...i,email:i.User.email}
                                          })} setSelectedOptions={setManagers} selectedOptions={managers} placeholder={'Adicione gestores'} noOptionsMessage={'Sem opções'}/>
                    </div>}
                  
                  </div>

              <div className="options">
                  <button className="cancel" onClick={()=>(edit ? setEdit(false) : setCreate(false) )}>Cancelar</button>
                  <div className="div_btn">
                     <button className="save" onClick={handle_action}><span>{edit? 'Actualizar' : 'Adicionar'}</span></button>
                     <span className="loader"></span>
                  </div>
              </div>

        </div>

 </div>

)
};

export default Create;
